@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-catch {
  width: 100%;
  padding: 1.8vw 1.4vw 1.4vw;
  background-color: v.$bluePale02;
  box-shadow: 0 2px 2px rgba(v.$black, 0.2);

  @include m.pcOver {
    padding: 24px 19px 19px;
  }

  @include m.sp {
    padding: 2rem 1.5rem;
  }

  &_heading {
    width: 100%;
    padding: 0 0 0.9vw;
    @include m.fz(18);
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.04em;
    color: v.$blue;

    @include m.pcOver {
      padding: 0 0 12px;
    }

    @include m.sp {
      padding: 0 0 1rem;
      font-size: 1.6rem;
    }
  }

  &_info {
    width: 100%;

    &List {
      padding: 0.8vw 0 0.7vw;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid rgba(v.$black, 0.4);

      @include m.pcOver {
        padding: 10px 0 9px;
      }

      @include m.sp {
        padding: 0.8rem 0;
        align-items: flex-start;
      }

      &Ico {
        width: 1.5vw;
        height: 1.5vw;
        margin: 0 0.6vw 0 0;
        display: block;
        border: 2px solid v.$grayPale;
        position: relative;
        z-index: 1;

        @include m.pcOver {
          width: 16px;
          height: 16px;
          margin: 0 8px 0 0;
        }

        @include m.sp {
          width: 1.5rem;
          height: 1.5rem;
          margin: 0.5rem 0.5rem 0 0;
        }

        &:after {
          content: '';
          width: 1.2vw;
          height: 1.4vw;
          display: block;
          background: url('../img/common/ico_check.svg') no-repeat center;
          background-size: contain;
          position: absolute;
          top: 27%;
          left: 57%;
          z-index: 2;
          transform: translate(-50%, -50%);

          @include m.pcOver {
            width: 16px;
            height: 19px;
          }

          @include m.sp {
            width: 1.2rem;
            height: 1.4rem;
            top: 24%;
            left: 65%;
          }
        }
      }

      &Text {
        @include m.fz(14);
        line-height: 1;
        letter-spacing: 0.04em;
        flex: 1;

        @include m.sp {
          font-size: 1.4rem;
          line-height: 1.6;
        }
      }
    }
  }
}
