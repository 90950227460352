@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-faq {
  &Intro {
    margin: 0 0 4.4vw;
    position: relative;
    z-index: 1;

    @include m.pcOver {
      margin: 0 0 60px;
    }

    &_ring {
      position: absolute;
      top: 17%;
      left: -8.6%;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
  }

  &Wrap {
    margin: -8vw 0 13.2vw;

    @include m.pcOver {
      margin: -120px 0 180px;
    }
    
    @include m.tab {
      margin: -100px 0 180px;
    }

    @include m.sp {
      margin: -8vh 0 12rem;
    }
  }

  &Cont {
    padding: 8vw 0 0;

    @include m.pcOver {
      padding: 120px 0 0;
    }

    @include m.tab {
      padding: 100px 0 0;
    }

    @include m.sp {
      padding: 8vh 0 0;
    }

    &:first-child {
      padding-top: 8vw;
      
      @include m.pcOver {
        padding-top: 120px;
      }
      
      @include m.tab {
        padding-top: 100px;
      }

      @include m.sp {
        padding-top: 8vh;
      }
    }

    &_head {
      width: 100%;
      padding: 0 0 1vw;
      text-align: center;
      position: relative;
      z-index: 1;

      @include m.pcOver {
        padding: 0 0 13px;
      }

      @include m.sp {
        padding: 0 0 1rem;
      }

      &Ring {
        $this: &;
        position: absolute;
        z-index: 2;
        transform: translate(-50%, -50%);

        &01 {
          @extend #{$this};
          width: 29%;
          top: -72%;
          left: 123.5%;

          @include m.pc {
            max-width: 260px;
          }
        }

        &02 {
          @extend #{$this};
          width: 53%;
          top: 585%;
          left: -30.7%;
        }
      }
    }

    &_input {
      display: none;

      &:checked + ul {
        li {
          &:nth-child(n + 5) {
            display: block;
          }
        }
      }
    }
  }

  &List {
    border-top: 1px solid v.$grayPale02;
    &_item {
      border-bottom: 1px solid v.$grayPale02;

      &:first-child {
        padding: 2.6vw 0;
      }

      & + & {
        margin: 1.3vw 0 0;
        padding: 1.3vw 0 2.6vw;

        @include m.pcOver {
          margin: 18px 0 0;
          padding: 18px 0 36px;
        }

        @include m.sp {
          margin: 0.8rem 0 0;
          padding: 0.8rem 0 1.6rem;
        }
      }

      &:nth-child(n + 5) {
        display: none;
      }

      &:last-child {
        padding-bottom: 2.7vw;
        border-bottom: 1px solid v.$grayPale02;

        @include m.pcOver {
          padding-bottom: 36px;
        }

        @include m.sp {
          padding-bottom: 1.5rem;
        }
      }

      &Box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & + & {
          &,
          & * {
            max-height: 0;
            opacity: 0;
            pointer-events: none;
            transition: margin v.$anime, max-height v.$anime, opacity v.$anime;
          }
        }

        &:first-child {
          @include m.pc {
            cursor: pointer;

            .p-faqInfo_lead {
              transition: color v.$anime;
            }

            &:hover {
              .p-faqInfo_lead {
                color: v.$blue;
              }

              .p-faqList_itemBoxIco {
                &:before,
                &:after {
                  background-color: v.$blue;
                }
              }
            }
          }
        }

        &.is-active {
          & + .p-faqList_itemBox {
            margin: 2.4vw 0 0;
            pointer-events: auto;
            max-height: 100%;
            opacity: 1;

            & * {
              pointer-events: auto;
              max-height: 100%;
              opacity: 1;
            }

            @include m.pcOver {
              margin: 32px 0 0;
            }
          }

          .p-faqList_itemBoxIco {
            &:after {
              transform: translate(-50%, -50%) rotate(0);
            }
          }
        }

        &Ico {
          width: 2.4vw;
          height: 2.4vw;
          margin: 0 0 0 2.4vw;
          position: relative;
          z-index: 1;

          @include m.pcOver {
            width: 30px;
            height: 30px;
            margin: 0 0 0 30px;
          }

          @include m.sp {
            width: 2rem;
            height: 2rem;
            margin: 0 0 0 2rem;
          }

          &:before,
          &:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: v.$grayPale02;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            transition: background-color v.$anime, transform v.$anime;
          }

          &:before {
            transform: translate(-50%, -50%);
          }

          &:after {
            transform: translate(-50%, -50%) rotate(-90deg);
          }
        }
      }
    }
  }

  &Info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &_head {
      width: 2.4vw;
      margin: 0 0.5vw 0 0;

      @include m.pcOver {
        width: 30px;
        margin: 0 6px 0 0;
      }

      @include m.sp {
        width: 2rem;
        margin: 0 1rem 0 0;
      }
    }

    &_body {
      flex: 1;
    }

    &_heading {
      $this: &;
      font-family: v.$oswald;
      @include m.fz(25);
      font-weight: 500;
      line-height: 1;

      @include m.sp {
        font-size: 1.7rem;
      }

      &--question {
        @extend #{$this};
        color: v.$blue;
      }

      &--answer {
        @extend #{$this};
        color: v.$greenGrad02;
      }
    }

    &_lead {
      @include m.fz(18);
      font-weight: bold;
      line-height: 1.5555555555555556;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1.4rem;
      }
    }

    &_text {
      @include m.fz(16);
      line-height: 2;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1.3rem;
      }
    }

    &_more {
      opacity: 0;
      pointer-events: none;
      
      &.is-view {
        opacity: 1;
        pointer-events: auto;
        margin-top: 3vw;
        
        @include m.pcOver {
          margin-top: 40px;
        }
  
        @include m.sp {
          margin-top: 4rem;
        }
      }
    }
  }

  &More {
    margin: 6.6vw 0 0;

    @include m.pcOver {
      margin: 90px 0 0;
    }

    @include m.sp {
      margin: 6rem 0 0;
    }

    &_inner {
      // .p-faqCont {
      //   display: none;
      // }

      &.is-view {
        .p-faqCont {
          display: block;
        }
      }
    }

    &_btn {
      margin: 6.6vw 0 0;

      @include m.pcOver {
        margin: 90px 0 0;
      }

      @include m.sp {
        margin: 6rem 0 0;
      }
    }
  }

  &Hide {
    margin: 0 0 8.8vw;

    @include m.pcOver {
      margin: 0 0 120px;
    }

    @include m.sp {
      margin: 0 0 10rem;
    }
  }
}
