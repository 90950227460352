@use 'global/mixin' as m;
@use 'global/variable' as v;

@for $i from 1 through 20 {
  .u-ma#{$i*5} {
    margin: $i * 5px !important;
  }

  .u-mt#{$i*5} {
    margin-top: $i * 5px !important;
  }

  .u-mr#{$i*5} {
    margin-right: $i * 5px !important;
  }

  .u-mb#{$i*5} {
    margin-bottom: $i * 5px !important;
  }

  .u-ml#{$i*5} {
    margin-left: $i * 5px !important;
  }
}
