@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-lapse {
  width: 100%;

  &_cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Box {
      width: 47%;
      position: relative;
      z-index: 1;

      @include m.pc {
        max-width: 230px;
      }

      @include m.pcOver {
        max-width: none;
        width: 230px;
      }

      @include m.sp {
        width: 48%;
      }

      &Pic {
        aspect-ratio: auto 460 / 360;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &Arrow {
      width: 12px;
      margin: 7px 9px 0;
    }
  }
}
