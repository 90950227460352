@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-link {
  &Text {
    $this: &;

    @include m.pc {
      display: inline-block;
      transition: color v.$anime;
      position: relative;
      z-index: 1;

      &:hover {
        color: v.$blue;

        .c-linkText_inner {
          &:after {
            width: 100%;
          }

          &--underline {
            opacity: 0.7;
          }
        }
      }
    }

    &--white {
      @extend #{$this};

      @include m.pc {
        .c-linkText {
          &_inner {
            &:after {
              background-color: v.$white;
            }
          }
        }
      }

      @include m.pc {
        &:hover {
          color: v.$white;
        }
      }
    }

    &--blue {
      @extend #{$this};
      color: v.$blue;

      &[href$='.pdf'] {
        &:after {
          background: url('../img/common/ico_pdf_blue.svg') no-repeat center;
          background-size: contain;
        }
      }
    }

    &[href$='.pdf'] {
      &:after {
        content: '';
        width: 1.3vw;
        height: 1.3vw;
        margin: 0 0 -0.2vw 2px;
        display: inline-block;

        @include m.pcOver {
          width: 16px;
          height: 16px;
        }

        @include m.sp {
          width: 1.6rem;
          height: 1.6rem;
          margin: 0 0 -0.3rem 4px;
        }
      }
    }

    &_inner {
      $this: &;
      display: inline-block;
      font-size: inherit;
      line-height: 1;
      position: relative;
      z-index: 1;

      @include m.pc {
        transition: opacity v.$anime;

        &:after {
          content: '';
          width: 0;
          height: 1px;
          background-color: v.$blue;
          position: absolute;
          top: calc(100% + 2px);
          left: 0;
          z-index: 2;
          transition: width v.$anime;
        }
      }

      &--underline {
        @extend #{$this};

        @include m.sp {
          text-decoration: underline;
          line-height: 1.5;
        }

        &:after {
          width: 100%;
        }
      }

      &Ico {
        &--external {
          width: 12px;
          margin: 0 0 0 6px;
          display: inline-block;

          @include m.sp {
            width: 1.2rem;
            margin: 0 0 0 .8rem;
          }
        }
      }
    }
  }

  &Tel {
    font-size: inherit;
    @include m.pc {
      pointer-events: none;
    }

    @include m.sp {
      color: v.$blue;
      text-decoration: underline;
    }
  }

  &Out {
    font-size: inherit;
    color: v.$black;
    text-decoration: none !important;
    pointer-events: none !important;
  }

  &Mail {
    font-size: inherit;
    color: v.$blue;
    text-decoration: underline;

    @include m.pc {
      transition: opacity v.$anime;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &Line {
    text-decoration: underline;

    @include m.pc {
      transition: opacity v.$anime;

      &:hover {
        opacity: 0.7 !important;
      }
    }

    &_external {
      width: 1vw;
      max-width: 16px;
      margin-right: 2px;
      margin-left: 2px;
      display: inline-block;

      @include m.sp {
        width: 1.5rem;
        margin-bottom: -2px;
      }
    }
  }
}
