@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-pager {
  margin: 40px 0 0;

  @include m.pcOver {
    margin: 40px 0 0;
  }

  @include m.sp {
    margin: 3rem 0 0;
  }

  &Cont {
    $this: &;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &_main {
      margin: 0 13px;

      @include m.sp {
        margin: 0 3rem;
      }
    }

    &--reverse {
      @extend #{$this};

      .c-pager {
        &Sub {
          &--prev,
          &--next {
            background-color: rgba(v.$blue, 0.5);

            &:after {
              border-color: v.$white;
            }
          }
        }

        &Nav {
          &_item {
            color: v.$blue;

            &.is-active {
              color: v.$white;
              background-color: v.$blue;
            }
          }
        }
      }
    }
  }

  &Sub {
    $this: &;
    width: 18px;
    height: 18px;
    display: block;
    background-color: rgba(v.$white, 0.5);
    border-radius: 50%;
    position: relative;
    z-index: 1;

    @include m.pc {
      cursor: pointer;
      transition: background-color v.$anime;

      &:after {
        transition: border-color v.$anime;
      }

      &:hover {
        background-color: rgba(v.$white, 1);
      }
    }

    @include m.sp {
      width: 2.5rem;
      height: 2.5rem;
    }

    &:after {
      content: '';
      width: 5px;
      height: 5px;
      display: block;
      position: absolute;
      top: 50%;
      z-index: 2;
      transform: translate(-50%, -50%) rotate(-45deg);

      @include m.sp {
        width: 0.7rem;
        height: 0.7rem;
      }
    }

    &--prev {
      @extend #{$this};

      &:after {
        left: calc(50% + 1px);
        border-top: 1px solid v.$blue;
        border-left: 1px solid v.$blue;
      }
    }

    &--next {
      @extend #{$this};

      &:after {
        left: calc(50% - 1px);
        border-right: 1px solid v.$blue;
        border-bottom: 1px solid v.$blue;
      }
    }
  }

  &Nav {
    display: flex;
    justify-content: center;
    align-items: center;

    &_list {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      overflow: hidden;

      @include m.sp {
        width: 3rem;
        height: 3rem;
      }

      & + & {
        margin: 0 0 0 8px;

        @include m.sp {
          margin: 0 0 0 1.5rem;
        }
      }
    }

    &_item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: v.$oswald;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.08em;
      color: v.$white;

      @include m.pc {
        transition: color v.$anime, background-color v.$anime;

        &:hover {
          color: v.$blue;
          background-color: v.$white;
        }
      }

      @include m.tab {
        font-size: 12px;
      }

      @include m.sp {
        font-size: 1.3rem;
      }

      &.is-active {
        color: v.$blue;
        background-color: v.$white;
      }
    }
  }
}
