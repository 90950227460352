@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-other {
  &Map {
    &_wrap {
      width: 100%;

      @include m.pc {
        display: flex;
        justify-content: space-between;
      }

      &Cont {
        & + & {
          @include m.sp {
            margin-top: 5rem;
          }
        }
      }
    }

    &_box {
      & + & {
        margin-top: 3.5vw;

        @include m.pcOver {
          margin-top: 40px;
        }

        @include m.sp {
          margin-top: 5rem;
        }
      }
    }

    &_arrow {
      display: flex;
      align-items: flex-end;
    }
  }

  &Policy {
    &_cont {
      & + & {
        margin-top: 7.4vw;

        @include m.pcOver {
          margin-top: 100px;
        }

        @include m.sp {
          margin-top: 6rem;
        }
      }
    }

    &_intro {
      margin-bottom: 1.5vw;

      @include m.pcOver {
        margin-bottom: 30px;
      }

      @include m.sp {
        margin-bottom: 2rem;
      }
    }

    &_heading {
      @include m.sp {
        text-indent: -1.3em;
        padding: 0 0 0 1.3em;
      }
    }

    &_text {
      @include m.sp {
        text-indent: -1.7em;
        padding: 0 0 0 1.7em;
      }
    }

    &_inner {
      & + & {
        margin-top: 3vw;

        @include m.pcOver {
          margin-top: 40px;
        }

        @include m.sp {
          margin-top: 3rem;
        }
      }
    }

    &_lead {
      margin: 0 0 0.5vw;

      @include m.pcOver {
        margin: 0 0 5px;
      }

      @include m.sp {
        margin: 0 0 0.5rem;
      }
    }
  }

  &Web {
    &_cont {
      margin-bottom: 11vw;

      @include m.pcOver {
        margin-bottom: 150px;
      }

      @include m.sp {
        margin-bottom: 4rem;
      }

      &Box {
        & + & {
          margin-top: 4.4vw;

          @include m.pcOver {
            margin-top: 60px;
          }

          @include m.sp {
            margin-top: 4rem;
          }
        }
      }
    }

    &_heading {
      margin-bottom: 0.8vw;

      @include m.pcOver {
        margin-bottom: 10px;
      }

      @include m.sp {
        margin-bottom: 0.5rem;
      }
    }

    &_note {
      &List {
        text-indent: -1.9em;
        padding: 0 0 0 1.9em;
      }
    }

    &_table {
      @include m.sp {
        width: 100%;
        overflow-x: scroll;
      }

      table {
        width: 100%;

        @include m.sp {
          width: 910px;
        }

        th,
        td {
          @include m.fz(14);
          line-height: 1.5;
          letter-spacing: 0.04em;

          @include m.pcOver {
            font-size: 14px;
          }

          @include m.sp {
            font-size: 1.4rem;
          }
        }

        thead {
          th {
            padding: 8px;
            min-width: 80px;
            text-align: center;
            vertical-align: middle;
            color: v.$white;
            background-color: v.$blue;

            @include m.tab {
              min-width: 50px;
            }

            & + th {
              border-left: 1px solid v.$white;
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid v.$grayPale02;
          }
          td {
            padding: 20px 12px;
            vertical-align: middle;

            @include m.pcOver {
              padding: 20px 12px;
            }
          }
        }
      }

      &Cell {
        &--large {
          width: 292px;
        }
      }
    }

    &_contact {
      width: 100%;
      margin: 0 auto;
      padding: 2.3%;
      text-align: center;

      @include m.pc {
        max-width: 720px;
      }

      @include m.pcOver {
        padding: 30px;
      }

      @include m.sp {
        padding: 2rem;
      }

      &Link {
        display: flex;
        justify-content: center;
        align-items: center;

        &Ico {
          width: 1.1vw;
          margin-right: 4px;

          @include m.pcOver {
            width: 15px;
          }
        }
      }
    }
  }
}
