@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-panel {
  &_cont {
    @include m.pc {
      display: flex;
      justify-content: space-between;
    }

    &Box {
      @include m.pc {
        width: 48%;
        max-width: 530px;
      }

      & + & {
        @include m.sp {
          margin: 3rem 0 0;
        }
      }
    }
  }

  &_item {
    width: 100%;
    display: block;
    color: v.$white;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include m.pc {
      .c-panel {
        &_item {
          &Img {
            &:after {
              transition: background-color v.$anime;
            }

            img {
              transition: transform v.$anime;
            }
          }

          &Text {
            &:after {
              transition: transform v.$anime;
            }
          }
        }
      }

      &:hover {
        .c-panel {
          &_item {
            &Img {
              &:after {
                background-color: rgba(v.$black, 0.1);
              }

              img {
                transform: scale(1.05);
              }
            }

            &Text {
              &:after {
                transform: translate(4px, -50%);
              }
            }
          }
        }
      }
    }

    &Img {
      width: 100%;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(v.$black, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        mix-blend-mode: multiply;
      }
    }

    &Inner {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      transform: translate(-50%, -50%);

      @include m.sp {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    &Text {
      @include m.fz(18);
      font-weight: bold;
      line-height: 1.1;
      letter-spacing: 0.04em;
      border-bottom: 1px solid;
      position: relative;
      z-index: 1;

      @include m.sp {
        font-size: 1.6rem;
      }

      &:after {
        content: '';
        width: 20px;
        height: 5px;
        background: url('../img/common/ico_arrow_white.svg') no-repeat left top;
        background-size: 20px 5px;
        position: absolute;
        top: 50%;
        left: calc(100% + 4px);
        z-index: 2;
        transform: translate(0, -50%);
      }
    }
  }
}
