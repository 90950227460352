@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-note {
  padding: 1.2vw 0 0;

  @include m.pcOver {
    padding: 16px 0 0;
  }

  @include m.sp {
    padding: 1rem 0 0;
  }

  &_list {
    $this: &;
    padding: 0 0 0 1em;
    text-indent: -1em;
    @include m.fz(12);
    line-height: 1.4;
    letter-spacing: 0.04em;

    @include m.sp {
      font-size: 1.1rem;
    }

    &--num {
      @extend #{$this};
      padding: 0 0 0 1.5em;
      text-indent: -1.5em;
    }
  }

  &Box {
    padding: 1.5vw;
    border: 1px solid v.$grayPale02;

    @include m.pc {
      padding: 20px;
    }

    @include m.sp {
      padding: 1.5rem 2rem;
    }

    &_intro {
      margin: 0 0 0.5vw;

      @include m.pcOver {
        margin: 0 0 6px;
      }

      @include m.sp {
        margin: 0 0 5px;
      }
    }

    &_list {
      &Item {
        & + & {
          margin: 2px 0 0;
        }
      }
    }
  }
}
