@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-flex {
  &Jc {
    &--center {
      justify-content: center;
    }
  }
}
