@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-area {
  width: 100%;
  padding: 2.1vw 2.3vw 2.7vw;

  @include m.pcOver {
    padding: 28px 31px 36px;
  }

  @include m.sp {
    padding: 2rem 1.5rem 3rem;
  }

  &_cont {
    &--col02 {
      width: 100%;

      @include m.pc {
        display: flex;
        justify-content: space-between;
      }

      .c-area {
        &_cont {
          &Box {
            @include m.pc {
              width: 49%;
              max-width: 490px;
            }

            & + .c-area_contBox {
              @include m.sp {
                margin: 3rem 0 0;
              }
            }

            &--half {
              @include m.pc {
                width: 50%;
              }
              & + .c-area_contBox--half {
                @include m.sp {
                  margin: 3rem 0 0;
                }
              }
            }

            &--w435 {
              @include m.pc {
                width: 100%;
                max-width: 435px;
              }

              & + .c-area_contBox--w435 {
                @include m.sp {
                  margin: 3rem 0 0;
                }
              }
            }
          }
        }
      }
    }

    &--col03 {
      $this: &;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include m.pc {
        &:after {
          content: '';
          width: 31%;
          display: block;
        }
      }

      @include m.sp {
        align-items: baseline;
      }

      .c-area {
        &_cont {
          &Box {
            @include m.pc {
              width: 31%;
              max-width: 320px;
              padding: 0 0 6%;
              display: flex;
              align-items: flex-start;
            }

            @include m.sp {
              width: 47%;
            }

            &:nth-child(n + 3) {
              @include m.sp {
                margin: 3rem 0 0;
              }
            }

            &:nth-child(n + 4) {
              @include m.pcOver {
                margin: 40px 0 0;
              }

              @include m.pc {
                margin: 3vw 0 0;
              }
            }

            &--large {
              @include m.pc {
                width: 31%;
                max-width: 340px;
                padding: 0 0 6%;
                display: flex;
                align-items: flex-start;
              }

              @include m.sp {
                width: 47%;
              }

              &:nth-child(n + 3) {
                @include m.sp {
                  margin: 3rem 0 0;
                }
              }

              &:nth-child(n + 4) {
                @include m.pcOver {
                  margin: 40px 0 0;
                }

                @include m.pc {
                  margin: 3vw 0 0;
                }
              }

              &Inner {
                @include m.pc {
                  width: 31%;
                  max-width: 340px;
                  align-items: flex-start;
                }

                @include m.sp {
                  width: 47%;
                }

                &:nth-child(n + 3) {
                  @include m.sp {
                    margin: 3rem 0 0;
                  }
                }
              }
            }
          }
        }
      }

      &--pc {
        @extend #{$this};

        @include m.sp {
          display: block;

          .c-area_cont {
            &Box {
              width: 100%;

              & + .c-area_contBox {
                margin: 5rem 0 0;
              }

              &--large {
                width: 100%;

                & + .c-area_contBox--large {
                  margin: 5rem 0 0;
                }
              }
            }
          }
        }
      }
    }

    &--col04 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include m.pc {
        &:before,
        &:after {
          content: '';
          max-width: 245px;
          width: 23%;
          display: block;
        }

        &:before {
          order: 1;
        }

        &:after {
          order: 2;
        }
      }

      .c-area {
        &_cont {
          &Box {
            @include m.pc {
              width: 23%;
              max-width: 245px;
              padding: 0 0 6%;
              align-items: flex-start;
            }

            @include m.sp {
              width: 47%;
              margin: 0 0 3rem;
            }

            & + .c-area_contBox {
              @include m.sp {
                margin: 0 0 3rem;
              }
            }

            &--inner {
              @include m.pc {
                width: 23%;
                max-width: 245px;
                align-items: flex-start;
              }

              @include m.sp {
                width: 47%;
              }

              &:nth-child(n + 3) {
                @include m.sp {
                  margin: 3rem 0 0;
                }
              }
            }

            &--column {
              display: none;

              @include m.pc {
                width: 23%;
                max-width: 245px;
                padding: 0 0 6%;
                align-items: flex-start;
              }

              @include m.sp {
                width: 47%;
                margin: 0 0 3rem;
              }

              &.is-view {
                display: flex;
              }
            }
          }
        }
      }
    }

    &Pic {
      & + .c-area_contInner {
        margin: 0.8vw 0 0;

        @include m.pcOver {
          margin: 10px 0 0;
        }

        @include m.sp {
          margin: 1rem 0 0;
        }
      }

      &--type02 {
        & + .c-area_contInner {
          margin: 1.5vw 0 0;

          @include m.pcOver {
            margin: 10px 0 0;
          }

          @include m.sp {
            margin: 1rem 0 0;
          }
        }
      }
    }

    &Inner {
      width: 100%;

      &Intro {
      }
    }

    &Lead {
      margin: 0 0 1vw;
      display: inline-block;
      @include m.fz(18);
      font-weight: bold;
      line-height: 1.5555555555555556;
      letter-spacing: 0.04em;
      @include m.pcOver {
        margin: 0 0 13px;
      }

      @include m.sp {
        font-size: 1.4rem;
      }
    }

    &Text {
      @include m.fz(14);
      font-weight: 500;
      line-height: 2;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1.2rem;
      }
    }

    &More {
      &:nth-child(n + 4) {
        @include m.pc {
          display: none;
        }
      }

      &:nth-child(n + 3) {
        @include m.sp {
          display: none;
        }
      }

      // &.is-view {
      //   @include m.pc {
      //     display: flex;
      //   }

      //   @include m.sp {
      //     display: block;
      //   }
      // }
    }
  }

  &_btn {
    $this: &;
    width: 300px;
    margin: 2.2vw auto 0;

    @include m.pcOver {
      margin: 27px auto 0;
    }

    @include m.sp {
      width: 25rem;
      margin: 3rem auto 0;
    }

    &--long {
      @extend #{$this};
      width: 68%;
      margin: 3.7vw auto 0;

      @include m.pc {
        max-width: 700px;
      }

      @include m.pcOver {
        margin: 50px auto 0;
      }

      @include m.sp {
        width: 100%;
        margin: 3.5rem auto 0;
      }
    }
  }

  &_more {
    @include m.sp {
      margin: 3rem 0 0;
    }

    &Check {
      display: none;

      &:checked + .c-area_cont--col03 {
        .c-area_contMore {
          @include m.pc {
            display: flex;
          }

          @include m.sp {
            display: block;
          }
        }
      }
    }
  }
}
