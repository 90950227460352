@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-modal {
  &Map {
    position: fixed;
    left: 0;
    top: 0;
    z-index: -101;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    pointer-events: none;

    &.is-view {
      z-index: 101;
      opacity: 1;
      pointer-events: auto;
    }

    #gmap {
      width: 600px;
      height: 400px;

      @include m.sp {
        width: 100%;
        height: 300px;
      }
    }

    &_inner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include m.sp {
        left: 0;
        right: 0;
        transform: translateY(-50%);
        width: 80%;
        margin: 0 auto;
      }
    }

    &_body {
      padding: 45px 40px;
      background: v.$white;

      @include m.sp {
        padding: 2rem 2rem 4rem;
      }

      &Inner {
        width: 600px;
        height: 400px;

        @include m.sp {
          width: 100%;
          height: 19rem;
        }

        .c-modalMap_frame,
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    &_link {
      margin-top: 10px;
      text-align: right;
      @include m.fz(12);

      @include m.pc {
        .c-modalMap_linkItem {
          &:before {
            transition: background-image v.$anime;
          }
        }

        &:hover {
          .c-modalMap_linkItem {
            &:before {
              background: url('../img/common/ico_external.svg') no-repeat center;
              background-size: contain;
            }
          }
        }
      }

      @include m.sp {
        position: relative;
      }

      &Item {
        display: inline-block;
        position: relative;
        &:before {
          content: '';
          width: 13px;
          height: 13px;
          display: block;
          background: url('../img/common/ico_external_black.svg') no-repeat center;
          background-size: contain;
          position: absolute;
          top: 50%;
          right: calc(100% + 8px);
          z-index: 2;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

          @include m.sp {
            width: 1.3rem;
            height: 1.3rem;
          }
        }
      }
    }

    &_close {
      width: 100%;
      margin-top: 15px;

      &Ico {
        width: 14px;
        height: 14px;
        display: block;
        position: relative;
        z-index: 1;

        &:before,
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: v.$white;
          position: absolute;
          top: calc(50% + 1px);
          left: calc(50% - 4px);
          z-index: 2;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }

      &Text {
        display: flex;
        justify-content: center;
        align-items: center;
        color: v.$white;
        border: none;
        cursor: pointer;
        font-size: 1.4rem;
        transition: color 0.3s linear;

        @include m.pc {
          &:hover {
            color: v.$grayPale03;
          }
        }
      }
    }
  }

  &Overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    opacity: 0;
    pointer-events: none;
    transition: opacity v.$anime;
    background: rgba(0, 0, 0, 0.8);

    &.is-view {
      opacity: 1;
    }
  }

  &Figure {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 102;
    translate: -50% -50%;
    opacity: 0;
    transition: opacity v.$anime;
    pointer-events: none;

    @include m.pc {
      max-width: 280px;
      width: 100%;
    }

    @include m.sp {
      width: 80%;
      margin: 0 auto;
    }

    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }

    &_inner {
      padding: 45px 40px;
      background: v.$white;

      @include m.sp {
        padding: 20px;
      }
    }

    &_close {
      width: 100%;
      margin-top: 15px;

      &Ico {
        width: 14px;
        height: 14px;
        display: block;
        position: relative;
        z-index: 1;

        &:before,
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: v.$white;
          position: absolute;
          top: calc(50% + 1px);
          left: calc(50% - 4px);
          z-index: 2;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }

      &Text {
        display: flex;
        justify-content: center;
        align-items: center;
        color: v.$white;
        border: none;
        cursor: pointer;
        font-size: 1.4rem;
        transition: color 0.3s linear;

        @include m.pc {
          &:hover {
            color: v.$grayPale03;
          }
        }
      }
    }
  }
}
