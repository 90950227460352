@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-pp {
  &Index {
    &_about {
      width: 100%;
      margin: 4.4vw 0 0;
      padding: 11vw 0 13.2vw;

      @include m.pcOver {
        margin: 60px 0 0;
        padding: 150px 0 180px;
      }

      @include m.pc {
        display: flex;
        justify-content: space-between;
      }

      @include m.tab {
        padding: 12vw 0;
      }

      @include m.sp {
        margin: 4rem 0 0;
        padding: 5rem 0 7rem;
      }

      &Logo {
        width: 33%;
        margin: 0 2.8vw 0 0;
        @include m.pc {
          max-width: 360px;
        }
        @include m.pcOver {
          margin: 0 40px 0 0;
        }
        @include m.sp {
          width: 55%;
          margin: 0 0 1.5rem;
        }
      }

      &Box {
        @include m.pc {
          flex: 1;
        }
        @include m.tab {
          padding-right: 2em;
        }
      }
    }

    &_main {
      margin: -10vw 0 0;
      padding: 10vw 0;

      @include m.pcOver {
        margin: -136px 0 0;
        padding: 136px 0 120px;
      }

      @include m.tab {
        margin: -8.5vw 0 0;
      }

      @include m.sp {
        margin: -4rem 0 0;
        padding: 6rem 0;
      }
    }

    &_box {
      & + & {
        margin: 5.8vw 0 0;

        @include m.pcOver {
          margin: 80px 0 0;
        }

        @include m.tab {
          margin: 6vw 0 0;
        }

        @include m.sp {
          margin: 6rem 0 0;
        }
      }
    }

    &_ring {
      $this: &;
      width: 100%;
      position: absolute;
      translate: -50% -50%;

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 28%;
        top: 88%;
        left: -8%;
        z-index: -1;

        @include m.pc {
          max-width: 320px;
        }

        @include m.pcOver {
          top: 81.5%;
        }

        @include m.tab {
          top: 71%;
          left: -7.2%;
        }
      }

      &02 {
        @extend #{$this};
        width: 41%;
        top: -7.7%;
        left: -12.5%;
        z-index: 2;

        @include m.pc {
          max-width: 480px;
        }

        @include m.pcOver {
          top: -8.9%;
        }

        @include m.tab {
          top: -8.8%;
          left: -4.5%;
        }
      }

      &03 {
        @extend #{$this};
        width: 30%;
        top: 91.4%;
        left: 103%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }

        @include m.pcOver {
          top: 90.5%;
        }

        @include m.tab {
          top: 90%;
          left: 96%;
        }
      }

      &04 {
        @extend #{$this};
        width: 30%;
        top: -7.5%;
        left: 103%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }

        @include m.tab {
          width: 25%;
          top: -7%;
          left: 99%;
        }
      }
    }
  }

  &Park {
    margin: -10.3vw 0 -10vw;
    padding: 12vw 0 16vw;

    @include m.pcOver {
      margin: -140px 0 -100px;
      padding: 163px 0 191px;
    }

    @include m.sp {
      margin: -4rem 0;
      padding: 8rem 0 10rem;
    }

    &_about {
      width: 100%;

      &Heading {
        width: 32%;
        max-width: 288px;
        margin: 0 auto 2.3vw;

        @include m.pcOver {
          margin: 0 auto 30px;
        }

        @include m.pc {
        }

        @include m.sp {
          width: 50%;
          margin: 0 auto 2rem;
          max-width: 170px;
        }
      }
    }

    &_point {
      margin-bottom: 2.3vw;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @include m.pcOver {
        margin-bottom: 30px;
      }

      @include m.sp {
        margin-bottom: 2rem;
        flex-wrap: wrap;
      }

      &List {
        width: 21%;
        @include m.pc {
          max-width: 190px;
        }

        @include m.sp {
          width: 50%;
        }

        & + & {
          @include m.pc {
            margin-left: 1.5vw;
          }

          @include m.pcOver {
            margin-left: 12px;
          }
        }

        &:last-child {
          @include m.sp {
            margin-top: 1.5rem;
          }
        }
      }

      &Logo {
        width: 79%;
        margin: 0 auto 1vw;
        display: flex;
        justify-content: center;
        align-items: center;

        @include m.pcOver {
          margin: 0 auto 12px;
        }

        @include m.pc {
          max-width: 150px;
        }

        @include m.sp {
          width: 60%;
        }
      }

      &Text {
        @include m.fz(18);
        font-weight: bold;
        line-height: 1.3333333333333333;
        letter-spacing: 0.04em;
        text-align: center;
        color: v.$blue;

        @include m.sp {
          flex: 1;
          font-size: 1.6rem;
        }
      }
    }

    &_ring {
      $this: &;
      width: 100%;
      position: absolute;
      translate: -50% -50%;

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 36%;
        top: 48%;
        left: -25%;
        z-index: -1;

        @include m.pc {
          max-width: 319px;
        }

        @include m.tab {
          left: -12%;
        }
      }
    }
  }

  &Area {
    padding: 10.3vw 0;

    @include m.pcOver {
      padding: 140px 0;
    }

    @include m.sp {
      padding: 6rem 0;
    }

    &_intro {
      width: 100%;
      margin-bottom: 1vw;

      @include m.pcOver {
        margin-bottom: 12px;
      }

      @include m.sp {
        margin-bottom: 1.5rem;
      }

      @include m.pc {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }

    &_toggle {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include m.sp {
        display: none;
      }

      &List {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: v.$white;

        @include m.pc {
          cursor: pointer;
          transition: color v.$anime;

          .p-ppArea_toggleIco {
            transition: background-image v.$anime;
          }

          &:hover {
            color: v.$yellowDeep;

            .p-ppArea_toggleIco {
              &--map {
                &:before {
                  opacity: 0;
                }
                &:after {
                  opacity: 1;
                }
              }

              &--list {
                &:before {
                  opacity: 0;
                }
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }

        & + & {
          margin-left: 3vw;

          @include m.pcOver {
            margin-left: 40px;
          }

          @include m.sp {
            margin-left: 2rem;
          }
        }

        &.is-selected {
          color: v.$yellowDeep;
          .p-ppArea_toggle {
            &Ico {
              &--map {
                &:before {
                  opacity: 0;
                }
                &:after {
                  opacity: 1;
                }
              }
              &--list {
                &:before {
                  opacity: 0;
                }
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      &Ico {
        &--map,
        &--list {
          position: relative;
          z-index: 1;

          &:before,
          &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            @include m.pc {
              transition: opacity 0.1s ease;
            }
          }

          &:after {
            opacity: 0;
          }
        }

        &--map {
          width: 3vw;
          height: 3vw;
          margin: 0 10px 0 0;

          @include m.pcOver {
            width: 40px;
            height: 40px;
          }

          &:before {
            background: url('../img/personal_park/appark/ico_area.webp') no-repeat center;
            background-size: contain;
          }
          &:after {
            background: url('../img/personal_park/appark/ico_area_on.webp') no-repeat center;
            background-size: contain;
          }
        }

        &--list {
          width: 1.6vw;
          height: 1.5vw;
          margin: 0 10px 0 0;

          @include m.pcOver {
            width: 22px;
            height: 20px;
          }

          &:before {
            background: url('../img/personal_park/appark/ico_list.webp') no-repeat center;
            background-size: contain;
          }
          &:after {
            background: url('../img/personal_park/appark/ico_list_on.webp') no-repeat center;
            background-size: contain;
          }
        }
      }
    }

    &_wrap {
    }

    &_cont {
      display: none;
      &.is-view {
        display: block;
      }
    }

    &_tab {
      width: 100%;
      display: flex;

      &List {
        width: 50%;
        padding: 4px 0;
        text-align: center;
        color: rgba(v.$white, 0.17);
        background-color: v.$blue;

        @include m.pc {
          cursor: pointer;
          transition: color v.$anime, background-color v.$anime;

          &:hover {
            color: v.$blue;
            background-color: v.$yellowDeep;
          }
        }

        &.is-selected {
          color: v.$blue;
          background-color: v.$yellowDeep;
        }

        & + & {
          @include m.pc {
            border-left: 2px solid v.$blue;
          }
        }
      }
    }

    &_view {
      width: 100%;

      &Cont {
        display: none;
        justify-content: flex-end;
        background-color: v.$white;
        position: relative;
        z-index: 1;

        @include m.sp {
          padding: 0 0 3rem;
          overflow: hidden;
        }

        &.is-view {
          display: flex;
          @include m.sp {
            display: block;
          }
        }
      }

      &Pic {
        padding: 0 1vw 1.4vw 0;

        @include m.pcOver {
          padding: 0 12px 20px 0;
        }

        @include m.pc {
          width: 89%;
          max-width: 803px;
        }

        @include m.sp {
          margin: 0 0 5rem;
          display: flex;
          justify-content: flex-end;
          position: relative;
          z-index: 1;

          img {
            width: 110%;
            margin-right: -4rem;
          }
        }

        &:after {
          @include m.sp {
            content: '';
            width: 90%;
            height: 1px;
            display: block;
            background-color: v.$blue;
            position: absolute;
            bottom: -2.2rem;
            left: 50%;
            z-index: 2;
            translate: -50% 0;
          }
        }
      }

      &Anchor {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        @include m.pc {
          display: none;
          pointer-events: none;
        }

        &List {
          $this: &;
          text-align: center;
          border: 1px solid;
          position: absolute;
          z-index: 2;
          translate: -50% -50%;

          &--kansai {
            @extend #{$this};
            border-color: v.$areaKansai;
            top: 27%;
            left: 50%;

            .p-ppArea_viewAnchorItem {
              color: v.$areaKansai;
            }
          }
          &--tokai {
            @extend #{$this};
            border-color: v.$areaTokai;
            top: 58%;
            left: 72%;

            .p-ppArea_viewAnchorItem {
              color: v.$areaTokai;
            }
          }
          &--hokuriku {
            @extend #{$this};
            border-color: v.$areaHokuriku;
            top: 10.5%;
            left: 64%;

            .p-ppArea_viewAnchorItem {
              color: v.$areaHokuriku;
            }
          }
          &--chugoku {
            @extend #{$this};
            border-color: v.$areaChugoku;
            top: 33%;
            left: 28%;

            .p-ppArea_viewAnchorItem {
              color: v.$areaChugoku;
            }
          }
          &--shikoku {
            @extend #{$this};
            border-color: v.$areaShikoku;
            top: 76%;
            left: 40%;

            .p-ppArea_viewAnchorItem {
              color: v.$areaShikoku;
            }
          }
          &--kyusyu {
            @extend #{$this};
            border-color: v.$areaKyusyu;
            top: 42%;
            left: 12%;

            .p-ppArea_viewAnchorItem {
              color: v.$areaKyusyu;
            }
          }
          &--kanto {
            @extend #{$this};
            border-color: v.$areaKanto;
            top: 54%;
            left: 90%;

            .p-ppArea_viewAnchorItem {
              color: v.$areaKanto;
            }
          }
        }

        &Item {
          padding: 0.4rem 0.8rem;
          font-size: 1.2rem;
          white-space: nowrap;
        }
      }
    }

    &_unit {
      $this: &;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @include m.pc {
        flex-flow: column;
        justify-content: flex-start;
      }

      @include m.sp {
        margin: 0 0 2.5rem;
        padding: 0 5%;
      }

      &Name {
        width: 100%;
        @include m.sp {
          width: 38%;
          font-size: 1.3rem !important;
        }
      }

      &Menu {
        display: flex;
        flex-wrap: wrap;

        @include m.sp {
          flex: 1;
        }

        &List {
        }

        &Item {
          width: 100%;
          display: block;
          text-align: center;

          @include m.pc {
            transition: color v.$anime, background-color v.$anime;

            &:hover {
              color: v.$white !important;
            }
          }
        }
      }

      &--kyusyu {
        @extend #{$this};
        top: 34%;
        left: 15%;

        .p-ppArea_unit {
          &Name {
            color: v.$areaKyusyu;
          }

          &Menu {
            border-color: v.$areaKyusyu !important;

            &List {
              border-color: v.$areaKyusyu !important;
            }

            &Item {
              color: v.$areaKyusyu;

              @include m.pc {
                &:hover {
                  background-color: v.$areaKyusyu !important;
                }
              }
            }
          }
        }
      }
      &--chugoku {
        @extend #{$this};
        top: 25.5%;
        left: 30%;

        .p-ppArea_unit {
          &Name {
            color: v.$areaChugoku;
          }

          &Menu {
            border-color: v.$areaChugoku !important;

            &List {
              border-color: v.$areaChugoku !important;
            }

            &Item {
              color: v.$areaChugoku;

              @include m.pc {
                &:hover {
                  background-color: v.$areaChugoku !important;
                }
              }
            }
          }
        }
      }
      &--kansai {
        @extend #{$this};
        top: 20.5%;
        left: 45%;

        .p-ppArea_unit {
          &Name {
            color: v.$areaKansai;
          }

          &Menu {
            border-color: v.$areaKansai !important;

            &List {
              border-color: v.$areaKansai !important;
            }

            &Item {
              color: v.$areaKansai;

              @include m.pc {
                &:hover {
                  background-color: v.$areaKansai !important;
                }
              }
            }
          }
        }
      }
      &--hokuriku {
        @extend #{$this};
        top: 8%;
        left: 60.5%;

        @include m.tab {
          left: 59%;
        }

        .p-ppArea_unit {
          &Name {
            color: v.$areaHokuriku;
          }

          &Menu {
            border-color: v.$areaHokuriku !important;

            &List {
              border-color: v.$areaHokuriku !important;
            }

            &Item {
              color: v.$areaHokuriku;

              @include m.pc {
                &:hover {
                  background-color: v.$areaHokuriku !important;
                }
              }
            }
          }
        }
      }
      &--shikoku {
        @extend #{$this};
        @extend #{$this};
        top: 78%;
        left: 43%;

        .p-ppArea_unit {
          &Name {
            color: v.$areaShikoku;
          }

          &Menu {
            border-color: v.$areaShikoku !important;

            &List {
              border-color: v.$areaShikoku !important;
            }

            &Item {
              color: v.$areaShikoku;

              @include m.pc {
                &:hover {
                  background-color: v.$areaShikoku !important;
                }
              }
            }
          }
        }
      }
      &--tokai {
        @extend #{$this};
        top: 62%;
        left: 68%;

        .p-ppArea_unit {
          &Name {
            color: v.$areaTokai;
          }

          &Menu {
            border-color: v.$areaTokai !important;

            &List {
              border-color: v.$areaTokai !important;
            }

            &Item {
              color: v.$areaTokai;

              @include m.pc {
                &:hover {
                  background-color: v.$areaTokai !important;
                }
              }
            }
          }
        }
      }
      &--kanto {
        @extend #{$this};
        @extend #{$this};
        @extend #{$this};
        top: 50%;
        left: 85%;

        .p-ppArea_unit {
          &Name {
            color: v.$areaKanto;
          }

          &Menu {
            border-color: v.$areaKanto !important;

            &List {
              border-color: v.$areaKanto !important;
            }

            &Item {
              color: v.$areaKanto;

              @include m.pc {
                &:hover {
                  background-color: v.$areaKanto !important;
                }
              }
            }
          }
        }
      }

      /* 地図表示から探すver */
      &--map {
        .p-ppArea {
          &_unit {
            @include m.pc {
              position: absolute;
              z-index: 2;
              translate: -50% -50%;
            }

            &Name {
              @include m.pc {
                text-align: center;
              }
            }
            &Menu {
              margin: 0 auto;
              border-left: 1px solid;
              border-right: none;
              border-bottom: none;
              @include m.pc {
                width: 9vw;
              }
              @include m.pcOver {
                max-width: 120px;
              }
              @include m.tab {
                min-width: 90px;
              }
              &List {
                width: 50%;

                border-right: 1px solid;
                border-bottom: 1px solid;

                &:nth-child(2n + 1) {
                  @include m.pc {
                    border-right: 1px solid;
                  }
                }

                &:nth-child(-n + 2) {
                  border-top: 1px solid;
                }
              }
              &Item {
              }
            }
          }
        }
      }
      /* end 地図表示から探すver */

      /* リスト表示から探すver */
      &--list {
        background-color: v.$white;
        @include m.sp {
          display: none;
        }
        .p-ppArea {
          &_unit {
            &Name {
              text-align: left;
            }
            &Menu {
              width: 100%;
              margin-bottom: 1.4vw;
              @include m.pcOver {
                margin-bottom: 20px;
              }
              &List {
                width: 16.66%;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-left: 1px solid;

                &:nth-child(6n) {
                  @include m.pc {
                    border-right: 1px solid;
                  }
                }

                &:nth-child(n + 7) {
                  @include m.pc {
                    border-top: none;
                  }
                }

                &:last-child {
                  @include m.pc {
                    border-right: 1px solid;
                  }
                }
              }
            }
          }
        }
      }
      /* end リスト表示から探すver */
    }

    &_list {
      width: 100%;
      padding: 2.4vw;

      @include m.pc {
        display: flex;
        justify-content: space-between;
      }

      @include m.pcOver {
        padding: 30px;
      }

      &Box {
        @include m.pc {
          width: 45%;
          max-width: 400px;
        }
      }

      &Heading {
        width: 100%;
        margin-bottom: 1.1vw;
        padding: 4px 0;
        text-align: center;
        color: v.$white;
        background-color: v.$blue;

        @include m.pcOver {
          margin-bottom: 15px;
        }
      }
    }

    &_ring {
      $this: &;
      width: 100%;
      position: absolute;
      translate: -50% -50%;

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 37%;
        top: -22%;
        left: 116%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }

        @include m.sp {
          top: -34%;
          left: 106%;
        }
      }
      &02 {
        @extend #{$this};
        width: 51%;
        top: 21%;
        left: -27.5%;
        z-index: -1;

        @include m.pc {
          max-width: 479px;
        }

        @include m.tab {
          display: none;
        }
      }
      &03 {
        @extend #{$this};
        width: 37%;
        top: 88%;
        left: 116%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
        @include m.tab {
          display: none;
        }
      }
    }
  }

  /* 時間貸し駐車場&月極駐車場 */
  &Search {
    margin: 0 0 4.5vw;
    padding: 3vw 0 0;
    display: flex;
    justify-content: space-between;

    @include m.pcOver {
      margin: 0 0 60px;
      padding: 40px 0 0;
    }

    @include m.sp {
      margin: 0 0 6rem;
      padding: 4rem 0 0;
      display: block;
    }

    &_head {
      width: 30%;
      margin: 0 48px 0 0;

      @include m.pc {
        max-width: 245px;
      }

      @include m.sp {
        width: 100%;
        margin: 0 0 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &_logo {
      width: 100%;
      margin: 1.5vw auto 2vw;

      @include m.pc {
        max-width: 225px;
      }

      @include m.pcOver {
        margin: 20px auto 24px;
      }

      @include m.tab {
        max-width: 170px;
      }

      @include m.sp {
        width: 48%;
        margin: 0;
      }
    }

    &_category {
      $this: &;
      width: 100%;
      padding: 0.8vw 0;
      text-align: center;
      color: v.$white;
      background-color: v.$gray02;

      @include m.pcOver {
        padding: 10px 0;
      }

      @include m.sp {
        width: 50%;
        padding: 1rem 0;
      }

      &Text {
        @include m.fz(20);
        font-weight: bold;
        line-height: 1.75;

        @include m.sp {
          font-size: 1.6rem;
        }
      }

      &--kansai {
        @extend #{$this};
        background-color: v.$areaKansai;
      }
      &--tokai {
        @extend #{$this};
        background-color: v.$areaTokai;
      }
      &--hokuriku {
        @extend #{$this};
        background-color: v.$areaHokuriku;
      }
      &--chugoku {
        @extend #{$this};
        background-color: v.$areaChugoku;
      }
      &--shikoku {
        @extend #{$this};
        background-color: v.$areaShikoku;
      }
      &--kyusyu {
        @extend #{$this};
        background-color: v.$areaKyusyu;
      }
      &--kanto {
        @extend #{$this};
        background-color: v.$areaKanto;
      }
    }

    &_body {
      @include m.pc {
        flex: 1;
      }
    }

    &_heading {
      width: 100%;
      margin: 0 0 0.8vw;

      @include m.pcOver {
        margin: 0 0 10px;
      }

      @include m.sp {
        margin: 0 0 1rem;
      }
    }

    &_cont {
      display: flex;
      flex-wrap: wrap;

      & + & {
        margin-top: 1.2vw;
        padding-top: 1vw;
        border-top: 1px solid v.$grayPale02;

        @include m.pcOver {
          margin-top: 18px;
          padding-top: 13px;
        }

        @include m.sp {
          margin-top: 2rem;
          padding-top: 1.5rem;
        }
      }

      &Box {
        @include m.tab {
          width: 100%;
        }

        @include m.sp {
          width: 100%;
        }

        & + & {
          @include m.pc {
            margin-left: 1.4vw;
          }

          @include m.pcOver {
            margin-left: 20px;
          }

          @include m.tab {
            margin: 1.1vw 0 0 0;
          }

          @include m.sp {
            margin-top: 1.5rem;
          }
        }
      }

      form {
        width: 100%;
      }
    }

    &_form {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1em;

      &Head {
        @include m.tab {
          width: 18%;
        }

        @include m.sp {
          width: 32%;
        }
      }

      &Body {
        flex: 1;
        @include m.pc {
          width: 225px;
        }

        @include m.tab {
          max-width: none;
          width: auto;
        }
      }

      &Select {
        width: 100% !important;
      }
    }

    &_input {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &Head {
        flex: 1;
      }

      &Body {
        margin-left: 1.8vw;
        display: flex;
        align-items: center;

        @include m.pc {
          width: 100%;
          max-width: 160px;
        }

        @include m.pcOver {
          margin-left: 24px;
        }

        @include m.sp {
          width: 25%;
        }
      }
    }
  }

  &Result {
    width: 100%;
    padding: 0 0 9.2vw;

    @include m.pcOver {
      padding: 0 0 120px;
    }

    @include m.sp {
      padding: 0 0 10rem;
    }

    &_cont {
      @include m.tab {
        width: 100%;
        overflow-x: scroll;
      }
    }

    &_heading {
      $this: &;
      margin: 0 0 1.5vw;
      padding: 0 0 0 0.8vw;
      @include m.fz(32);
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0.04em;
      border-left: 5px solid;

      @include m.pcOver {
        margin: 0 0 20px;
        padding: 0 0 0 10px;
      }

      @include m.sp {
        margin: 0 0 1.5rem;
        padding: 0 0 0 1rem;
        font-size: 2.4rem;
      }

      &--kansai {
        @extend #{$this};
        border-color: v.$areaKansai;
      }
      &--tokai {
        @extend #{$this};
        border-color: v.$areaTokai;
      }
      &--hokuriku {
        @extend #{$this};
        border-color: v.$areaHokuriku;
      }
      &--chugoku {
        @extend #{$this};
        border-color: v.$areaChugoku;
      }
      &--shikoku {
        @extend #{$this};
        border-color: v.$areaShikoku;
      }
      &--kyusyu {
        @extend #{$this};
        border-color: v.$areaKyusyu;
      }
      &--kanto {
        @extend #{$this};
        border-color: v.$areaKanto;
      }
    }
  }
  /* end 時間貸し駐車場&月極駐車場 */

  &Notion {
    $this: &;
    width: 100%;
    margin: 0 0 3.2vw;
    padding: 1.5vw;
    background-color: v.$white;
    border: 3px solid v.$bluePale02;

    @include m.pc {
      display: flex;
      align-items: flex-end;
    }

    @include m.pcOver {
      margin: 0 0 42px;
      padding: 20px;
    }

    @include m.sp {
      margin: 0 0 3rem;
      padding: 1.5rem;
      text-align: center;
    }

    &--inner {
      @extend #{$this};
      margin: 0 0 2.5vw;

      @include m.pcOver {
        margin: 0 0 32px;
      }

      @include m.sp {
        margin: 0 0 2.5rem;
      }
    }

    &--block {
      @extend #{$this};

      @include m.pc {
        display: block;
      }
    }

    &_info {
      margin: 0 1.2vw 0 0;
      color: v.$blue;

      @include m.pc {
        display: inline-flex;
        align-items: center;
      }

      @include m.pcOver {
        margin: 0 16px 0 0;
      }

      @include m.sp {
        margin: 0 0 1rem;
      }

      &Head {
        @include m.fz(16);
        line-height: 1.5;

        @include m.sp {
          font-size: 1.4rem;
        }

        &:after {
          content: '：';
          font-size: inherit;

          @include m.sp {
            display: none;
          }
        }
      }

      &Body {
        @include m.fz(20);
        font-weight: bold;
        line-height: 1.2;

        @include m.sp {
          margin: 1rem 0 1.4rem;
          font-size: 2.4rem;
        }
      }
    }

    &_text {
      @include m.fz(14);
      line-height: 1.5;

      @include m.sp {
        font-size: 1.3rem;
      }
    }
  }

  &Contact {
    &_flow {
      margin: 0 0 4.5vw;

      @include m.pcOver {
        margin: 0 0 60px;
      }

      @include m.sp {
        margin: 0 0 5rem;
      }
    }

    &_table {
      margin: 0 0 3vw;
      border-bottom: 1px solid v.$grayPale02;

      @include m.pcOver {
        margin: 0 0 40px;
      }

      @include m.sp {
        margin: 0 0 2rem;
      }
    }

    &_notion {
      margin: 0 0 2.7vw;

      @include m.pcOver {
        margin: 0 0 28px;
      }

      @include m.sp {
        margin: 0 0 3rem;
      }
    }

    &_number {
      margin: 0 0 2.2vw;
      padding: 1.5vw;
      border: 1px solid v.$blue;

      @include m.pcOver {
        margin: 0 0 28px;
        padding: 20px;
      }

      @include m.sp {
        margin: 0 0 2rem;
        padding: 1.5rem;
      }

      &Lead {
        padding: 0 0 8px;
        @include m.fz(20);
        font-weight: bold;
        line-height: 1.2;

        @include m.sp {
          font-size: 1.8rem;
        }
      }

      &Text {
        @include m.fz(14);
        line-height: 1.7142857142857142;

        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }
  }
}
