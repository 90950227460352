@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-media {
  &Cont {
    $this: &;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include m.sp {
      flex-flow: column;
      justify-content: flex-start;
    }

    &--alignC {
      @extend #{$this};

      @include m.pc {
        align-items: center;
      }
    }

    &--reverse {
      @extend #{$this};

      @include m.sp {
        .c-mediaCont {
          &_box {
            margin: 0 0 2rem;
            order: 0;
          }
        }
      }
    }

    & + & {
      margin: 5.9vw 0 0;

      @include m.pcOver {
        margin: 80px 0 0;
      }

      @include m.sp {
        margin: 4rem 0 0;
      }
    }

    &_map {
      width: 100%;
      margin: 0 0 15px;

      &Frame {
        @include m.pc {
          height: 380px;
        }

        @include m.tab {
          height: 280px;
        }

        @include m.sp {
          height: 38rem;
          margin: 0 0 1rem;
        }

        iframe {
          width: 100%;
          height: 100%;
        }
      }

      &Inner {
        margin: 15px 0 0;
        display: flex;
        justify-content: flex-end;

        @include m.sp {
          margin: 1rem 0 0;
        }

        & + & {
          margin: 1.5vw 0 0;

          @include m.pcOver {
            margin: 12px 0 0;
          }

          @include m.sp {
            margin: 1rem 0 0;
          }
        }
      }

      &Link {
        $this: &;
        @include m.fz(12);

        @include m.pc {
          &:before {
            transition: background-image v.$anime;
          }

          &:hover {
            &:before {
              background: url('../img/common/ico_external.svg') no-repeat center;
              background-size: contain;
            }
          }
        }

        @include m.sp {
          position: relative;
        }

        &:before {
          content: '';
          width: 13px;
          height: 13px;
          display: block;
          background: url('../img/common/ico_external_black.svg') no-repeat center;
          background-size: contain;
          position: absolute;
          top: 50%;
          right: calc(100% + 8px);
          z-index: 2;
          transform: translateY(-50%);

          @include m.sp {
            width: 1.3rem;
            height: 1.3rem;
          }
        }

        &--white {
          @extend #{$this};

          &:before {
            background: url('../img/common/ico_external_white.svg') no-repeat center;
            background-size: contain;
          }

          @include m.pc {
            &:hover {
              &:before {
                background: url('../img/common/ico_external_white.svg') no-repeat center;
                background-size: contain;
              }
            }
          }
        }
      }
    }

    &_pic {
      $this: &;

      @include m.pc {
        width: 40%;
        max-width: 435px;
      }

      @include m.pcOver {
        width: 435px;
      }

      @include m.sp {
        order: 0;
      }

      & + .c-mediaCont_box {
        margin: 0 0 0 3vw;

        @include m.pcOver {
          margin: 0 0 0 40px;
        }

        @include m.sp {
          margin: 2rem 0 0;
        }
      }

      iframe {
        width: 100%;

        @include m.sp {
          height: 100%;
        }
      }

      &--order {
        &Last {
          @include m.sp {
            order: 2;
          }
        }
      }

      &--w340 {
        @extend #{$this};

        @include m.pc {
          width: 50%;
          max-width: 340px;
        }

        @include m.pcOver {
          width: 340px;
        }

        iframe {
          @include m.pc {
            height: 340px;
          }

          @include m.tab {
            height: 300px;
          }
        }
      }

      &--w380 {
        @extend #{$this};

        @include m.pc {
          width: 35%;
          max-width: 380px;
        }

        @include m.pcOver {
          width: 380px;
        }

        iframe {
          @include m.pc {
            height: 380px;
          }

          @include m.tab {
            height: 340px;
          }
        }

        &Inner {
          @extend #{$this};

          @include m.pc {
            width: 42%;
            max-width: 380px;
          }
        }
      }

      &--w475 {
        @extend #{$this};

        @include m.pc {
          width: 42%;
          max-width: 475px;
        }

        @include m.pcOver {
          width: 475px;
        }

        iframe {
          @include m.pc {
            height: 475px;
          }

          @include m.tab {
            height: 430px;
          }
        }

        &Inner {
          @extend #{$this};

          @include m.pc {
            width: 53%;
            max-width: 475px;
          }

          @include m.pcOver {
            width: 475px;
          }
        }
      }

      &--w530 {
        @extend #{$this};

        @include m.pc {
          width: 49%;
          max-width: 530px;
        }

        @include m.pcOver {
          width: 530px;
        }

        iframe {
          @include m.pc {
            height: 530px;
          }

          @include m.tab {
            height: 490px;
          }
        }
      }

      &--w570 {
        @extend #{$this};

        @include m.pc {
          width: 52%;
          max-width: 570px;
        }

        @include m.pcOver {
          width: 570px;
        }

        iframe {
          @include m.pc {
            height: 570px;
          }

          @include m.tab {
            height: 530px;
          }
        }
      }

      &Flex {
        display: flex;
      }

      &Half {
        width: 50%;
      }
    }

    &_box {
      position: relative;
      z-index: 1;

      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        order: 1;
      }

      & + .c-mediaCont_pic {
        margin: 0 0 0 3vw;

        @include m.pcOver {
          margin: 0 0 0 40px;
        }

        @include m.tab {
          margin: 0 0 0 1.5vw;
        }

        @include m.sp {
          margin: 0 0 2rem;
        }

        &--order {
          &Last {
            @include m.sp {
              margin: 2rem 0 0;
            }
          }
        }
      }
    }

    &_border {
      width: 100%;
      margin: 1.4vw 0 0;
      padding: 1.4vw 0;
      border-top: 1px solid rgba(v.$grayPale, 0.5);
      border-bottom: 1px solid rgba(v.$grayPale, 0.5);

      @include m.pcOver {
        margin: 20px 0 0;
        padding: 20px 0;
      }

      @include m.sp {
        margin: 1.5rem 0 0;
        padding: 1.5rem 0;
      }
    }

    &_head {
      width: 100%;
      margin: 0 0 1.8vw;

      @include m.pcOver {
        margin: 0 0 24px;
      }

      @include m.sp {
        margin: 0 0 2rem;
      }
    }

    &_heading {
      $this: &;
      padding: 0 0 2.2vw;

      @include m.pcOver {
        padding: 0 0 30px;
      }

      @include m.sp {
        padding: 0 0 1rem;
      }

      &--small {
        @extend #{$this};

        @include m.pc {
          padding: 0 0 1.4vw;
        }
      }
    }

    &_inner {
      width: 100%;
      position: relative;
      z-index: 1;

      & + & {
        margin: 2.4vw 0 0;

        @include m.pcOver {
          margin: 32px 0 0;
        }
      }
    }

    &_text {
      @include m.fz(16);
      line-height: 2;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1.4rem;
      }

      & + & {
        padding: 2.7vw 0 0;

        @include m.pcOver {
          padding: 36px 0 0;
        }

        @include m.sp {
          padding: 1rem 0 0;
        }
      }
    }

    &_btn {
      $this: &;
      width: 300px;
      margin: 2.2vw 0 0;

      @include m.pc {
        margin: 30px 0 0;
      }

      @include m.sp {
        width: 25rem;
        margin: 3rem auto 0;
      }

      &--large {
        @extend #{$this};
        width: 320px;

        @include m.sp {
          width: 100%;
          margin: 2.5rem auto 0;
        }
      }
    }

    &_ring {
      width: 48%;
      position: absolute;
      position: absolute;
      bottom: -125px;
      left: -19.5%;
      z-index: -1;
      transform: translateX(-50%);

      @include m.pc {
        max-width: 320px;
      }

      @include m.sp {
        display: none;
      }
    }

    &_num {
      @include m.pc {
        display: flex;
      }

      &Head {
        width: 3.5vw;
        margin: 0 10px 0 0;
        font-family: v.$oswald;
        @include m.fz(40);
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.08em;
        color: v.$bluePale;

        @include m.pcOver {
          width: 48px;
        }

        @include m.tab {
          margin: 0 12px 0 0;
        }

        @include m.sp {
          margin: 0 0 0.5rem;
          font-size: 2.5rem;
        }
      }

      &Body {
        flex: 1;
      }
    }

    &_data {
      & + & {
        margin: 2.4vw 0 0;

        @include m.pcOver {
          margin: 32px 0 0;
        }

        @include m.sp {
          margin: 1.5rem 0 0;
        }
      }
    }
  }
}
