@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-company {
  &Index {
    padding: 3vw 0 8.8vw;

    @include m.pcOver {
      padding: 40px 0 120px;
    }

    @include m.sp {
      padding: 2rem 0 6rem;
    }
  }

  &Ring {
    $this: &;
    position: absolute;
    transform: translate(-50%, -50%);

    @include m.sp {
      display: none;
    }

    &01 {
      @extend #{$this};
      width: 28%;
      top: 68%;
      left: -4%;
      z-index: -1;

      @include m.pc {
        max-width: 320px;
      }
    }

    &02 {
      @extend #{$this};
      width: 30%;
      top: 84.5%;
      left: 103.5%;
      z-index: -1;

      @include m.pc {
        max-width: 344px;
      }
    }
  }

  &Outline {
    &_plan {
      &Main {
        width: 80%;
        margin: 4.4vw auto;

        @include m.pcOver {
          margin: 60px auto;
        }

        @include m.pc {
          max-width: 720px;
        }

        @include m.tab {
          margin: 30px auto;
        }

        @include m.sp {
          width: 100%;
          margin: 3rem auto;
        }
      }

      &Mark {
        padding: 2.2vw;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include m.pcOver {
          padding: 30px;
        }

        @include m.sp {
          padding: 2rem;
          align-items: flex-start;
        }

        &Box {
          flex: 1;
        }

        &Pic {
          width: 12%;
          margin: 0 0 0 20px;

          @include m.pcOver {
            margin: 0 0 0 20px;
          }

          @include m.pc {
            max-width: 100px;
          }

          @include m.sp {
            width: 30%;
            margin: 5rem 0 0 2rem;
          }
        }
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      transform: translate(-50%, -50%);

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 26%;
        top: 20.5%;
        left: -8.6%;
        z-index: -1;

        @include m.pc {
          max-width: 300px;
        }
      }

      &02 {
        @extend #{$this};
        width: 30%;
        top: 82.5%;
        left: 99.8%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }

      &03 {
        @extend #{$this};
        width: 26%;
        top: 46.5%;
        left: 6.5%;
        z-index: -1;

        @include m.pc {
          max-width: 300px;
        }
      }

      &04 {
        @extend #{$this};
        width: 30%;
        top: 56%;
        left: 97%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }
    }
  }

  &Greetings {
    &_ring {
      $this: &;
      position: absolute;
      transform: translate(-50%, -50%);

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 26%;
        top: 40.5%;
        left: -8.6%;
        z-index: -1;

        @include m.pc {
          max-width: 300px;
        }
      }
    }
  }

  &Biz {
    &_cont {
      & + & {
        margin: 6vw 0 0;

        @include m.pcOver {
          margin: 80px 0 0;
        }

        @include m.sp {
          margin: 4rem 0 0;
        }
      }

      &Box {
        margin: 3vw 0 0;

        @include m.pcOver {
          margin: 40px 0 0;
        }

        @include m.sp {
          margin: 2rem 0 0;
        }
      }

      &Inner {
        margin: 2.2vw 0 0;

        @include m.pcOver {
          margin: 30px 0 0;
        }

        @include m.sp {
          margin: 1.5rem 0 0;
        }
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      transform: translate(-50%, -50%);

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 32%;
        top: 18%;
        left: -22.5%;
        z-index: -1;

        @include m.pc {
          max-width: 300px;
        }
      }

      &02 {
        @extend #{$this};
        width: 37%;
        top: 34.5%;
        left: 104%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }

      &03 {
        @extend #{$this};
        width: 37%;
        top: 79.3%;
        left: 120%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }
    }
  }

  &Office {
    &_service {
      width: 100%;
      margin: 2.2vw 0 0;
      display: flex;
      justify-content: flex-start;

      @include m.pcOver {
        margin: 30px 0 0;
      }

      @include m.sp {
        margin: 3rem 0 0;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &Box {
        width: 17%;

        @include m.pc {
          max-width: 150px;
        }

        @include m.sp {
          width: 45%;
        }

        & + & {
          @include m.pcOver {
            margin: 0 0 0 40px;
          }

          @include m.pc {
            margin: 0 0 0 3vw;
          }
        }

        &:nth-child(n + 3) {
          @include m.sp {
            margin: 3rem 0 0;
          }
        }
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      transform: translate(-50%, -50%);

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 26%;
        top: 28%;
        left: -8.7%;
        z-index: -1;

        @include m.pc {
          max-width: 300px;
        }
      }

      &02 {
        @extend #{$this};
        width: 32%;
        top: 50%;
        left: -22.8%;
        z-index: -1;

        @include m.pc {
          max-width: 300px;
        }
      }
    }
  }

  /* 拠点所在地 */
  &Ch {
    width: 100%;
    padding: 128px 0 52px; // ここは固定値
    position: relative;
    z-index: 1;

    @include m.sp {
      padding: 0;
    }

    &_area {
      $this: &;
      width: 100%;
      height: 100%;

      @include m.pc {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }

    &_cont {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;

      &Box {
        $this: &;
        padding: 5px 10px 10px;
        display: block;

        @include m.pc {
          width: 19%;
          max-width: 200px;
          position: absolute;
          z-index: 2;

          &:after {
            content: '';
            @include m.opacity();
          }

          &:hover {
            &:after {
              opacity: 0.3;
            }
          }
        }

        @include m.sp {
          padding-right: 1rem;
          padding-left: 1rem;
        }

        & + & {
          @include m.sp {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 1px solid v.$grayPale;
          }
        }

        &:first-child {
          @include m.sp {
            padding-top: 2rem;
            border-top: 1px solid v.$grayPale;
          }
        }

        &:last-child {
          @include m.sp {
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid v.$grayPale;
          }
        }

        &--kyusyu {
          @extend #{$this};
          background-color: v.$areaKyusyu;

          @include m.pc {
            top: 0;
            left: 0;
          }

          @include m.sp {
            background: none;
          }

          .p-companyCh_infoTitle {
            color: v.$white;

            @include m.sp {
              color: v.$areaKyusyu;
            }
          }

          .p-companyCh_infoName {
            color: v.$areaKyusyu;
          }
        }

        &--kansai {
          &01 {
            @extend #{$this};
            background-color: v.$areaKansai;

            @include m.pc {
              top: 0;
              left: 21.8%;
            }

            @include m.sp {
              background: none;
            }

            &:after {
              display: none;
            }

            .p-companyCh_infoTitle {
              color: v.$white;

              @include m.sp {
                color: v.$areaKansai;
              }
            }

            .p-companyCh_infoName {
              color: v.$areaKansai;
            }
          }

          &02 {
            @extend #{$this};
            background-color: v.$areaKansai;

            @include m.sp {
              background: none;
            }

            @include m.pc {
              top: 0;
              left: 43.6%;
            }

            .p-companyCh_infoTitle {
              color: v.$white;

              @include m.sp {
                color: v.$areaKansai;
              }
            }

            .p-companyCh_infoName {
              color: v.$areaKansai;
            }
          }
        }

        &--hokuriku {
          @extend #{$this};
          background-color: v.$areaHokuriku;

          @include m.sp {
            background: none;
          }

          @include m.pc {
            top: 0;
            left: 65.4%;
          }

          .p-companyCh_infoTitle {
            color: v.$white;

            @include m.sp {
              color: v.$areaHokuriku;
            }
          }

          .p-companyCh_infoName {
            color: v.$areaHokuriku;
          }
        }

        &--shikoku {
          @extend #{$this};
          background-color: v.$areaShikoku;

          @include m.sp {
            background: none;
          }

          @include m.pc {
            top: 72%;
            left: 38.2%;
          }

          .p-companyCh_infoTitle {
            color: v.$white;

            @include m.sp {
              color: v.$areaShikoku;
            }
          }

          .p-companyCh_infoName {
            color: v.$areaShikoku;
          }
        }

        &--chugoku {
          @extend #{$this};
          background-color: v.$areaChugoku;

          @include m.sp {
            background: none;
          }

          @include m.pc {
            top: 72%;
            left: 60%;
          }

          .p-companyCh_infoTitle {
            color: v.$white;

            @include m.sp {
              color: v.$areaChugoku;
            }
          }

          .p-companyCh_infoName {
            color: v.$areaChugoku;
          }
        }

        &--tokai {
          @extend #{$this};
          background-color: v.$areaTokai;

          @include m.sp {
            background: none;
          }

          @include m.pc {
            top: 72%;
            left: 81.8%;
          }

          .p-companyCh_infoTitle {
            color: v.$white;

            @include m.sp {
              color: v.$areaTokai;
            }
          }

          .p-companyCh_infoName {
            color: v.$areaTokai;
          }
        }
      }
    }

    &_info {
      @include m.sp {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      &Head {
        @include m.pc {
          padding: 0 0 4px;
          text-align: center;
        }

        @include m.sp {
          width: 40%;
        }
      }

      &Body {
        padding: 10px;

        @include m.pc {
          background-color: v.$white;
        }

        @include m.sp {
          flex: 1;
          padding: 0.3rem 0 0;
        }
      }

      &Inner {
        & + & {
          @include m.pc {
            margin-top: 10px;
            padding-top: 5px;
            border-top: 1px solid v.$grayPale;
          }

          @include m.sp {
            margin-top: 1rem;
          }
        }
      }

      &Unit {
        & + & {
          margin-top: 10px;
        }
      }

      &More {
        @include m.pc {
          display: none;
        }
      }
    }

    &_list {
      &Item {
        padding: 0 0 0 1em;
        text-indent: -1em;
        @include m.fz(14);
        line-height: 1.5;
      }
    }

    &_map {
      @include m.sp {
        display: none;
      }

      &Cont {
        width: 100%;
        position: relative;
        z-index: 1;
      }

      &Name {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        &--col {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;

          .p-companyCh_mapNameDot {
            margin: 0 0 6px;
          }
        }

        &Dot {
          $this: &;
          width: 0.73vw;
          height: 0.73vw;
          margin: 0.35vw 0.37vw 0 0;
          display: block;
          background-color: v.$white;
          border: 1px solid;
          border-radius: 50%;

          @include m.pcOver {
            width: 10px;
            height: 10px;
          }

          @include m.tab {
            width: 7px;
            height: 7px;
          }

          &--hokuriku {
            @extend #{$this};
            border-color: v.$areaHokuriku;
          }

          &--tokai {
            @extend #{$this};
            border-color: v.$areaTokai;
          }

          &--kansai {
            @extend #{$this};
            border-color: v.$areaKansai;
          }

          &--chugoku {
            @extend #{$this};
            border-color: v.$areaChugoku;
          }

          &--shikoku {
            @extend #{$this};
            border-color: v.$areaShikoku;
          }

          &--kyusyu {
            @extend #{$this};
            border-color: v.$areaKyusyu;
          }

          &--okinawa {
            @extend #{$this};
            border-color: v.$areaKyusyu;
          }
        }

        &Text {
          $this: &;
          width: 6.6vw;
          height: 1.5vw;
          display: flex;
          justify-content: center;
          align-items: center;
          @include m.fz(12);
          font-weight: bold;
          line-height: 1;
          background-color: v.$white;
          border: 1px solid;
          border-radius: 100px;

          @include m.pcOver {
            width: 90px;
            height: 20px;
          }

          @include m.tab {
            min-width: 80px;
            min-height: 16px;
          }

          & + & {
            margin-top: 4px;
          }

          &--hokuriku {
            @extend #{$this};
            border-color: v.$areaHokuriku;
          }

          &--tokai {
            @extend #{$this};
            border-color: v.$areaTokai;
          }

          &--kansai {
            @extend #{$this};
            width: 7.5vw;
            border-color: v.$areaKansai;

            @include m.pcOver {
              width: 100px;
            }
          }

          &--chugoku {
            @extend #{$this};
            border-color: v.$areaChugoku;
          }

          &--shikoku {
            @extend #{$this};
            border-color: v.$areaShikoku;
          }

          &--kyusyu {
            @extend #{$this};
            border-color: v.$areaKyusyu;
          }

          &--okinawa {
            @extend #{$this};
            border-color: v.$areaKyusyu;
          }
        }
      }

      &Pos {
        $this: &;
        position: absolute;
        z-index: 2;

        &--hokuriku {
          &01 {
            @extend #{$this};
            top: 25.8%;
            left: 75%;
          }
        }

        &--tokai {
          &01 {
            @extend #{$this};
            top: 41.3%;
            left: 76.45%;
          }

          &02 {
            @extend #{$this};
            top: 37.7%;
            left: 76.3%;
          }

          &03 {
            @extend #{$this};
            top: 46.7%;
            left: 72.35%;
          }

          &04 {
            @extend #{$this};
            top: 43.1%;
            left: 89.9%;
          }
        }

        &--kansai {
          &01 {
            @extend #{$this};
            top: 49%;
            left: 65.2%;
            transform: translate(-50%, -50%);
          }
        }

        &--chugoku {
          &01 {
            @extend #{$this};
            top: 49.7%;
            left: 37.7%;
          }

          &02 {
            @extend #{$this};
            top: 38.4%;
            left: 46%;
          }
        }

        &--shikoku {
          &01 {
            @extend #{$this};
            top: 56.7%;
            left: 40%;
          }

          &02 {
            @extend #{$this};
            top: 50.8%;
            left: 50.9%;
          }
        }

        &--kyusyu {
          &01 {
            @extend #{$this};
            top: 59.25%;
            left: 16.9%;
          }

          &02 {
            @extend #{$this};
            top: 67.9%;
            left: 20.9%;
          }

          &03 {
            @extend #{$this};
            top: 80.8%;
            left: 19.3%;
          }
        }

        &--okinawa {
          &01 {
            @extend #{$this};
            top: 95.4%;
            left: 2.9%;
          }
        }
      }
    }

    &_note {
      font-size: 12px;

      @include m.tab {
        font-size: 11px;
      }

      @include m.sp {
        font-size: 1.2rem;
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      transform: translate(-50%, -50%);

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 26%;
        top: 22%;
        left: -8.3%;
        z-index: -1;

        @include m.pc {
          max-width: 300px;
        }
      }
    }
  }
  /* end 拠点所在地 */

  &Csr {
    &_intro {
      margin: 0 0 4.4vw;

      @include m.pcOver {
        margin: 0 0 60px;
      }

      @include m.sp {
        margin: 0 0 3rem;
      }

      &Heading {
        margin: 0 0 2.2vw;

        @include m.pcOver {
          margin: 0 0 30px;
        }

        @include m.sp {
          margin: 0 0 2rem;
        }
      }
    }

    &_pillar {
      margin: 0 0 4.4vw;

      @include m.pcOver {
        margin: 0 0 60px;
      }

      @include m.sp {
        margin: 0 0 3rem;
      }

      &Heading {
        margin: 0 0 2.2vw;

        @include m.pcOver {
          margin: 0 0 30px;
        }

        @include m.sp {
          margin: 0 0 2rem;
        }
      }

      &Pic {
        width: 67%;
        margin: 0 auto 1vw;

        @include m.pcOver {
          margin: 0 auto 10px;
        }

        @include m.pc {
          max-width: 610px;
        }

        @include m.sp {
          width: 95%;
          margin: 0 auto 2rem;
        }

        img {
          @include m.sp {
            transform-origin: center;
            transform: scale(1.1);
          }
        }
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      transform: translate(-50%, -50%);

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 32%;
        top: 21%;
        left: -22.7%;
        z-index: -1;

        @include m.pc {
          max-width: 300px;
        }
      }

      &02 {
        @extend #{$this};
        width: 37%;
        top: 76%;
        left: 120%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }
    }
  }

  &Mi {
    &_hero {
      width: 100%;
      margin: -10vw 0 0;
      position: relative;
      z-index: 1;

      @include m.sp {
        margin: -7.5rem 0 0;
      }

      &Cont {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        mix-blend-mode: screen;
      }

      &Bg {
        position: relative;
        z-index: 1;

        @include m.sp {
          height: 45rem;

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &_vision {
      &Cont {
        padding: 8.7vw 0 17vw;
        position: relative;
        z-index: 1;

        @include m.pcOver {
          padding: 118px 0 234px;
        }

        @include m.sp {
          padding: 1rem 0 6rem;
        }
      }

      &Pic {
        @include m.pc {
          width: 40%;
          max-width: 440px;
          margin: 0 auto;
        }

        @include m.sp {
          margin: 0 0 4rem;
        }
      }

      &Copy {
        @include m.fz(14);
        font-weight: bold;
        line-height: 1;
        color: v.$blue;

        @include m.sp {
          font-size: 1.5rem;
        }

        &:after {
          content: '';
          width: 20px;
          height: 2px;
          margin: 0.8vw 0 0.4vw;
          display: block;
          background-color: v.$blue;

          @include m.pcOver {
            margin: 10px 0 5px;
          }

          @include m.sp {
            margin: 1.5rem 0 1rem;
          }
        }
      }

      &Lead {
        padding: 0 0 5px;
        @include m.fz(18);
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.04em;

        @include m.sp {
          padding: 0 0 0.8rem;
          font-size: 1.8rem;
        }
      }

      &List {
        &Item {
          @include m.fz(14);
          line-height: 2;
          letter-spacing: 0.04em;

          @include m.sp {
            font-size: 1.3rem;
          }
        }
      }

      &Box {
        $this: &;
        padding: 0.8vw 1.1vw;
        background-color: v.$bluePale02;

        @include m.pcOver {
          padding: 10px 15px;
        }

        @include m.pc {
          position: absolute;
          z-index: 2;
        }

        @include m.sp {
          padding: 1.8rem 1.2rem;

          & + & {
            margin: 4rem 0 0;
          }
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          transform: translate(-50%, -50%);

          @include m.sp {
            display: none;
          }
        }

        &--num {
          &01 {
            @extend #{$this};

            @include m.pc {
              max-width: 415px;
              width: 38%;
              top: 0;
              left: 0;
            }

            &:after {
              width: 3.5vw;
              height: 3.3vw;
              background: url('../img/company/mission/obj_balloon_type01.svg') no-repeat center;
              background-size: contain;
              top: 100%;
              left: 85%;

              @include m.pcOver {
                width: 48px;
                height: 45px;
              }
            }
          }

          &02 {
            @extend #{$this};

            @include m.pc {
              max-width: 315px;
              width: 29%;
              top: 37%;
              left: 0;
            }

            &:after {
              width: 2.2vw;
              height: 3.3vw;
              background: url('../img/company/mission/obj_balloon_type02.svg') no-repeat center;
              background-size: contain;
              top: 28%;
              left: 104%;

              @include m.pcOver {
                width: 30px;
                height: 46px;
              }
            }
          }

          &03 {
            @extend #{$this};

            @include m.pc {
              max-width: 415px;
              width: 38%;
              top: 0;
              right: 0;
            }

            &:after {
              width: 3.5vw;
              height: 3.3vw;
              background: url('../img/company/mission/obj_balloon_type03.svg') no-repeat center;
              background-size: contain;
              top: 104%;
              left: 11%;

              @include m.pcOver {
                width: 48px;
                height: 45px;
              }
            }
          }

          &04 {
            @extend #{$this};

            @include m.pc {
              max-width: 315px;
              width: 29%;
              top: 37%;
              right: 0;
            }

            &:after {
              width: 3.3vw;
              height: 2.2vw;
              background: url('../img/company/mission/obj_balloon_type04.svg') no-repeat center;
              background-size: contain;
              top: 50%;
              left: -3%;

              @include m.pcOver {
                width: 46px;
                height: 30px;
              }
            }
          }

          &05 {
            @extend #{$this};

            @include m.pc {
              max-width: 380px;
              width: 35%;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }

            &:after {
              width: 2.2vw;
              height: 3.3vw;
              background: url('../img/company/mission/obj_balloon_type05.svg') no-repeat center;
              background-size: contain;
              top: -7%;
              left: 50%;

              @include m.pcOver {
                width: 30px;
                height: 46px;
              }
            }
          }
        }
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      transform: translate(-50%, -50%);

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 32%;
        top: -6.7%;
        left: -7%;
        z-index: -1;

        @include m.pc {
          max-width: 371px;
        }
      }

      &02 {
        @extend #{$this};
        width: 30%;
        top: 103%;
        left: 97.4%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }
    }
  }

  &Life {
    &_table {
      margin: 0 0 7.3vw;

      @include m.pcOver {
        margin: 0 0 100px;
      }

      @include m.sp {
        margin: 0 0 6rem;
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      transform: translate(-50%, -50%);

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 26%;
        top: -50%;
        left: -8.4%;
        z-index: -1;

        @include m.pc {
          max-width: 300px;
        }
      }

      &02 {
        @extend #{$this};
        width: 32%;
        top: -22%;
        left: -1.5%;
        z-index: -1;

        @include m.pc {
          max-width: 370px;
        }
      }

      &03 {
        @extend #{$this};
        width: 30%;
        top: -0.4%;
        left: 106.5%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }
    }
  }
}
