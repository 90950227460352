@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-wrap {
  $this: &;
  width: 100%;
  margin: 0 auto;

  @include m.pc {
    max-width: 1180px;
    padding: 0 40px;
  }

  @include m.tab {
    max-width: 1140px;
    padding: 0 20px;
  }

  @include m.sp {
    width: 90%;
  }

  &Rela {
    @extend #{$this};
    position: relative;
    z-index: 1;
  }

  &Cont {
    width: 100%;
    margin: 0 auto;

    @include m.pc {
      max-width: 1366px;
    }

    @include m.sp {
      width: 100%;
    }
  }

  &Inner {
    $this: &;
    width: 100%;
    margin: 0 auto;

    @include m.pc {
      max-width: 950px;
      padding: 0 20px;
    }

    @include m.sp {
      width: 90%;
    }

    &--full {
      @extend #{$this};

      @include m.sp {
        width: 100%;
      }
    }

    &--small {
      width: 100%;
      margin: 0 auto;

      @include m.pc {
        max-width: 750px;
        padding: 0 20px;
      }

      @include m.sp {
        width: 90%;
      }
    }

    &Rela {
      @extend #{$this};
      position: relative;
      z-index: 1;
    }
  }

  &Post {
    width: 100%;
    margin: 0 auto;

    @include m.pc {
      max-width: 950px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
    }

    @include m.sp {
      width: 90%;
    }

    &_main {
      @include m.pc {
        width: 74%;
        max-width: 665px;
      }
    }

    &_sub {
      width: 100%;

      @include m.pc {
        width: 190px;
        margin-left: 4vw;
      }

      @include m.pcOver {
        margin-left: 55px;
      }

      @include m.sp {
        margin-top: 8rem;
      }
    }
  }
}
