@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-contact {
  width: 100%;
  padding: 70px 0 90px;
  position: relative;
  z-index: 1;

  @include m.tab {
    padding: 48px 0;
  }

  @include m.sp {
    padding: 5.2rem 0 6.5rem;
  }

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:before {
    background-color: rgba(v.$black, 0.5);
    z-index: -1;
    mix-blend-mode: multiply;
  }

  &:after {
    background: url('../img/common/common_bg_contact.jpg') no-repeat center;
    background-size: cover;
    z-index: -2;

    @include m.sp {
      background: url('../img/common/common_bg_contact_sp.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &_textarea,
  &_input,
  &_number {
    &::placeholder {
      @include m.fz(16);
      line-height: 1.75;
      letter-spacing: 0.04em;
      color: v.$black;
      opacity: 0.5;

      @include m.sp {
        font-size: 1.4rem;
      }
    }
  }

  &_textarea,
  &_input,
  &_selectItem,
  &_number {
    width: 100%;
    height: 50px;
    padding: 1.1vw 1.5vw;
    display: block;
    @include m.fz(16);
    line-height: 1.75;
    letter-spacing: 0.04em;
    color: v.$black;
    resize: none;

    @include m.pcOver {
      padding: 15px 20px;
    }

    @include m.tab {
      height: 40px;
    }

    @include m.sp {
      padding: 1rem;
      font-size: 1.4rem;
    }
  }

  &_number {
    padding: 1.1vw 0 1.1vw 0.5vw;

    @include m.pcOver {
      padding: 15px 0 15px 5px;
    }

    @include m.sp {
      padding: 1rem 0 1rem 0.5rem;
    }
  }

  &_textarea {
    height: 150px;
    background-color: v.$grayPale03;
    word-break: break-all;
  }

  &_input,
  &_number {
    background-color: v.$grayPale03;
  }

  &_radio {
    width: 100%;
    display: flex;

    @include m.pc {
      height: 50px;
      align-items: center;
    }

    @include m.tab {
      height: 40px;
    }

    @include m.sp {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &List {
      @include m.sp {
        width: 50%;
      }

      & + & {
        @include m.pc {
          margin-left: 3.2vw;
        }

        @include m.pcOver {
          margin-left: 40px;
        }
      }

      &:nth-child(n + 3) {
        margin-top: 1.5rem;
      }
    }

    &Label {
      display: flex;
      align-items: center;

      &Input {
        display: none;

        &:checked + span {
          &:after {
            opacity: 1;
          }
        }
      }

      &Ico {
        width: 1.7vw;
        height: 1.7vw;
        margin: 2px 10px 0 0;
        background-color: v.$white;
        border: 1px solid v.$gray;
        border-radius: 50%;
        position: relative;
        z-index: 1;

        @include m.pcOver {
          width: 20px;
          height: 20px;
        }

        @include m.sp {
          width: 2rem;
          height: 2rem;
        }

        &:after {
          content: '';
          width: 0.8vw;
          height: 0.8vw;
          display: block;
          background-color: v.$blue;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          transform: translate(-50%, -50%);
          opacity: 0;

          @include m.pcOver {
            width: 10px;
            height: 10px;
          }

          @include m.sp {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }

  &_select {
    $this: &;
    width: 267px;
    appearance: none;
    position: relative;
    z-index: 1;

    @include m.pc {
      cursor: pointer;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      width: 100%;
      height: 100%;
      background-color: v.$grayPale03;
      top: 0;
      left: 0;
      z-index: -2;
    }

    &:after {
      width: 0.8vw;
      height: 0.8vw;
      border-right: 1px solid v.$black;
      border-bottom: 1px solid v.$black;
      top: calc(50% - 2px);
      right: 16px;
      z-index: -1;
      transform: translateY(-50%) rotate(45deg);

      @include m.pcOver {
        width: 10px;
        height: 10px;
      }

      @include m.sp {
        width: 0.8rem;
        height: 0.8rem;
      }
    }

    &--large {
      @extend #{$this};
      width: 320px;

      @include m.tab {
        width: 240px;
      }

      @include m.sp {
        width: 100%;
      }
    }

    &Item {
      padding: 1.1vw 2.5vw 1.1vw 1.5vw;
      display: flex;
      align-items: center;
      line-height: 1;
      white-space: nowrap;

      @include m.pcOver {
        padding: 15px 35px 15px 20px;
      }

      @include m.sp {
        padding: 1rem 1.5rem 1rem 1rem;
      }
    }
  }

  &_submit {
    @include m.pc {
      width: 100%;
      max-width: 560px;
      cursor: pointer;
    }

    @include m.sp {
      padding: 1.4rem 0;
    }

    &Text {
      @include m.sp {
        font-size: 1.4rem;
      }
    }
  }

  &_btn {
    $this: &;
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
    @include m.fz(20);
    line-height: 2.2;
    letter-spacing: 0.04em;

    @include m.pc {
      transition: color v.$anime, background-color v.$anime;
    }

    @include m.sp {
      font-size: 1.6rem;
    }

    &--search {
      @extend #{$this};
      color: v.$white;
      background-color: v.$blackPale;
      border: 2px solid v.$blackPale;
      border-radius: 100px;

      @include m.pc {
        cursor: pointer;

        &:hover {
          color: v.$blackPale;
          background-color: v.$white;
        }
      }
    }
  }

  &Cont {
    width: 100%;
    text-align: center;

    &_text {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: 0.04em;
      color: v.$white;

      @include m.tab {
        font-size: 16px;
      }

      @include m.sp {
        font-size: 1.6rem;
        line-height: 2;
      }
    }

    &_btn {
      width: 500px;
      margin: 24px auto 0;

      @include m.tab {
        width: 420px;
      }

      @include m.sp {
        width: 100%;
        margin: 2rem auto 0;
      }
    }
  }

  &Notion {
    width: 100%;
    border: 3px solid v.$bluePale02;

    &_box {
      padding: 1.5vw;

      @include m.pcOver {
        padding: 20px;
      }

      @include m.sp {
        padding: 1.5rem;
      }
    }

    &_text {
      @include m.fz(18);
      line-height: 1.7222222222222223;

      @include m.tab {
        @include m.fz(16);
      }

      @include m.sp {
        font-size: 1.4rem;
      }

      &--s {
        @include m.fz(16);
        line-height: 1.75;

        @include m.sp {
          font-size: 1.3rem;
        }
      }
    }
  }

  &Check {
    $this: &;
    width: 100%;
    height: 50px;
    gap: 0.5em;
    display: flex;
    align-items: center;

    @include m.pc {
      cursor: pointer;
    }

    @include m.tab {
      height: 40px;
    }

    &--inner {
      @extend #{$this};
      height: auto;
      margin-top: 10px;

      @include m.sp {
        margin-top: 0;
      }
    }

    &_input {
      display: none;

      &:checked + span {
        background-color: v.$blue;

        &:after {
          opacity: 1;
        }
      }
    }

    &_ico {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: v.$white;
      border: 1px solid v.$blue;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        width: 12px;
        height: 6px;
        display: block;
        border-bottom: 2px solid v.$white;
        border-left: 2px solid v.$white;
        position: absolute;
        top: calc(50% - 1px);
        left: 50%;
        z-index: 2;
        translate: -50% -50%;
        rotate: -45deg;
        opacity: 0;
      }
    }

    &_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      &Item {
        @include m.pc {
          width: 25%;
          padding: 0 1em 0 0;
        }

        @include m.sp {
          margin-right: 1.5rem;
        }

        &:nth-child(n + 5) {
          @include m.pc {
            margin-top: 1em;
          }
        }

        &:nth-child(n + 4) {
          @include m.sp {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }

  &Judge {
    width: 200px;
    margin: 32px auto 0;
    padding: 0 0 12px;
    border: 1px solid v.$black;
    background-color: rgba(v.$blue, 0.2);
    opacity: 1;

    @include m.sp {
      width: 50%;
      margin: 2.4rem auto 0;
      padding: 1.2rem 0;
    }

    input {
      &:disabled {
        pointer-events: none;
      }
    }

    &.is-disabled {
      background-color: v.$grayPale02;
      opacity: 0.7;
    }
  }
}
