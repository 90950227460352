@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-header {
  width: 100%;
  height: 100px;
  background-color: v.$white;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 100;
  transition: box-shadow v.$anime;

  @include m.tab {
    height: 80px;
  }

  @include m.sp {
    height: 7dvh;
  }

  &.is-fixed {
    box-shadow: 0 2px 5px rgba(v.$black, 0.26);
  }

  &Hamburger {
    @include m.pc {
      display: none;
    }

    @include m.sp {
      width: 3rem;
      height: 2rem;
      position: relative;
      z-index: 1;
    }

    &.is-active {
      .l-headerHamburger {
        &_line {
          &--top {
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
          &--mid {
            opacity: 0;
          }
          &--btm {
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }

    &_line {
      $this: &;
      width: 100%;
      height: 1px;
      background: v.$gradGreen;
      position: absolute;
      left: 50%;
      z-index: 2;
      transition: transform v.$anime, opacity v.$anime;

      &--top {
        @extend #{$this};
        top: 0;
        transform: translateX(-50%);
        transition: transform v.$anime;
      }

      &--mid {
        @extend #{$this};
        top: 50%;
        transform: translateX(-50%);
        transition: opacity v.$anime;
      }

      &--btm {
        @extend #{$this};
        top: 100%;
        transform: translateX(-50%);
        transition: transform v.$anime;
      }
    }
  }

  &Cont {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include m.pc {
      max-width: 1100px;
      padding: 0 0 0 1.5%;
    }

    @include m.sp {
      padding: 0 1.5rem;
    }

    &_logo {
      width: 100%;
      display: block;

      @include m.pc {
        max-width: 213px;
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.7;
        }
      }

      @include m.tab {
        max-width: 180px;
      }

      @include m.sp {
        width: 15.6rem;
        margin: 0 0 -2px;
      }

      &--nolink {
        pointer-events: none;
      }
    }

    &_nav {
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: flex-end;

      @include m.sp {
        width: 100vw;
        height: 93dvh;
        background-color: rgba(v.$black, 0.88);
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 2;
        opacity: 0;
        pointer-events: none;
      }

      &.is-open {
        @include m.sp {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  &Hamburger {
    @include m.pc {
      display: none;
    }
  }

  &Nav {
    width: 100%;

    @include m.sp {
      height: 100%;
      display: flex;
      flex-flow: column;
      overflow-y: scroll;
    }

    &_cont {
      &:first-child {
        @include m.pc {
          padding: 0 20px 0 0;
        }

        @include m.sp {
          order: 1;
        }
      }

      &:last-child {
        @include m.pc {
          margin-top: 3%;
        }

        @include m.sp {
          order: 0;
        }
      }
    }

    &_menu {
      position: relative;
      z-index: 1;

      @include m.pc {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &List {
        @include m.pc {
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include m.tab {
          height: 40px;
        }

        @include m.sp {
          width: 100%;
          border-bottom: 1px solid v.$white;
        }

        & + & {
          @include m.pc {
            // border-left: 1px solid v.$gray;
          }
        }

        &:first-child {
          .l-headerNav_menuListItem {
            @include m.pc {
              &:before {
                display: none;
              }
            }
          }
        }

        &Item {
          $this: &;
          height: 100%;
          padding: 8px 20px;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.035em;
          color: v.$black;
          position: relative;
          z-index: 1;

          @include m.pc {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            white-space: nowrap;
          }

          &:before {
            @include m.pc {
              content: '';
              width: 1px;
              height: 30px;
              background-color: v.$gray;
              position: absolute;
              top: 50%;
              left: 0;
              z-index: 2;
              transform: translateY(-50%);
            }

            @include m.tab {
              height: 20px;
            }
          }

          @include m.tab {
            padding: 5px 16px;
            font-size: 12px;
          }

          @include m.sp {
            width: 100%;
            padding: 2.4rem 2rem;
            display: block;
            font-size: 1.6rem;
            color: v.$white;
            background: v.$gradBlue;
            position: relative;
            z-index: 1;

            &:after {
              content: '';
              width: 0.8rem;
              height: 0.8rem;
              border-right: 1px solid v.$white;
              border-bottom: 1px solid v.$white;
              position: absolute;
              top: calc(50% - 2px);
              right: 2rem;
              z-index: 2;
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }

          &.is-active {
            @include m.pc {
              color: v.$white;

              &:after {
                content: '';
                width: calc(100% + 1px);
                height: 100%;
                background-color: v.$black;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
              }
            }

            @include m.sp {
              &:after {
                top: 50%;
                transform: translate(-50%, -50%) rotate(-135deg);
              }
            }
          }

          &--link {
            @extend #{$this};

            @include m.pc {
              &:hover {
                color: v.$white;

                &:after {
                  content: '';
                  width: calc(100% + 1px);
                  height: 100%;
                  background-color: v.$black;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: -1;
                }
              }
            }

            @include m.sp {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &Other {
    width: 100%;

    @include m.sp {
      padding-bottom: 3rem;
    }

    &_cont {
      @include m.pc {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &List {
        @include m.pc {
          margin: 0 0 0 20px;
        }

        @include m.sp {
          margin-top: 3rem;
        }

        &Item {
          font-size: 14px;
          letter-spacing: 0.08em;
          color: v.$black;
          position: relative;
          z-index: 1;

          @include m.pc {
            white-space: nowrap;
          }

          @include m.tab {
            font-size: 11px;
          }

          @include m.sp {
            padding: 0 2rem;
            display: block;
            font-size: 1.6rem;
            color: v.$white;
          }
        }

        &Ico {
          &--external {
            width: 8px;

            @include m.pc {
              display: block;
              position: absolute;
              top: -5px;
              right: -5px;
              z-index: 2;
            }

            @include m.sp {
              width: 1.6rem;
              margin: 0 0 -0.2rem 0.5rem;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  &Mega {
    width: 100%;
    background-color: v.$black;
    pointer-events: none;
    opacity: 0;

    @include m.pc {
      padding: 20px 40px 30px;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
    }

    @include m.tab {
      padding: 8px 16px 20px;
    }

    @include m.sp {
      max-height: 0;

      & * {
        max-height: 0;
        padding: 0;
        opacity: 0;
        transition: max-height v.$anime, padding v.$anime, opacity v.$anime;
      }
    }

    &.is-view {
      pointer-events: auto;
      opacity: 1;

      @include m.sp {
        max-height: 100%;

        & * {
          max-height: 100%;
          opacity: 1;
        }

        .l-headerMega {
          &_heading {
            padding: 2rem;
          }

          &_menu {
            &Item {
              padding: 2rem;
            }
          }
        }
      }
    }

    &_heading {
      width: 100%;
      padding: 0 0 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.75;
      letter-spacing: 0.04em;
      color: v.$white;
      border-bottom: 1px solid v.$white;

      @include m.tab {
        padding: 0 0 8px;
        font-size: 12px;
      }

      @include m.sp {
        padding: 0 2rem;
        display: block;
        font-size: 1.4rem;
        line-height: 2;
        background-color: v.$black;
      }
    }

    &_menu {
      @include m.pc {
        display: flex;
        flex-wrap: wrap;
      }

      &List {
        @include m.pc {
          width: 50%;
          margin: 15px 0 0;
        }

        @include m.tab {
          margin: 10px 0 0;
        }

        @include m.sp {
          border-bottom: 1px solid v.$white;
        }

        &:last-child {
          @include m.sp {
            border-bottom: none;
          }
        }
      }

      &Item {
        font-size: 14px;
        font-weight: 600;
        line-height: 2;
        letter-spacing: 0.04em;
        color: v.$white;
        white-space: nowrap;

        @include m.tab {
          font-size: 12px;
        }

        @include m.sp {
          padding: 0 2rem;
          display: block;
          font-size: 1.4rem;
        }

        &Ico {
          &--external {
            width: 12px;
            margin: 0 0 0 6px;
            display: inline-block;

            @include m.sp {
              width: 1.3rem;
              margin: 0 0 0 0.8rem;
            }
          }
        }
      }
    }
  }
}
