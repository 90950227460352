@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-service {
  &Ring {
    $this: &;
    position: absolute;
    transform: translate(-50%, -50%);

    @include m.sp {
      display: none;
    }

    &01 {
      @extend #{$this};
      width: 28%;
      top: 103%;
      left: -4%;
      z-index: -1;

      @include m.pc {
        max-width: 320px;
      }
    }

    &02 {
      @extend #{$this};
      width: 30%;
      top: 123.5%;
      left: 103.5%;
      z-index: -1;

      @include m.pc {
        max-width: 344px;
      }
    }
  }
}
