@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-ta {
  &c {
    text-align: center !important;

    &--pc {
      @include m.pc {
        text-align: center !important;
      }
    }

    &--sp {
      @include m.sp {
        text-align: center !important;
      }
    }
  }

  &l {
    text-align: left !important;

    &--pc {
      @include m.pc {
        text-align: left !important;
      }
    }

    &--sp {
      @include m.sp {
        text-align: left !important;
      }
    }
  }

  &r {
    text-align: right !important;

    &--pc {
      @include m.pc {
        text-align: right !important;
      }
    }

    &--sp {
      @include m.sp {
        text-align: right !important;
      }
    }
  }
}
