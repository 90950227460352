@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-heading {
  &Type {
    $this: &;
    @include m.fz(25);
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.04em;

    @include m.tab {
      @include m.fz(23);
    }

    @include m.sp {
      font-size: 1.6rem;
    }

    &01 {
      @extend #{$this};
    }

    &02 {
      @extend #{$this};
      @include m.fz(20);
      line-height: 1.95;

      @include m.tab {
        @include m.fz(18);
      }

      @include m.sp {
        font-size: 1.6rem;
      }
    }

    &03 {
      @include m.fz(30);
      font-weight: bold;
      line-height: 1.4666666666666666;
      letter-spacing: 0.04em;

      @include m.tab {
        @include m.fz(28);
      }

      @include m.sp {
        font-size: 2rem;
      }
    }

    &04 {
      $this: &;
      margin: 0 0 0.8vw;
      padding: 0 0 0 0.7vw;
      @include m.fz(18);
      font-weight: bold;
      line-height: 1.5555555555555556;
      letter-spacing: 0.04em;
      border-left: 4px solid v.$blue;

      @include m.pcOver {
        margin: 0 0 13px;
        padding: 0 0 0 10px;
      }

      @include m.sp {
        margin: 0 0 0.5rem;
        padding: 0 0 0 1rem;
        font-size: 1.4rem;
        border-width: 2px;
      }

      &--noBorder {
        @extend #{$this};
        margin: 0;
        padding: 0;
        border: none;

        @include m.pcOver {
          margin: 0;
          padding: 0;
        }

        @include m.sp {
          margin: 0;
          padding: 0;
        }
      }
    }

    &05 {
      $this: &;
      @include m.fz(25);
      font-weight: bold;
      line-height: 2.08;
      letter-spacing: 0.04em;

      @include m.tab {
        @include m.fz(23);
      }

      @include m.sp {
        font-size: 2.1rem;
      }
      &--type02 {
        @extend #{$this};
        line-height: 1.52;

        @include m.sp {
          font-size: 1.9rem !important;
        }
      }
    }

    &06 {
      @include m.fz(18);
      font-weight: bold;
      line-height: 1.9444444444444444;
      letter-spacing: 0.04em;

      @include m.tab {
        @include m.fz(16);
      }

      @include m.sp {
        font-size: 1.6rem;
      }
    }
  }
}
