@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-notion {
  width: 100%;
  margin: 0 0 4.5vw;
  padding: 1.5vw;
  background-color: v.$white;
  border: 1px solid v.$bluePale02;

  @include m.pcOver {
    margin: 0 0 60px;
    padding: 20px;
  }

  @include m.sp {
    margin: 0 0 4rem;
    padding: 1.5rem;
  }

  &_text {
    & + & {
      margin-top: 0.8em;

      @include m.sp {
        margin-top: 0.8rem;
      }
    }

    & + .c-notionInner {
      padding-top: 1.6rem;
    }
  }

  &Inner {
    &--img {
      display: flex;
      gap: 3%;

      @include m.sp {
        flex-flow: column;
        gap: 2.4rem;
      }
    }

    &_box {
      @include m.pc {
        width: 50%;
      }
    }
  }
}
