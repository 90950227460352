@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-case {
  &Intro {
    width: 100%;
    margin: 0 0 4.4vw;

    @include m.pcOver {
      margin: 0 0 60px;
    }

    @include m.sp {
      margin: 0 0 2rem;
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include m.sp {
        flex-flow: column;
        justify-content: flex-start;
      }

      &Box {
        @include m.pc {
          flex: 1;
        }

        @include m.sp {
          order: 0;
        }
      }

      &Slider {
        margin: 0 0 0 4vw;
        -webkit-animation: viewAnimation 0.3s 0.3s;
        animation: viewAnimation 0.3s 0.3s;

        @include m.pc {
          width: 49%;
          max-width: 530px;
        }

        @include m.pcOver {
          margin: 0 0 0 76px;
        }

        @include m.sp {
          margin: 0 0 2rem;
          order: 1;
        }

        &Main {
          margin: 0 0 20px;

          @include m.pc {
            height: 360px;
          }

          @include m.sp {
            margin: 0 0 2rem;
          }

          &.swiper-fade {
            .swiper-slide {
              opacity: 0 !important;

              &-active {
                opacity: 1 !important;
              }
            }
          }

          .swiper-slide {
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        &Thumb {
          @include m.sp {
            margin: 0 0 2rem;
          }
          .swiper-slide {
            @include m.pc {
              cursor: pointer;

              &:hover {
                &:after {
                  opacity: 1;
                }
              }
            }

            &:after {
              content: '';
              width: 100%;
              height: 100%;
              border: 3px solid v.$blue;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              opacity: 0;
              transition: opacity v.$anime;
            }

            &-active {
              &:after {
                opacity: 1;
              }
            }
          }

          &Item {
            width: 20% !important;
            height: auto;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    &_head {
      margin: 0 0 2.6vw;
      padding: 7px 0 0;

      @include m.pcOver {
        margin: 0 0 40px;
      }
    }

    &_category {
      padding: 0 0 1.1vw;
      @include m.fz(18);
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.04em;

      @include m.pcOver {
        padding: 0 0 10px;
      }

      @include m.sp {
        padding: 0 0 1rem;
        font-size: 1.4rem;
      }
    }

    &_heading {
      padding: 0 0 0.9vw;
      @include m.fz(30);
      font-weight: bold;
      line-height: 1.3;
      letter-spacing: 0.04em;

      @include m.pcOver {
        padding: 0 0 10px;
      }

      @include m.sp {
        padding: 0 0 1rem;
        font-size: 1.8rem;
      }
    }

    &_lead {
      padding: 0 0 1vw;
      @include m.fz(20);
      font-weight: bold;
      line-height: 2;
      letter-spacing: 0.04em;

      @include m.pcOver {
        padding: 0 0 20px;
      }

      @include m.sp {
        padding: 0 0 1rem;
        font-size: 1.6rem;
      }
    }

    &_info {
      $this: &;

      @include m.pc {
        max-width: 435px;
      }

      &--pc {
        @include m.sp {
          display: none;
        }
      }

      &--sp {
        @include m.pc {
          display: none;
        }
      }
    }
  }

  &Feature {
    width: 100%;
    margin: 0 0 5.9vw;
    padding: 3vw 2.2vw 2.6vw;

    @include m.pcOver {
      padding: 40px 30px;
    }

    @include m.sp {
      margin: 0 0 4rem;
      padding: 1.5rem;
    }

    &_cont {
      & + & {
        margin: 1.8vw 0 0;
        padding: 2.2vw 0 0;
        border-top: 1px solid v.$grayPale;

        @include m.pcOver {
          margin: 24px 0 0;
          padding: 30px 0 0;
        }

        @include m.sp {
          margin: 2rem 0 0;
          padding: 2.5rem 0 0;
        }
      }
    }

    &_text {
      @include m.fz(16);
      line-height: 2;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1.2rem;
      }
    }
  }

  &Lapse {
    width: 100%;
    margin: 0 0 7.3vw;

    @include m.pcOver {
      margin: 0 0 100px;
    }

    @include m.sp {
      margin: 0 0 8rem;
    }

    &_cont {
      @include m.pc {
        display: flex;
        justify-content: space-between;
      }

      & + & {
        margin: 4.6vw 0 0;

        @include m.pcOver {
          margin: 60px 0 0;
        }

        @include m.sp {
          margin: 4rem 0 0;
        }
      }

      &Box {
        position: relative;
        z-index: 1;

        @include m.pc {
          width: 48%;
          max-width: 430px;
        }

        & + & {
          @include m.sp {
            margin: 6rem 0 0;

            &:after {
              content: '';
              width: 1.8rem;
              height: 3rem;
              display: block;
              background: url('../img/common/obj_arrow_green.svg') no-repeat center;
              background-size: contain;
              position: absolute;
              bottom: calc(100% + 1.5rem);
              left: 50%;
              transform: translateX(-50%) rotate(90deg);
              z-index: 2;
            }
          }
        }
      }

      &Pic {
        $this: &;
        margin: 0 0 1.1vw;
        position: relative;
        z-index: 1;

        @include m.pcOver {
          margin: 0 0 16px;
        }

        @include m.sp {
          margin: 0 0 1rem;
        }

        &--before {
          @extend #{$this};
          &:after {
            content: '';
            width: 1.3vw;
            height: 2.2vw;
            display: block;
            background: url('../img/common/obj_arrow_green.svg') no-repeat center;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: calc(100% + 1.2vw);
            transform: translateY(-50%);
            z-index: 2;

            @include m.pcOver {
              width: 18px;
              height: 30px;
              left: calc(100% + 16px);
            }

            @include m.sp {
              display: none;
            }
          }
        }
      }

      &Text {
        @include m.fz(14);
        line-height: 2;
        letter-spacing: 0.04em;

        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }

    &_ring {
      width: 34%;
      position: absolute;
      top: 25.1%;
      left: -17.9%;
      z-index: -1;
      transform: translate(-50%, -50%);
    }
  }

  &Btn {
    width: 300px;
    margin: 5.9vw auto 0;

    @include m.pcOver {
      margin: 80px auto 0;
    }

    @include m.sp {
      width: 25rem;
      margin: 6rem auto 0;
    }
  }
}
