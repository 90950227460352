@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-btn {
  &Wrap {
    $this: &;
    width: 100%;
    max-width: 300px;

    &--center {
      @extend #{$this};
      margin: 60px auto 0;

      @include m.sp {
        margin: 4rem auto 0;
      }
    }

    &02 {
      $this: &;
      width: 100%;
      max-width: 420px;

      &--center {
        @extend #{$this};
        margin: 60px auto 0;

        @include m.tab {
          margin: 40px auto 0;
        }

        @include m.sp {
          margin: 4rem auto 0;
        }
      }
    }
  }

  &Type {
    &01 {
      $this: &;
      width: 100%;
      padding: 16px 0;
      display: block;
      text-align: center;
      color: v.$white;
      border-radius: 100px;
      border: 2px solid transparent;
      position: relative;
      z-index: 1;

      @include m.pc {
        transition: color v.$anime, background-color v.$anime, border-color v.$anime, box-shadow v.$anime;

        &:after {
          transition: opacity v.$anime;
        }

        .c-btnType01 {
          &_mail {
            transition: background-image v.$anime;
          }

          &_arrow {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transition: all v.$anime;
          }
        }

        &:hover {
          box-shadow: v.$shadow01;

          &:after {
            opacity: 0;
          }

          .c-btnType01 {
            &_arrow {
              transform: translate(4px, -50%);
            }
          }
        }
      }

      @include m.tab {
        padding: 12px 0;
      }

      @include m.sp {
        padding: 1.2rem 0;
      }

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 100px;
        position: absolute;
        top: 0;
        left: 0;

        @include m.sp {
          border-radius: 50px;
        }
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background-color: v.$white;
        box-shadow: v.$shadow01;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
      }

      &:after {
        z-index: -1;
      }

      &--green {
        &Grad {
          @extend #{$this};

          &:after {
            background: v.$gradGreen;
          }

          @include m.pc {
            &:after {
              background-color: v.$greenGrad03;
            }

            &:hover {
              box-shadow: none;
              color: v.$greenGrad03;
              border: 2px solid v.$greenGrad03;

              .c-btnType01_arrow {
                background: url('../img/common/ico_arrow_green.svg') no-repeat center;
                background-size: 20px 5px;

                &--reverse {
                  background: url('../img/common/ico_arrow_green_reverse.svg') no-repeat center;
                  background-size: 20px 5px;
                  transform: translate(-4px, -50%);
                }
              }
            }
          }

          &:disabled {
            pointer-events: none;

            &:after {
              background: v.$gray;
            }
          }
        }
      }

      &--black {
        @extend #{$this};
        background-color: v.$blackPale;
        border-color: v.$blackPale;

        &:before,
        &:after {
          display: none;
        }

        @include m.pc {
          &:hover {
            color: v.$blackPale;
            background-color: v.$white;
          }
        }
      }

      &--gray {
        @extend #{$this};
        background-color: v.$grayPale02;
        border-color: v.$grayPale02;

        &:before,
        &:after {
          display: none;
        }

        @include m.pc {
          &:hover {
            color: v.$grayPale02;
            background-color: v.$white;
          }
        }
      }

      &--contact {
        @extend #{$this};
        padding: 2.1vw 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include m.pcOver {
          padding: 28px 0;

          &:after {
            background-color: v.$orange;
          }
        }

        @include m.pc {
          &:hover {
            color: v.$orange;
            border-color: v.$orange;

            .c-btnType01_mail {
              background: url('../img/common/ico_mail_orange.svg') no-repeat center;
              background-size: contain;
            }

            .c-btnType01_arrow {
              background: url('../img/common/ico_arrow_orange.svg') no-repeat center;
            }
          }
        }

        @include m.tab {
          padding: 20px 0;
        }

        @include m.sp {
          padding: 2rem 0;
        }

        &:after {
          background: v.$gradOrangeR;
        }

        .c-btnType01 {
          &_text {
            font-size: 22px;
            font-family: v.$notoSerif;
            font-weight: bold;
            letter-spacing: 0.04em;

            @include m.tab {
              font-size: 18px;
            }

            @include m.sp {
              font-size: 1.4rem;
            }
          }

          &_arrow {
            width: 24px;
            height: 6px;
            background: url('../img/common/ico_arrow_white.svg') no-repeat center;
            background-size: 24px 6px;
            right: 24px;
          }
        }

        &Thin {
          @extend .c-btnType01--contact;
          padding: 16px 0;

          .c-btnType01 {
            &_text {
              @include m.fz(20);
              @include m.sp {
                font-size: 1.4rem;
                line-height: 1.5;
              }
            }

            &_arrow {
              width: 24px;
              height: 6px;
              background: url('../img/common/ico_arrow_white.svg') no-repeat center;
              background-size: 24px 6px;
              right: 30px;

              @include m.sp {
                width: 2rem;
                height: 0.6rem;
                right: 1.5rem;
              }
            }
          }
        }

        &Inner {
          @extend .c-btnType01--contact;
          padding: 1.4vw 0;

          &:after {
            background: v.$gradGreen;
          }

          @include m.pcOver {
            padding: 18px 0;
          }

          @include m.pc {
            &:after {
              background-color: v.$greenGrad03;
            }

            &:hover {
              box-shadow: none;
              color: v.$greenGrad03;
              border: 2px solid v.$greenGrad03;

              .c-btnType01_mail {
                background: url('../img/common/ico_mail_green.svg') no-repeat center;
                background-size: contain;
              }

              .c-btnType01_arrow {
                background: url('../img/common/ico_arrow_green.svg') no-repeat center;
                background-size: 20px 5px;

                &--reverse {
                  background: url('../img/common/ico_arrow_green_reverse.svg') no-repeat center;
                  background-size: 20px 5px;
                  transform: translate(-4px, -50%);
                }
              }
            }
          }

          @include m.sp {
            padding: 1.4rem 0;
          }

          .c-btnType01 {
            &_text {
              @include m.fz(20);

              @include m.sp {
                font-size: 1.6rem;
              }
            }

            &_arrow {
              @include m.sp {
                width: 1.8rem;
                right: 0.5rem;
              }
            }
          }
        }
      }

      &--cart {
        @extend #{$this};
        padding: 0.9vw 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
          background: v.$gradGreen;
        }

        @include m.pcOver {
          padding: 10px 0;
        }

        @include m.pc {
          &:after {
            background-color: v.$greenGrad03;
          }

          &:hover {
            box-shadow: none;
            color: v.$greenGrad03;
            border: 2px solid v.$greenGrad03;

            .c-btnType01_arrow {
              background: url('../img/common/ico_arrow_green.svg') no-repeat center;
              background-size: 20px 5px;

              &--reverse {
                background: url('../img/common/ico_arrow_green_reverse.svg') no-repeat center;
                background-size: 20px 5px;
                transform: translate(-4px, -50%);
              }
            }

            .c-btnType01_cart {
              background: url('../img/common/ico_cart_green.svg') no-repeat center;
              background-size: contain;
            }
          }
        }

        @include m.sp {
          padding: 1.3rem 0;
        }

        .c-btnType01 {
          &_cart {
            background: url('../img/common/ico_cart.svg') no-repeat center;
            background-size: contain;
          }

          &_text {
            @include m.fz(16);

            @include m.sp {
              font-size: 1.6rem;
            }
          }
        }
      }

      &_mail {
        $this: &;
        width: 32px;
        height: 23px;
        margin: 0 15px 0 0;
        display: block;
        background: url('../img/common/ico_mail_white.svg') no-repeat center;
        background-size: contain;

        @include m.tab {
          width: 26px;
          height: 17px;
          margin: 0 8px 0 0;
        }

        @include m.sp {
          width: 2.1rem;
          height: 1.5rem;
          margin: 0 0.8rem 0 0;
        }

        &--fix {
          @extend #{$this};
          width: 40px;
          height: 29px;
          margin: 0;
          position: absolute;
          top: 50%;
          left: 40px;
          z-index: 2;
          transform: translateY(-50%);

          @include m.tab {
            width: 35px;
            height: 24px;
          }

          @include m.sp {
            width: 2.7rem;
            height: 2.1rem;
            left: 2rem;
          }
        }

        &Size {
          &--small {
            width: 28px;
            height: 20px;

            @include m.tab {
              width: 24px;
              height: 16px;
            }

            @include m.sp {
              width: 2rem;
              height: 1.6rem;
            }
          }
        }
      }

      &_cart {
        width: 19px;
        height: 18px;
        display: block;
        margin-right: 8px;

        @include m.tab {
          width: 16px;
          height: 15px;
        }

        @include m.sp {
          width: 2rem;
          height: 1.9rem;
        }
      }

      &_text {
        $this: &;
        font-family: v.$oswald;
        @include m.fz(20);
        line-height: 1.4;
        letter-spacing: 0.08em;

        @include m.sp {
          font-size: 1.6rem;
        }

        &--jp {
          @extend #{$this};
          font-family: v.$notoSerif;
        }
      }

      &_lead {
        $this: &;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        @include m.fz(28);
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.04em;

        @include m.sp {
          font-size: 1.8rem;
          line-height: 1.6;
        }

        &--small {
          @extend #{$this};
          @include m.fz(20);
          line-height: 1.45;
        }

        &Inner {
          $this: &;
          @include m.fz(22);
          letter-spacing: 0.04em;

          @include m.sp {
            margin: 0.2rem 0 0;
            font-size: 1.5rem;
          }

          &--small {
            @extend #{$this};
            @include m.fz(14);
          }
        }
      }

      &_arrow {
        $this: &;
        width: 20px;
        height: 5px;
        display: block;
        background: url('../img/common/ico_arrow_white.svg') no-repeat center;
        background-size: 20px 5px;
        position: absolute;
        top: 50%;
        right: 13px;
        z-index: 2;
        transform: translate(0, -50%);

        @include m.tab {
          width: 16px;
        }

        @include m.sp {
          width: 2rem;
          height: 0.5rem;
          background-size: 2rem 0.5rem;
          right: 1.6rem;
        }

        &--reverse {
          @extend #{$this};
          background: url('../img/common/ico_arrow_white_reverse.svg') no-repeat center;
          background-size: 20px 5px;
          right: auto;
          left: 13px;
          transform: translate(0, -50%);
        }
      }

      &_external {
        $this: &;

        @include m.pc {
          display: none;
        }

        @include m.sp {
          width: 1.6rem;
          height: 1.6rem;
          display: block;
          background: url('../img/common/ico_external_white.svg') no-repeat center bottom;
          background-size: 1.5rem;
          position: absolute;
          top: 50%;
          right: 1.6rem;
          z-index: 2;
          transform: translate(0, -50%);
        }
      }
    }

    &02 {
      $this: &;
      padding: 0.5vw 0;
      display: block;
      text-align: center;
      border-radius: 100px;

      @include m.pc {
        transition: color v.$anime, background-color v.$anime;

        &:hover {
          color: v.$blackPale;
          background-color: v.$white;
        }
      }

      @include m.sp {
        padding: 0.8rem 0;
      }

      @include m.pcOver {
        padding: 6px 0;
      }

      &--black {
        @extend #{$this};
        color: v.$white;
        background-color: v.$blackPale;
        border: 2px solid v.$blackPale;

        @include m.pc {
          &:hover {
            color: v.$blackPale;
            background-color: v.$white;
          }
        }
      }
    }

    /* table内で使用想定 */
    &03 {
      $this: &;
      width: 100%;
      padding: 5px 0;
      display: block;
      @include m.fz(14);
      line-height: 1 !important;
      text-align: center;
      border-radius: 100px;

      @include m.pc {
        transition: color v.$anime, background-color v.$anime;
      }

      @include m.sp {
        padding: 1.2rem 0;
        font-size: 1.5rem;
      }

      &--blue {
        @extend #{$this};
        color: v.$white;
        background-color: v.$blue;
        border: 1px solid v.$blue;

        @include m.pc {
          &:hover {
            color: v.$blue;
            background-color: v.$white;
          }
        }
      }

      &--disabled {
        @extend #{$this};
        color: v.$white;
        background-color: v.$grayPale02;
        pointer-events: none;
      }
    }
    /* end table内で使用想定 */
  }

  &More {
    width: 200px;
    margin: 0 auto;
    padding: 12px 0;
    display: block;
    @include m.fz(14);
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.04em;
    text-align: center;
    color: v.$white;
    background-color: v.$blackPale;
    border: 1px solid v.$blackPale;
    border-radius: 100px;
    position: relative;
    z-index: 1;

    @include m.pc {
      cursor: pointer;
      transition: color v.$anime, background-color v.$anime;

      .c-btnMore {
        &_ico {
          &:before,
          &:after {
            transition: background-color v.$anime, transform v.$anime;
          }
        }
      }

      &:hover {
        color: v.$blackPale;
        background-color: v.$white;

        .c-btnMore {
          &_ico {
            &:before,
            &:after {
              background-color: v.$blackPale;
            }
          }
        }
      }
    }

    @include m.sp {
      font-size: 1.2rem;
    }

    &.is-active {
      color: v.$blackPale;
      background-color: v.$white;

      @include m.pc {
        &:hover {
          color: v.$white;
          background-color: v.$blackPale;

          .c-btnMore {
            &_ico {
              &:before,
              &:after {
                background-color: v.$white;
              }
            }
          }
        }
      }

      .c-btnMore {
        &_text {
          &:after {
            content: '閉じる';
            display: inline;
            font-size: inherit;
            color: inherit;
          }
        }

        &_ico {
          &:before,
          &:after {
            background-color: v.$blackPale;
          }

          &:after {
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &_text {
      &:after {
        content: 'さらに表示する';
        display: inline;
        font-size: inherit;
        color: inherit;
      }
    }

    &_ico {
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        background-color: v.$white;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
      }

      &:before {
        transform: translate(-50%, -50%);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    }
  }
}
