@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-ygg {
  &Notice {
    margin: 0 0 6.7vw;

    @include m.pcOver {
      margin: 0 0 92px;
    }

    @include m.sp {
      margin: 0 0 6rem;
    }

    &_info {
      text-align: center;
      &Head {
        padding: 2px 0;
        color: v.$white;
        background-color: v.$orange;
      }

      &Body {
        padding: 1.8vw 2vw;
        background-color: v.$white;
        border-right: 1px solid v.$orange;
        border-bottom: 1px solid v.$orange;
        border-left: 1px solid v.$orange;

        @include m.pcOver {
          padding: 26px;
        }

        @include m.sp {
          padding: 1.5rem 2rem;
        }

        a {
          text-decoration: underline;
          color: v.$red02;
          position: relative;
          z-index: 1;

          &[href$='.pdf'] {
            &:after {
              content: '';
              width: 1.3vw;
              height: 1.3vw;
              margin: 0 0 -0.2vw;
              display: inline-block;
              background: url('../img/common/ico_pdf_red.svg') no-repeat center;
              background-size: contain;

              @include m.pc {
                position: absolute;
                top: 50%;
                left: calc(100% + 4px);
                z-index: 2;
                translate: 0 -50%;
              }

              @include m.sp {
                width: 1.6rem;
                height: 1.6rem;
                margin: 0 0 -0.3rem 4px;
              }
            }
          }
        }
      }

      &Inner {
        & + & {
          padding-top: 0.8vw;

          @include m.pcOver {
            padding-top: 12px;
          }
        }
      }
    }
  }

  &Index {
    padding: 0 0 4.5vw;

    @include m.pcOver {
      padding: 0 0 60px;
    }

    @include m.sp {
      padding: 0 0 8rem;
    }

    &_intro {
      margin: 0 0 5.8vw;

      @include m.pcOver {
        margin: 0 0 80px;
      }

      @include m.sp {
        margin: 0 0 6rem;
      }
    }

    &_view {
      margin: 0 0 7.5vw;

      @include m.pcOver {
        margin: 0 0 100px;
      }

      @include m.sp {
        margin: 0 0 6rem;
      }
    }

    &_panel {
      @include m.sp {
        margin: 0 0 5rem;
      }
    }

    &_ring {
      $this: &;
      width: 100%;
      position: absolute;
      translate: -50% -50%;

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 28%;
        top: 10.5%;
        left: -8%;
        z-index: -1;

        @include m.pc {
          max-width: 320px;
        }

        @include m.tab {
          left: -7.2%;
        }
      }

      &02 {
        @extend #{$this};
        width: 32%;
        top: 69.5%;
        left: -15.5%;
        z-index: -1;

        @include m.pc {
          max-width: 370px;
        }

        @include m.tab {
          left: -7.2%;
        }
      }

      &03 {
        @extend #{$this};
        width: 30%;
        top: 71%;
        left: 100%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }

        @include m.tab {
          left: -7.2%;
        }
      }

      &04 {
        @extend #{$this};
        width: 30%;
        top: 89%;
        left: 99%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }

        @include m.tab {
          top: 90%;
          left: 96%;
        }
      }
    }

    &_access {
      padding: 8.8vw 0;

      @include m.pcOver {
        padding: 120px 0;
      }

      @include m.sp {
        padding: 6rem 0;
      }
    }
  }

  &Les {
    padding: 0 0 9.5vw;

    @include m.pcOver {
      padding: 0 0 120px;
    }

    @include m.sp {
      padding: 0 0 12rem;
    }

    &_info {
      margin-bottom: 7.5vw;

      @include m.pcOver {
        margin-bottom: 100px;
      }

      @include m.sp {
        margin-bottom: 6rem;
      }

      &Cont {
        & + & {
          margin-top: 3vw;

          @include m.pcOver {
            margin-top: 40px;
          }

          @include m.sp {
            margin-top: 6rem;
          }
        }
      }
    }

    &_school {
      &Cont {
        & + & {
          margin-top: 3.2vw;

          @include m.pcOver {
            margin-top: 44px;
          }

          @include m.sp {
            margin-top: 6rem;
          }
        }
      }
    }

    &_special {
      width: 100%;

      @include m.pc {
        display: flex;
        justify-content: space-between;
      }

      &Box {
        &:first-child {
          @include m.pc {
            max-width: 585px;
            margin-right: 40px;
          }

          @include m.sp {
            margin-bottom: 3rem;
          }
        }

        &:last-child {
          @include m.pc {
            flex: 1;
          }
        }
      }
    }

    &_ring {
      $this: &;
      width: 100%;
      position: absolute;
      translate: -50% -50%;

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 35%;
        top: 59.5%;
        left: -25%;
        z-index: -1;

        @include m.pc {
          max-width: 320px;
        }

        @include m.tab {
          left: -12%;
        }
      }

      &02 {
        @extend #{$this};
        width: 38%;
        top: 86.5%;
        left: 117%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }

      &03 {
        @extend #{$this};
        width: 53%;
        top: 69.5%;
        left: -22.5%;
        z-index: -1;

        @include m.pc {
          max-width: 479px;
        }

        @include m.tab {
          left: -12.5%;
        }
      }

      &04 {
        @extend #{$this};
        width: 38%;
        top: 181.5%;
        left: 119%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }

        @include m.tab {
          left: 108%;
        }
      }
    }
  }

  &Tomo {
    padding: 0 0 9.5vw;

    @include m.pcOver {
      padding: 0 0 120px;
    }

    @include m.sp {
      padding: 0 0 12rem;
    }

    &_heading {
      margin: 0 0 1vw;

      @include m.pcOver {
        margin: 0 0 13px;
      }

      @include m.tab {
        margin: 0 0 1.5vw;
      }

      @include m.sp {
        margin: 0 0 1.2rem;
      }
    }

    &_service {
      &Cont {
        & + & {
          margin-top: 4.5vw;

          @include m.pcOver {
            margin-top: 60px;
          }

          @include m.sp {
            margin-top: 4rem;
          }
        }
      }

      &Inner {
        & + & {
          margin-top: 2.8vw;

          @include m.pcOver {
            margin-top: 40px;
          }

          @include m.sp {
            margin-top: 3rem;
          }
        }
      }
    }

    &_ring {
      $this: &;
      width: 100%;
      position: absolute;
      translate: -50% -50%;

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 35%;
        top: 37.5%;
        left: -25%;
        z-index: -1;

        @include m.pc {
          max-width: 320px;
        }

        @include m.tab {
          left: -12%;
        }
      }

      &02 {
        @extend #{$this};
        width: 53%;
        top: 59.5%;
        left: -29.5%;
        z-index: -1;

        @include m.pc {
          max-width: 479px;
        }

        @include m.tab {
          top: 94%;      
          left: -7.2%;
        }
      }

      &03 {
        @extend #{$this};
        width: 38%;
        top: -23.5%;
        left: 111.5%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }
    }
  }
}
