@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-service {
  &Wrap {
    &_cont {
      width: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 2;

      @include m.pc {
        max-width: 950px;
        padding: 0 20px;
      }

      @include m.sp {
        width: 90%;
      }
    }

    &_bg {
      $this: &;
      height: 490px;
      margin: -118px 0 0;
      background: none;
      position: relative;
      z-index: 1;

      @include m.pcOver {
        margin: -82px 0 0;
      }

      @include m.tab {
        height: 320px;
      }

      @include m.sp {
        height: 24rem;
        margin: -5rem 0 0;
      }

      &--simple {
        @extend #{$this};
        margin: 0;
        background: none;
      }

      img {
        height: 100%;
        object-fit: cover;
        object-position: 0 95%;
      }
    }
  }

  &Card {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include m.sp {
      flex-flow: column;
    }

    &_list {
      width: 48%;

      @include m.pc {
        max-width: 435px;
      }

      @include m.sp {
        width: 100%;
      }

      & + & {
        @include m.sp {
          margin: 4rem 0 0;
        }
      }

      &Logo {
        $this: &;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        @include m.tab {
          height: 50px;
        }

        @include m.sp {
          height: 5rem;
        }

        img {
          width: auto;
          -o-object-fit: contain;
          object-fit: contain;
        }

        &--appark {
          @extend #{$this};

          img {
            height: 100%;
          }
        }

        &--koti {
          @extend #{$this};

          img {
            height: 40px;

            @include m.tab {
              height: 30px;
            }

            @include m.sp {
              height: 3rem;
            }
          }
        }
      }
    }

    &_item {
      width: 100%;
      height: 100%;
      padding: 20px 20px 24px;
      display: block;
      background-color: v.$white;
      box-shadow: 0 3px 6px rgba(v.$black, 0.16);
      position: relative;
      z-index: 1;

      @include m.pc {
        transition: transform v.$anime;

        &:after {
          content: '';
          @include m.opacity();
        }

        &:hover {
          transform: translateY(-5px);

          &:after {
            opacity: 0.3;
          }
        }
      }

      @include m.sp {
        padding: 2rem;
      }
    }

    &_cont {
      width: 100%;
      margin: 20px 0 0;
      padding: 20px 0 0;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid v.$black;

      @include m.tab {
        margin: 16px 0 0;
        padding: 16px 0 0;
      }

      @include m.sp {
        margin: 2rem 0 0;
        padding: 3rem 0 0;
        flex-flow: column;
        justify-content: flex-start;
      }

      &Box {
        @include m.pc {
          flex: 1;
        }

        @include m.sp {
          order: 1;
        }
      }

      &Text {
        @include m.fz(14);
        line-height: 2;
        letter-spacing: 0.04em;

        @include m.tab {
          @include m.fz(15);
        }

        @include m.sp {
          font-size: 1.4rem;
        }
      }

      &Pic {
        margin: 0 0 0 20px;

        @include m.pc {
          width: 48%;
          max-width: 190px;
        }

        @include m.sp {
          margin: 0 0 2rem;
          order: 0;
        }
      }
    }
  }
}
