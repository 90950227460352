@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-news {
  &Wrap {
    width: 100%;
    margin: 0 auto;

    @include m.pc {
      max-width: 840px;
      padding: 0 20px;
    }
  }

  &Info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid v.$white;

    &:last-child {
      border-bottom: 1px solid v.$white;
    }

    &_head {
      width: 95px;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(v.$white, 0.25);

      @include m.sp {
        width: 7.5rem;
        padding: 0;
      }

      &Date {
        @include m.fz(14);
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.04em;
        color: v.$white;

        @include m.sp {
          font-size: 1.2rem;
        }
      }
    }

    &_body {
      flex: 1;

      &Item {
        width: 100%;
        padding: 2.1% 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include m.sp {
          padding: 1rem;
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        &Tag {
          width: 80px;
          margin: 0 10px 0 0;

          @include m.sp {
            min-width: 6.5rem;
            margin: 0 0 0.5rem;
            display: inline-block;
          }
        }

        &Text {
          flex: 1;
          @include m.fz(14);
          font-weight: 500;
          line-height: 2;
          letter-spacing: 0.04em;

          @include m.sp {
            font-size: 1.2rem;
            line-height: 1.5;
          }

          &Inner {
            line-height: 1.5;
          }
        }
      }
    }
  }

  &Post {
    &_list {
      width: 100%;
      padding: 0 0 1vw;
      border-bottom: 1px solid v.$grayPale02;

      @include m.pcOver {
        padding: 0 0 12px;
      }

      @include m.sp {
        padding: 0 0 1rem;
      }

      & + & {
        margin-top: 1vw;

        @include m.pcOver {
          margin-top: 12px;
        }

        @include m.sp {
          margin-top: 1rem;
        }
      }
    }

    &_item {
      width: 100%;
      display: block;
    }

    &_info {
      width: 100%;

      &Head {
        width: 100%;
        margin: 0 0 8px;
        display: flex;
        align-items: center;
      }

      &Text {
        $this: &;
        @include m.fz(16);
        font-weight: 500;
        line-height: 1.75;
        letter-spacing: 0.04em;

        @include m.sp {
          font-size: 1.4rem;

          span {
            line-height: 1.6 !important;
          }
        }

        &--date {
          @extend #{$this};
          font-weight: 600;
        }
      }
    }

    &_tag {
      margin-left: 1vw;
      display: flex;
      align-items: center;

      @include m.pc {
        margin-bottom: 4px;
      }

      @include m.pcOver {
        margin-left: 12px;
      }

      @include m.sp {
        margin-left: 1rem;
      }

      &List {
        & + & {
          margin-left: 8px;
        }
      }
    }
  }

  &Menu {
    width: 100%;
    padding: 1vw 0.8vw 1.5vw;
    background-color: v.$bluePale02;

    @include m.pcOver {
      padding: 12px 10px 20px;
    }

    @include m.sp {
      padding: 1.5rem 1rem;
    }

    &_box {
      & + & {
        margin: 1.8vw 0 0;

        @include m.pcOver {
          margin: 24px 0 0;
        }

        @include m.sp {
          margin: 2rem 0 0;
        }
      }
    }

    &_heading {
      width: 100%;
      padding: 0 0 4px;
      color: v.$blue;
    }

    &_cont {
      width: 100%;

      &List {
        width: 100%;
        padding: 1.2vw 1vw;
        border-top: 1px solid v.$grayPale02;

        @include m.pcOver {
          padding: 16px 12px;
        }

        @include m.sp {
          padding: 1.2rem 0.8rem;
        }

        &:last-child {
          border-bottom: 1px solid v.$grayPale02;
        }
      }

      &Item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          margin: 2px 6px 0 0;
          display: block;
          border-right: 1px solid v.$black;
          border-bottom: 1px solid v.$black;
          transform: rotate(-45deg);
        }
      }
    }

    &_select {
      width: 100%;
      position: relative;
      z-index: 1;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
      }

      &:before {
        width: 100%;
        height: 100%;
        background-color: v.$white;
        z-index: -2;
      }

      &:after {
        width: 8px;
        height: 8px;
        border-right: 1px solid v.$black;
        border-bottom: 1px solid v.$black;
        top: calc(50% - 2px);
        right: 1vw;
        z-index: -1;
        transform: translateY(-50%) rotate(45deg);

        @include m.pcOver {
          right: 12px;
        }

        @include m.sp {
          right: 1.5rem;
        }
      }

      &Input {
        width: 100%;
        padding: 0 8px;
        appearance: none;
        font-size: 12px;
        line-height: 2.3333333333333335;
        position: relative;

        @include m.tab {
          font-size: 11px;
        }

        @include m.sp {
          padding: 0.8rem 1rem;
          font-size: 1.2rem;
        }
      }
    }
  }
}
