@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-footer {
  padding: 40px 0 16px;
  background-color: v.$black;

  @include m.sp {
    padding: 4rem 0 7rem;
  }

  &Btn {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 100;
    border-radius: 50%;
    overflow: hidden;
    opacity: 0;
    transition: opacity v.$anime;

    @include m.pc {
      .l-footerBtn {
        &_item {
          &:after {
            opacity: 0;
            transition: opacity v.$anime;
          }
        }

        &_obj,
        &_obj:after {
          transition: border-color v.$anime;
        }
      }

      &:hover {
        .l-footerBtn {
          &_item {
            &:after {
              opacity: 1;
            }
          }

          &_obj,
          &_obj:after {
            border-color: v.$blue;
          }
        }
      }
    }

    @include m.tab {
      width: 48px;
      height: 48px;
    }

    @include m.sp {
      width: 5.5rem;
      height: 5.5rem;
      right: 0.5rem;
      bottom: 5.5rem;
    }

    &.is-view {
      opacity: 1;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: v.$gradBlue;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
    }

    &_item {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      padding-top: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      position: relative;
      z-index: 1;
      overflow: hidden;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: v.$white;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
      }
    }

    &_obj {
      width: 40%;
      height: 40%;
      display: block;
      border-top: 2px solid v.$white;
      position: relative;
      z-index: 1;

      @include m.sp {
        border-color: v.$blue;
      }

      &:after {
        content: '';
        width: 75%;
        height: 75%;
        border-top: 2px solid v.$white;
        border-right: 2px solid v.$white;
        position: absolute;
        top: calc(50% + 6px);
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%) rotate(-45deg);

        @include m.sp {
          border-color: v.$blue;
        }
      }
    }
  }

  &Main {
    width: 100%;

    @include m.pc {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &_menu {
      @include m.pc {
        flex: 1;
      }
    }

    &_group {
      // display: flex;

      @include m.pc {
        margin: 0 0 0 40px;
      }
    }
  }

  &Menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include m.sp {
      margin: 0 0 3rem;
      flex-wrap: wrap;
    }

    &_cont {
      width: 22%;

      @include m.sp {
        width: 49%;
      }

      &:nth-child(n + 3) {
        @include m.sp {
          margin: 4rem 0 0;
        }
      }
    }

    &_intro {
      width: 100%;
      line-height: 1;

      &:after {
        content: '';
        width: 20px;
        height: 1px;
        margin: 16px 0 12px;
        display: block;
        background-color: v.$white;

        @include m.sp {
          width: 2rem;
          margin: 1.6rem 0 1.5rem;
        }
      }
    }

    &_lead {
      $this: &;
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.04em;
      color: v.$white;

      @include m.tab {
        font-size: 12px;
      }

      @include m.sp {
        font-size: 1.4rem;
      }

      &--link {
        @extend #{$this};

        & + .l-footerMenu_lead--link {
          margin: 20px 0 0;

          @include m.sp {
            margin: 2.5rem 0 0;
          }
        }
      }
    }

    &_box {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;

      &List {
        width: 100%;

        & + & {
          margin: 10px 0 0;

          @include m.sp {
            margin: 1rem 0 0;
          }
        }
      }

      &Item {
        display: flex;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.04em;
        color: v.$white;

        @include m.sp {
          font-size: 1.2rem;
        }

        &:before {
          content: '・';
          display: inline-block;
        }

        &Inner {
          $this: &;
          line-height: 1.4;

          &--nowrap {
            white-space: nowrap;
            &--pc {
              @include m.pc {
              }
            }
          }
        }

        &Ico {
          &--external {
            width: 12px;
            margin: 0 0 0 6px;
            display: inline-block;

            @include m.tab {
              width: 10px;
            }

            @include m.sp {
              width: 1.2rem;
              margin: 0 0 0 0.8rem;
            }
          }
        }
      }
    }

    &Inner {
      margin: 8px 0 0 1.6em;

      @include m.sp {
        margin: 8px 0 0 1.2rem;
      }

      &_list {
        @extend .l-footerMenu_boxList;

        &Item {
          @extend .l-footerMenu_boxItem;
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &Group {
    width: 100%;
    padding: 8px 10px 16px;
    background-color: v.$white;

    @include m.pc {
      width: 210px;
    }

    @include m.sp {
      padding: 0.7rem 1rem 1rem;
    }

    &_text {
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1rem;
      }
    }

    &_cont {
      &List {
        & + & {
          margin: 16px 0 0;
          border-top: 1px solid v.$black;
        }
      }

      &Item {
        width: 100%;
        padding: 16px 0;
        display: block;
        position: relative;
        z-index: 1;

        @include m.pc {
          &:before {
            content: '';
            @include m.opacity();
          }

          &:hover {
            &:before {
              opacity: 0.3;
            }
          }
        }

        @include m.sp {
          width: 20rem;
          margin: 0 auto;
        }

        &:after {
          content: '';
          width: 8px;
          height: 8px;
          background: url('../img/common/ico_external.svg') no-repeat center;
          background-size: contain;
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: -1;

          @include m.sp {
            width: 0.8rem;
            height: 0.8rem;
          }
        }
      }

      &Img {
        &--group {
          &01 {
            margin: 0 auto;

            @include m.pc {
              max-width: 186px;
            }

            @include m.sp {
              width: 19rem;
            }
          }

          &02 {
            margin: 0 auto;

            @include m.pc {
              max-width: 150px;
            }

            @include m.sp {
              width: 15rem;
            }
          }
        }
      }
    }
  }

  &Sub {
    margin: 32px 0 0;

    @include m.pc {
      padding: 20px 0 0;
      border-top: 1px solid rgba(v.$white, 0.6);
    }

    @include m.sp {
      margin: 3rem 0 0;
    }
  }

  &Other {
    width: 100%;

    @include m.pc {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    &_list {
      @include m.pc {
        margin-top: 4px;
        margin-bottom: 4px;
      }

      & + & {
        @include m.pc {
          margin-left: 4.5%;
        }

        @include m.sp {
          margin: 1.5rem 0 0;
        }
      }
    }

    &_item {
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.04em;
      color: v.$white;

      @include m.pc {
        white-space: nowrap;
      }

      @include m.sp {
        font-size: 1.2rem;
      }
    }
  }

  &Copy {
    width: 100%;
    padding: 20px 0 0;
    text-align: center;
    font-weight: bold;

    @include m.sp {
      padding: 6rem 0 0;
    }

    &_inner {
      font-size: 10px;
      line-height: 1;
      letter-spacing: 0.04em;
      color: v.$white;

      @include m.sp {
        font-size: 1rem;
      }
    }
  }

  &Shinsa {
    margin-top: 12px;
    & * {
      color: v.$white;
      border-color: v.$white;
    }
  }
}
