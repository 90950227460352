@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-fix {
  &Bnr {
    width: 72px;
    position: fixed;
    top: 24.8%;
    right: 0;
    z-index: 10;

    @include m.tab {
      width: 52px;
    }

    @include m.sp {
      width: 100%;
      height: 5rem;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
    }

    &.is-hide {
      display: none;
    }

    &_item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: v.$white;
      position: relative;
      z-index: 1;
      overflow: hidden;

      @include m.pc {
        padding: 18px 0;
        flex-flow: column;
        border: 2px solid transparent;
        border-right: none;
        border-radius: 10px 0 0 10px;
        transition: color v.$anime, border-color v.$anime;

        &:after {
          transition: opacity v.$anime;
        }

        .c-fixBnr {
          &_item {
            &Ico {
              transition: background-image v.$anime;
            }
          }
        }

        &:hover {
          color: v.$orange;
          border-color: v.$orange;

          &:after {
            opacity: 0;
          }

          .c-fixBnr {
            &_item {
              &Ico {
                background: url('../img/common/ico_mail_orange.svg') no-repeat center;
                background-size: 30px 21px;
              }
            }
          }
        }
      }

      @include m.sp {
        height: 100%;
        align-items: center;
      }

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:before {
        background-color: v.$white;
        z-index: -2;
      }

      &:after {
        background: v.$gradOrange;
        z-index: -1;

        @include m.sp {
          background: v.$gradOrangeR;
        }
      }

      &Ico {
        width: 30px;
        height: 21px;
        margin: 0 0 10px;
        display: block;
        background: url('../img/common/ico_mail_white.svg') no-repeat center;
        background-size: 30px 21px;

        @include m.tab {
          width: 22px;
          height: 13px;
          margin: 0 0 6px;
          background-size: 22px 13px;
        }

        @include m.sp {
          width: 2.1rem;
          height: 1.5rem;
          margin: 0 1rem 0 0;
          background-size: 2.1rem 1.5rem;
        }
      }

      &Text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.35;

        @include m.pc {
          flex-flow: column;
        }

        @include m.tab {
          font-size: 16px;
        }

        @include m.sp {
          justify-content: flex-start;
          align-items: center;
          font-size: 1.6rem;
          letter-spacing: 0.04em;
        }
      }
    }
  }
}
