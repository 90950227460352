@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-ad {
  &Intro {
    margin: 0 0 4.4vw;
    text-align: center;

    @include m.pcOver {
      margin: 0 0 60px;
    }
  }

  &Ring {
    $this: &;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;

    @include m.sp {
      display: none;
    }

    &01 {
      @extend #{$this};
      width: 29%;
      top: 77%;
      left: 119.6%;

      @include m.pc {
        max-width: 260px;
      }
    }

    &02 {
      @extend #{$this};
      width: 32%;
      top: 91.8%;
      left: -6.2%;

      @include m.pc {
        max-width: 370px;
      }
    }
  }
}
