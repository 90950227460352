@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-table {
  &_vertical {
    @include m.pc {
      margin: 0 auto;
      writing-mode: vertical-lr;
    }

    @include m.sp {
      line-height: 1.1 !important;
    }
  }
  &_size {
    &--w45 {
      width: 45px;
      text-align: center;

      @include m.pc {
        padding-right: 12px !important;
        padding-left: 12px !important;
      }
    }

    &--w64 {
      width: 64px;
    }
    &--w80 {
      width: 80px;

      @include m.tab {
        width: 60px;
      }
    }
    &--w92 {
      width: 92px;
      @include m.tab {
        width: 70px;
      }
    }
    &--w100 {
      width: 100px;

      @include m.tab {
        width: 80px;
      }
    }
    &--w114 {
      width: 114px;
      @include m.tab {
        width: 70px;
      }
    }
    &--w134 {
      width: 134px;
      @include m.tab {
        width: 90px;
      }
    }
    &--w150 {
      @include m.pc {
        width: 150px;
      }
      @include m.tab {
        width: 120px;
      }
    }
    &--w160 {
      width: 160px;
      @include m.tab {
        width: 140px;
      }
    }
    &--w170 {
      width: 170px;
      @include m.tab {
        width: 140px;
      }
    }
    &--w220 {
      width: 220px;
      @include m.tab {
        width: 140px;
      }
    }
    &--w250 {
      width: 250px;
      @include m.tab {
        width: 140px;
      }
    }
    &--w285 {
      @include m.pc {
        width: 285px;
      }
      @include m.tab {
        width: 160px;
      }
    }
    &--w333 {
      width: 333px;
    }
    &--w345 {
      width: 345px;
      @include m.tab {
        width: 240px;
      }
    }
    &--w387 {
      width: 387px;
      @include m.tab {
        width: 280px;
      }
    }
    &--w456 {
      width: 456px;
    }
  }

  &Type {
    &01 {
      width: 100%;

      @include m.pc {
        table-layout: fixed;
      }

      @include m.sp {
        display: block;
      }

      & + & {
        @include m.sp {
          margin: 4rem 0 0;
        }
      }

      &_head {
        @include m.sp {
          display: none;
        }

        th {
          padding: 10px 0;
          text-align: center;
          color: v.$white;
          background-color: v.$blue;

          & + th {
            border-left: 1px solid v.$grayPale02;
          }
        }
      }

      &_body {
        @include m.sp {
          width: 100%;
          display: block;
        }

        tr {
          border-bottom: 1px solid v.$grayPale02;

          @include m.sp {
            width: 100%;
            padding: 0 0 2rem;
            display: block;
          }
        }

        &Cont {
          td {
            @include m.sp {
              width: 100%;
              display: block;
            }

            & + td {
              border-left: 1px solid v.$grayPale02;

              @include m.sp {
                margin: 2rem 0 0;
                padding: 2rem 1rem 0;
                border-top: 1px solid v.$grayPale02;
                border-left: none;
              }
            }

            &:first-child + td {
              @include m.sp {
                margin: 0;
                border-top: none;
              }
            }

            & * {
              line-height: 1.6;
            }
          }
          &Box {
            $this: &;
            padding: 20px;
            position: relative;
            z-index: 1;

            @include m.sp {
              margin: 2rem 0 0;
              padding: 0;
            }

            &--head {
              @extend #{$this};
              background-color: v.$bluePale02;

              @include m.sp {
                padding: 1rem;
              }
            }

            &--before {
              @extend #{$this};

              @include m.sp {
                &:before {
                  content: attr(data-before);
                  width: 100%;
                  display: block;
                  font-size: 1.2rem;
                  font-weight: bold;
                  line-height: 2;
                  letter-spacing: 0.04em;
                }
              }
            }
          }
        }

        &Map {
          margin: 10px 0 0;
          display: flex;
          justify-content: flex-end;
          font-family: v.$oswald;
          text-decoration: underline;
          color: v.$blue;

          @include m.pc {
            cursor: pointer;
            transition: opacity v.$anime;

            &:hover {
              opacity: 0.7;
            }
          }

          @include m.sp {
            margin: 1rem 0 0;
          }

          & * {
            line-height: 1 !important;
          }
        }
      }

      &_separate {
        margin: 12px 0 0;
        display: block;

        @include m.sp {
          margin: 0.8rem 0 0;
        }
      }
    }

    &02 {
      width: 100%;

      &_head {
        @include m.sp {
          display: none;
        }

        th {
          padding: 8px;
          text-align: center;
          background-color: v.$bluePale02;

          & + th {
            border-left: 1px solid v.$grayPale02;
          }
        }
      }

      &_body {
        td {
          vertical-align: top;
          border-right: 1px solid v.$grayPale02;

          @include m.pc {
            padding: 1.5vw;
          }

          @include m.pcOver {
            padding: 20px;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    &03 {
      width: 100%;
      table-layout: fixed;

      &--nofixed {
        width: 100%;
      }

      &_tag {
        @include m.fz(14);

        @include m.pcOver {
          font-size: 13px !important;
        }
      }

      &_head {
        @include m.sp {
          display: none;
        }
        th {
          padding: 15px 0;
          text-align: center;
          vertical-align: middle;
          background-color: v.$bluePale02;
        }
      }

      &_body {
        @include m.sp {
          width: 100%;
          display: block;
        }
        tr {
          @include m.sp {
            width: 100%;
            padding: 2rem;
            display: block;
            background-color: v.$white;
          }

          &:nth-child(even) {
            @include m.pc {
              background-color: v.$white;
            }
          }

          & + tr {
            border-top: 1px solid v.$grayPale02;
          }
        }

        td {
          padding: 1.6vw 1.2vw;
          vertical-align: top;
          @include m.pcOver {
            padding: 21px 16px;
          }
          @include m.sp {
            width: 100%;
            display: block;
          }

          &.c-tableType03 {
            /* btnやtagがある場合のセルのpadding */
            &_inner {
              padding: 1.3vw 1.2vw;
              @include m.pcOver {
                padding: 16px;
              }
            }
            /* end btnやtagがある場合のセルのpadding */
          }
        }

        &Map {
          text-align: center;
          font-family: v.$oswald;
          text-decoration: underline;
          @include m.fz(14);
          line-height: 1.4285714286 !important;
          color: v.$blue;

          @include m.pc {
            cursor: pointer;
            transition: opacity v.$anime;
            position: absolute;
            top: 0;
            right: 0;

            &:hover {
              opacity: 0.7;
            }
          }

          @include m.pcOver {
            font-size: 13px;
          }

          @include m.sp {
            margin-left: 1rem;
            font-size: 1.4rem;
          }

          & * {
            line-height: 1 !important;
          }
        }
      }

      &_text {
        $this: &;
        @include m.fz(14);
        line-height: 1.4285714285714286;

        @include m.pcOver {
          font-size: 13px;
        }

        @include m.sp {
          font-size: 1.4rem;
          line-height: 1.8;
        }

        &--map {
          @extend #{$this};
          padding: 0 2.5vw 0 0;
          position: relative;
          z-index: 1;

          @include m.pcOver {
            padding: 0 30px 0 0;
          }

          @include m.tab {
            padding: 0 3em 0 0;
          }
        }

        &--data {
          @extend #{$this};

          @include m.sp {
            &:before {
              content: attr(data-text);
              font-size: inherit;
              display: inline-block;
            }
          }
        }

        &--large {
          &--sp {
            @extend #{$this};
            @include m.sp {
              font-size: 1.6rem;
              font-weight: bold;
            }
          }
        }
      }

      &_ico {
        &--pic {
          width: 2.4vw;
          height: 2.4vw;
          margin: 0 4px;
          display: inline-block;
          @include m.fz(20);
          position: relative;
          z-index: 1;
          @include m.pc {
            cursor: pointer;
            transition: opacity v.$anime;

            &:hover {
              opacity: 0.7;
            }
          }
          @include m.pcOver {
            width: 24px;
            height: 24px;
          }
          @include m.sp {
            width: 1.4rem;
            height: 1.4rem;
            font-size: 1.4rem;
          }
          &:after {
            content: '\EA1F';
            width: 100%;
            height: 100%;
            display: block;
            @include m.fz(20);
            font-family: icon;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            color: v.$blue03;
            position: absolute;
            top: 95%;
            left: 20%;
            translate: -50% -50%;

            @include m.pcOver {
              top: 90%;
            }

            @include m.tab {
              left: 10%;
            }

            @include m.sp {
              font-size: 1.8rem;
              top: 0.6rem;
            }
          }
        }
      }

      &_border {
        &--right {
          border-right: 1px solid v.$grayPale02;
        }
      }

      &_btn {
        @include m.sp {
          margin-top: 1rem;
        }
      }

      &_flex {
        @include m.sp {
          display: flex;
        }

        &Item {
          &:last-child {
            @include m.sp {
              flex: 1;
            }
          }
        }
      }

      &_tag {
        @include m.pc {
          width: 100%;
        }
      }
    }
  }

  &Basic {
    background-color: v.$white;
    @include m.sp {
      width: 100%;
      overflow-x: scroll;
    }

    &_cont {
      width: 100%;

      @include m.sp {
        width: 910px;
      }

      th,
      td {
        border: 1px solid v.$grayPale02;
        vertical-align: middle;
      }

      th {
        padding: 4px;
      }

      &Inner {
        padding: 1vw;
        line-height: 1.5 !important;

        @include m.pcOver {
          padding: 12px;
        }

        @include m.sp {
          padding: 1rem;
        }
      }

      &Box {
        padding: 1.5vw;

        @include m.pcOver {
          padding: 20px;
        }

        @include m.sp {
          padding: 1rem;
        }
      }
    }

    &_note {
      margin-top: 0.5vw;

      @include m.pcOver {
        margin-top: 5px;
      }

      @include m.sp {
        margin-top: 0.5rem;
      }
    }
  }

  &Inner {
    & + & {
      margin: 1.5vw 0 0;

      @include m.pcOver {
        margin: 20px 0 0;
      }

      @include m.sp {
        margin: 1.5rem 0 0;
      }
    }
  }

  &Info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & + & {
      padding-top: 5px;
    }

    &_head {
      width: 14px;
      margin: 0 4px 0 0;

      &--large {
        width: 28px;
        margin: 0 4px 0 0;
      }
    }

    &_body {
      flex: 1;
    }
  }

  &Scroll {
    @include m.sp {
      width: 100%;
      overflow-x: scroll;

      table {
        width: 762px;

        thead {
          display: table-header-group;
        }

        tr {
          width: auto;
          display: table-row;
        }

        td {
          width: auto;
          padding: 1rem;
          display: table-cell;
        }
      }
    }
  }
}
