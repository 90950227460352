@keyframes sphereRotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes viewAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* circle */
@keyframes circleMove01 {
  0%,
  100% {
    transform: translate(-50%, -51.5%);
  }
  50% {
    transform: translate(-50%, -49.5%);
  }
}
@keyframes circleMove02 {
  0%,
  100% {
    transform: translate(-50%, -52.5%);
  }
  50% {
    transform: translate(-50%, -48.5%);
  }
}
@keyframes circleMove03 {
  0%,
  100% {
    transform: translate(-50%, -48.5%);
  }
  50% {
    transform: translate(-50%, -50.5%);
  }
}
/* end circle */

/* asteroid */
@keyframes asteroidMove01 {
  0%,
  100% {
    transform: translate(-50%, -65%) scale(1.05);
  }
  50% {
    transform: translate(-50%, -35%) scale(1);
  }
}
@keyframes asteroidMove02 {
  0%,
  100% {
    transform: translate(-50%, -35%) scale(1);
  }
  50% {
    transform: translate(-50%, -65%) scale(1.05);
  }
}
@keyframes asteroidMove03 {
  0%,
  100% {
    transform: translate(-50%, -25%);
  }
  50% {
    transform: translate(-50%, -75%);
  }
}
@keyframes asteroidMove04 {
  0%,
  100% {
    transform: translate(-50%, -40%);
  }
  50% {
    transform: translate(-50%, -60%);
  }
}
/* end asteroid */
