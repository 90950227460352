@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-flower {
  &Hero {
    width: 100%;
    margin: -9vw 0 0;
    position: relative;
    z-index: 1;

    @include m.sp {
      margin: -7.5rem 0 0;
    }
  }

  &Filter {
    margin: 0 0 4.5vw;

    @include m.pcOver {
      margin: 0 0 60px;
    }

    @include m.sp {
      margin: 0 0 4rem;
    }

    &_wrap {
      padding: 3vw 0;
      border-top: 1px solid v.$grayPale02;
      border-bottom: 1px solid v.$grayPale02;

      @include m.pcOver {
        padding: 40px 0;
      }

      @include m.sp {
        padding: 2.5rem 0 4rem;
      }
    }

    &_menu {
      display: flex;
      justify-content: center;
      gap: 2em;

      @include m.sp {
        gap: 1em;
      }

      &Input {
        display: flex;

        @include m.sp {
          flex: 1;
        }
      }

      &Btn {
        width: 100%;
        display: flex;

        @include m.pc {
          max-width: 160px;
        }

        @include m.tab {
          max-width: 140px;
        }

        @include m.sp {
          width: 25%;
        }
      }
    }

    &_intro {
      width: 100%;
      margin: 0 0 2.2vw;

      @include m.pcOver {
        margin: 0 0 30px;
      }

      @include m.sp {
        margin: 0 0 1.5rem;
      }
    }

    &_cont {
      $this: &;

      @include m.pc {
        display: flex;
      }

      @include m.sp {
      }

      & + & {
        margin-top: 3vw;

        @include m.pcOver {
          margin-top: 40px;
        }

        @include m.sp {
          margin-top: 2rem;
        }
      }

      &--select {
        @extend #{$this};
        align-items: center;

        &Inner {
          @extend #{$this};
          align-items: center;
          @include m.sp {
            width: 100%;
            display: flex;
            gap: 1em;
          }

          .p-flowerFilter_cont {
            &Head {
              @include m.sp {
                width: auto;
                margin: 0;
              }
            }

            &Body {
              @include m.sp {
                flex: 1;
              }
            }
          }
        }
      }

      &Head {
        width: 100%;
        margin-right: 3vw;

        @include m.pcOver {
          margin-right: 40px;
        }

        @include m.pc {
          max-width: 185px;
          display: flex;
          justify-content: flex-end;
        }

        @include m.tab {
          max-width: 100px;
        }

        @include m.sp {
          margin: 0 0 1rem;
        }
      }

      &Body {
        @include m.pc {
          flex: 1;
        }
      }

      &Inner {
        & + & {
          margin-top: 1.5vw;
          padding-top: 1.5vw;
          border-top: 1px dashed v.$grayPale02;

          @include m.pcOver {
            margin-top: 20px;
            padding-top: 20px;
          }

          @include m.sp {
            margin-top: 2rem;
            padding-top: 2rem;
          }
        }
      }
    }

    &_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @include m.pc {
        &:before,
        &:after {
          content: '';
          max-width: 180px;
          width: 21%;
          margin-right: 2.2vw;
          display: block;
        }

        &:before {
          order: 1;
        }

        &:after {
          order: 1;
        }
      }

      @include m.pcOver {
        &:before,
        &:after {
          width: 172px;
          margin-right: 30px;
        }
      }

      @include m.sp {
        gap: 2rem;
      }

      &Item {
        padding: 0.4vw 3.2vw;
        text-align: center;
        color: rgba(v.$black, 0.5);
        background-color: v.$grayPale03;
        border-radius: 100px;
        position: relative;
        z-index: 1;

        @include m.pc {
          max-width: none;
          margin-right: 2.2vw;
          cursor: pointer;
          transition: color v.$anime, background-color v.$anime;

          &:hover {
            color: v.$white;
            background-color: v.$blue;
          }
        }

        @include m.pcOver {
          margin-right: 30px;
          padding: 2px 32px;
        }

        @include m.sp {
          padding: 0.4rem 3.2rem;
        }

        &:before {
          content: '';
          width: 1vw;
          height: 0.6vw;
          display: block;
          border-bottom: 2px solid v.$white;
          border-left: 2px solid v.$white;
          position: absolute;
          top: calc(50% - 1px);
          left: 0.8vw;
          z-index: 2;
          translate: 0 -50%;
          rotate: -45deg;
          opacity: 0;

          @include m.pcOver {
            width: 12px;
            height: 7px;
            left: 10px;
          }

          @include m.sp {
            width: 1.2rem;
            height: 0.6rem;
            left: 1rem;
          }
        }

        &.is-selected {
          color: v.$white;
          background-color: v.$blue;

          &:before {
            opacity: 1;
          }
        }

        &:nth-child(n + 5) {
          @include m.pcOver {
            margin-top: 20px;
          }
          @include m.pc {
            margin-top: 1.5vw;
          }
        }

        input[type='checkbox'] {
          display: none;
        }
      }
    }

    &_btn {
      $this: &;
      width: 100%;

      &--unit {
        margin: 3.5vw auto 0;

        @include m.pc {
          max-width: 300px;
        }

        @include m.pcOver {
          margin: 40px auto 0;
        }

        @include m.tab {
          width: 240px;
        }

        @include m.sp {
          width: 75%;
          margin: 3rem auto 0;
        }
      }

      &Item {
        cursor: pointer;

        &.is-disabled {
          pointer-events: none;
          background-color: v.$grayPale;
          border-color: v.$grayPale;
          transition: none !important;
        }
      }
    }
  }

  &Result {
    &_intro {
      @include m.pc {
        display: flex;
        align-items: center;
      }

      &Box {
        & + & {
          @include m.pcOver {
            margin-left: 20px;
            padding-left: 20px;
          }

          @include m.pc {
            margin-left: 1.5vw;
            padding-left: 1.5vw;
            border-left: 2px solid v.$black;
          }

          @include m.sp {
            margin-top: 1rem;
            padding-top: 0.8rem;
            border-top: 2px solid v.$black;
          }
        }
      }

      &Text {
        @include m.fz(32);
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.04em;

        @include m.sp {
          font-size: 2.2rem;
        }
      }
    }

    &_list {
      @include m.sp {
        margin-top: 5rem;
      }
    }
  }

  &Info {
    &_intro {
      margin: 0 0 1.5vw;

      @include m.pcOver {
        margin: 0 0 20px;
      }

      @include m.sp {
        margin: 0 0 1rem;
      }

      &Text {
        height: 3.5em;
        margin-top: 0.5vw;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;

        @include m.pcOver {
          margin-top: 5px;
        }

        @include m.sp {
          height: 5rem;
        }
      }
    }

    &_box {
    }

    &_unit {
      display: flex;

      &Head {
        width: 85px;
        margin-right: 4px;
        display: flex;
        justify-content: space-between;

        @include m.sp {
          width: 27%;
        }

        &:after {
          content: ':';
          margin: 0 2px;
          display: inline-block;
          font-size: inherit;
        }
      }

      &Body {
        flex: 1;
      }
    }

    &_price {
      $this: &;
      margin-top: 1.5vw;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      line-height: 1;
      letter-spacing: 0.04em;

      @include m.pcOver {
        margin-top: 20px;
      }

      @include m.sp {
        margin-top: 1.5rem;
      }

      &--start {
        @extend #{$this};
        justify-content: flex-start;
      }

      &Num {
        margin: 0 0.2em 0 0;
        @include m.fz(28);

        @include m.sp {
          font-size: 2.6rem;
        }
      }

      &Text {
        padding: 0 0 1px;
        @include m.fz(14);
      }

      &Lead {
        @include m.fz(22);

        @include m.sp {
          font-size: 1.8rem;
        }
      }
    }

    &_btn {
      margin-top: 2vw;

      @include m.pcOver {
        margin-top: 25px;
      }

      @include m.sp {
        margin-top: 2rem;
      }
    }
  }

  &Contact {
    &_cont {
      @include m.pc {
        display: flex;
        justify-content: space-between;
      }
    }

    &_pic {
      @include m.pc {
        width: 40%;
        max-width: 340px;
      }

      &--inner {
        @include m.pc {
          width: 24%;
          max-width: 262px;
        }
      }
    }

    &_box {
      padding: 2vw 2.5vw;
      background-color: v.$white;

      @include m.pc {
        flex: 1;
      }

      @include m.pcOver {
        padding: 26px 32px;
      }

      @include m.sp {
        padding: 2rem 1.5rem;
      }
    }

    &_input {
      $this: &;
      margin-top: 1.2vw;

      @include m.pc {
        display: flex;
        align-items: center;
      }

      @include m.pcOver {
        margin-top: 16px;
      }

      @include m.sp {
        margin-top: 2rem;
      }

      &--inner {
        @extend #{$this};
        margin-top: 0;

        @include m.pcOver {
          margin-top: 0;
        }

        @include m.sp {
          margin-top: 0;
        }
      }
    }

    &_confirm {
      &Num {
        @include m.fz(20);
        font-weight: bold;
        line-height: 1.5;

        @include m.sp {
          font-size: 1.6rem;
        }
      }
    }
  }

  &Tag {
    width: 100%;
    &_cont {
      width: 100%;
      @include m.pc {
        display: flex;
        justify-content: space-between;
      }
    }

    &_pic {
      @include m.pc {
        width: 35%;
        max-width: 220px;
        margin-right: 2.8vw;
      }

      @include m.pcOver {
        margin-right: 36px;
      }

      @include m.sp {
        height: 215px;
        margin: 0 0 2.5rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: left;
        }
      }
    }

    &_box {
      @include m.pc {
        flex: 1;
      }

      &Inner {
        & + & {
          margin-top: 1.8vw;

          @include m.pcOver {
            margin-top: 24px;
          }

          @include m.sp {
            margin-top: 1.5rem;
          }
        }
      }
    }

    &_form {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1em;

      @include m.tab {
        gap: 0.5em;
      }

      &Head {
        width: 16px;
      }

      &Body {
        flex: 1;
      }
    }

    &_note {
      margin-left: 32px;
    }
  }

  &Size {
    padding-top: 5.4%;

    @include m.sp {
      padding-top: 4rem;
    }

    &_inner {
      width: 100%;
      padding: 40px 24px;
      background-color: v.$white;
      box-shadow: 0px 3px 3px rgba(v.$black,.1);

      @include m.sp {
        padding: 2rem 1rem;
      }
    }
  }
}
