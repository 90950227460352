@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-more {
  @include m.pc {
    .c-more {
      &_unit {
        &Arrow {
          transition: transform v.$anime;
        }
      }
    }

    &:hover {
      .c-more {
        &_unit {
          &Arrow {
            transform: translateX(4px);
          }
        }
      }
    }
  }

  &_unit {
    $this: &;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include m.sp {
      margin: 2rem 0 0;
    }

    &--black {
      @extend #{$this};

      .c-more_unit {
        &Text {
          color: v.$blackPale;
        }
      }
    }

    &--white {
      @extend #{$this};

      .c-more_unit {
        &Text {
          color: v.$whitePale;
        }
      }
    }

    &--area {
      &Kansai {
        @extend #{$this};

        .c-more_unit {
          &Text {
            color: v.$areaKansai;
          }
        }
      }

      &Kansai {
        @extend #{$this};

        .c-more_unit {
          &Text {
            color: v.$areaKansai;
          }
        }
      }

      &Hokuriku {
        @extend #{$this};

        .c-more_unit {
          &Text {
            color: v.$areaHokuriku;
          }
        }
      }

      &Tokai {
        @extend #{$this};

        .c-more_unit {
          &Text {
            color: v.$areaTokai;
          }
        }
      }

      &Chugoku {
        @extend #{$this};

        .c-more_unit {
          &Text {
            color: v.$areaChugoku;
          }
        }
      }

      &Shikoku {
        @extend #{$this};

        .c-more_unit {
          &Text {
            color: v.$areaShikoku;
          }
        }
      }

      &Kyusyu {
        @extend #{$this};

        .c-more_unit {
          &Text {
            color: v.$areaKyusyu;
          }
        }
      }
    }

    &Text {
      font-family: v.$oswald;
      @include m.fz(13);
      letter-spacing: 0.08em;
      color: v.$blue;

      @include m.sp {
        font-size: 1.2rem;
      }
    }

    &Arrow {
      width: 20px;
      margin: 0 0 0 6px;
      display: block;

      @include m.sp {
        width: 2rem;
      }
    }
    
    &External {
      width: 12px;
      margin: 0 0 0 6px;
      display: block;

      @include m.sp {
        width: 1.2rem;
      }
    }
  }
}
