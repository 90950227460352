@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-info {
  &Table {
    width: 100%;
    border-top: 1px solid v.$grayPale02;
    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid v.$grayPale02;

      &Head {
        width: 80px;
        padding: 0.5vw 0;
        font-weight: bold;
        text-align: center;
        background-color: v.$grayPale03;

        @include m.pcOver {
          padding: 10px 0;
        }

        @include m.sp {
          width: 25%;
          padding: 0.5rem 0;
        }
      }

      &Body {
        padding: 0.5vw 1.5vw;
        flex: 1;

        @include m.pcOver {
          padding: 10px 20px;
        }

        @include m.sp {
          padding: 0.5rem 1rem;
        }
      }

      &Text {
        @include m.fz(14);
        line-height: 1.9;
        letter-spacing: 0.04em;

        @include m.sp {
          font-size: 1.2rem;
        }
      }
    }

    &_note {
      margin: 10px 0 0;

      @include m.sp {
        margin: 0.5rem 0 0;
      }

      &Text {
        @include m.fz(12);
        line-height: 1.5;
        letter-spacing: 0.04em;

        @include m.sp {
          font-size: 1rem;
        }
      }
    }

    &Type02 {
      width: 100%;
      border-top: 1px solid v.$grayPale02;
      &_cont {
        $this: &;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid v.$grayPale02;

        &--center {
          @extend #{$this};

          .c-infoTableType02_contHead,
          .c-infoTableType02_contBody {
            display: flex;
            align-items: center;
          }
        }

        &--inner {
          @extend #{$this};

          .c-infoTableType02 {
            &_cont {
              &Head {
                width: 110px;
                padding: 1.5vw 1vw 1.5vw 1.5vw;

                @include m.pcOver {
                  padding: 20px 12px 20px 20px;
                }

                @include m.sp {
                  width: 32%;
                  padding: 1rem 1.2rem;
                }
              }

              &Body {
                padding: 1.5vw;

                @include m.pcOver {
                  padding: 20px;
                }

                @include m.sp {
                  padding: 1rem 1.2rem;
                }
              }
            }
          }
        }

        &Head {
          $this: &;
          width: 150px;
          padding: 1.5vw;
          font-weight: bold;
          background-color: v.$bluePale02;

          @include m.pcOver {
            padding: 20px;
          }

          @include m.sp {
            width: 32%;
            padding: 1rem;
          }

          &--type02 {
            @extend #{$this};
            text-align: left;
            background-color: v.$bluePale02;
            @include m.pc {
              width: 100%;
              max-width: 280px;
            }
          }
        }

        &Body {
          padding: 1.5vw 3vw;
          flex: 1;

          @include m.pcOver {
            padding: 20px 40px;
          }

          @include m.sp {
            padding: 1rem 2rem;
          }
        }

        &Inner {
          & + & {
            margin-top: 1em;
          }
        }

        &Indent {
          padding: 0 0 0 2em;

          @include m.sp {
            padding: 0 0 0 1.4rem;
          }
        }

        &Text {
          @include m.fz(14);
          line-height: 1.5;
          letter-spacing: 0.04em;

          @include m.sp {
            font-size: 1.4rem;
          }

          & + & {
            margin-top: 1vw;

            @include m.pcOver {
              margin-top: 12px;
            }

            @include m.sp {
              margin-top: 1rem;
            }
          }
        }

        &Pic {
          width: 100%;
          margin: 0.5vw 0;

          @include m.pcOver {
            margin: 10px 0;
          }

          @include m.sp {
            margin: 1rem 0;
          }

          img {
            max-width: 100%;
            width: auto;
          }
        }

        &Note {
          margin-top: 1em;

          &Text {
            padding: 0 0 0 1em;
            text-indent: -1em;
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: 0.04em;

            @include m.tab {
              font-size: 11px;
            }

            @include m.sp {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  &Inner {
    @include m.pc {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    & + & {
      margin: 10px 0 0;

      @include m.sp {
        margin: 1.5rem 0 0;
      }
    }

    &_head {
      margin: 0 20px 0 0;

      @include m.pcOver {
        margin: 0 20px 0 0;
      }

      @include m.pc {
        width: 9vw;
      }

      @include m.pcOver {
        width: 124px;
      }

      @include m.sp {
        margin: 0;
      }
    }

    &_body {
      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        width: 100%;
      }
    }
  }

  &Menu {
    width: 100%;

    @include m.pc {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &_list {
      width: 48%;

      @include m.pc {
        max-width: 435px;
        display: flex;
      }

      @include m.sp {
        width: 100%;
      }

      & + & {
        @include m.sp {
          margin-top: 4rem;
        }
      }

      &:nth-child(n + 3) {
        @include m.pc {
          margin-top: 3vw;
        }

        @include m.pcOver {
          margin-top: 40px;
        }
      }
    }

    &_link {
      width: 100%;

      @include m.pc {
        display: flex;
        transition: background-color v.$anime;

        &:hover {
          .c-infoMenu_contBody {
            background-color: v.$white;
          }
        }
      }
    }

    &_cont {
      width: 100%;

      @include m.pc {
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        &:hover {
          background-color: v.$white;
        }
      }

      &Head {
        width: 100%;
        @include m.fz(18);
        font-weight: bold;
        text-align: center;
        color: v.$white;
        background: v.$gradBlue03;

        @include m.pc {
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include m.sp {
          padding: 1rem 0;
          font-size: 1.6rem;
        }
      }

      &Body {
        width: 100%;
        padding: 1.2vw 1.5vw 3vw;
        background-color: v.$bluePale02;
        border-right: 2px solid v.$bluePale02;
        border-bottom: 2px solid v.$bluePale02;
        border-left: 2px solid v.$bluePale02;

        @include m.pc {
          min-height: 135px;
          flex: 1;
          position: relative;
          z-index: 1;
          transition: background-color v.$anime;
        }

        @include m.pcOver {
          padding: 20px;
        }

        @include m.sp {
          padding: 1.5rem;
        }
      }
    }

    &_note {
      &List {
        text-indent: -1em;
        padding: 0 0 0 1em;
      }
    }

    &_more {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      @include m.pc {
        position: absolute;
        right: 20px;
        bottom: 12px;
        z-index: 2;
      }

      @include m.pcOver {
        margin: 12px 0 0;
      }
    }
  }
}
