@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-flow {
  &Type {
    &01 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include m.sp {
        margin: 0 0 2.5rem;
      }

      &_box {
        width: 27%;

        @include m.pc {
          max-width: 140px;
        }
      }

      &_arrow {
        width: 15px;
        margin: 24px 18px 0;
        position: relative;
        z-index: 1;

        @include m.sp {
          width: 1.1rem;
          margin: 0 1rem;
        }

        &Text {
          @include m.fz(14);
          font-weight: bold;
          letter-spacing: 0.04em;
          position: absolute;
          top: calc(100% + 8px);
          left: calc(50% - 2px);
          z-index: 2;
          transform: translateX(-50%);
          white-space: nowrap;

          @include m.sp {
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  &Cont {
    width: 100%;
    position: relative;
    z-index: 1;

    &_logo {
      $this: &;
      width: 100%;
      height: 24px;
      margin: 0 0 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include m.tab {
        height: 18px;
      }

      &--long {
        @extend #{$this};
        height: 28px;
        margin: 0 0 4px;
      }

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }

    &_figure {
      position: relative;
      z-index: 1;

      &Balloon {
        width: 43px;
        position: absolute;
        top: 23%;
        left: 10%;
        z-index: 2;
        transform: translate(-50%, -50%);

        @include m.sp {
          width: 3.5rem;
          top: 21%;
          left: 12%;
        }
      }
    }

    &_text {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include m.fz(16);
      font-weight: bold;
      letter-spacing: 0.04em;
      text-align: center;
      color: v.$blue;
      position: absolute;
      top: calc(100% + 8px);
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);
      white-space: nowrap;

      @include m.sp {
        font-size: 1.2rem;
      }
    }
  }
}
