@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-clamp {
  &Text {
    &03 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
