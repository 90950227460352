@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-pic {
  &Box {
    width: 100%;
    position: relative;
    z-index: 1;

    &_cap {
      $this: &;
      width: 100%;
      position: absolute;
      bottom: 5px;
      left: 5px;
      z-index: 2;

      &--right {
        @extend #{$this};
        right: 5px;
        left: auto;
        text-align: right;
      }
    }
  }
}
