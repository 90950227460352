@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-breadcrumb {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  white-space: nowrap;

  @include m.sp {
    padding-bottom: 0.5rem;
  }

  &_list {
    @include m.fz(12);
    display: flex;
    align-items: center;
    line-height: 1;
    letter-spacing: 0.04em;
    color: v.$white;

    @include m.sp {
      margin: 0 0 1rem;
      font-size: 1.1rem;
      font-weight: bold;
    }

    & + & {
      &:before {
        content: '>';
        margin: 0 3px 0 4px;
        display: block;
        @include m.fz(12);
        line-height: 1;
        color: v.$white;

        @include m.sp {
          font-size: 1.1rem;
        }
      }
    }
  }

  &_item {
    display: block;
    font-size: inherit;

    @include m.sp {
      & * {
        text-decoration: underline;
      }
    }
  }
}
