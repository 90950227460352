@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-intro {
  $this: &;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  &--left {
    @extend #{$this};
    align-items: flex-start;
  }

  &--right {
    @extend #{$this};
    align-items: flex-end;
    text-align: right;

    .c-intro {
      &_cont {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-end;

        @include m.sp {
          text-align: left;
        }
      }
    }
  }

  &_head {
    width: 100%;
    margin: 0 0 2.2vw;

    @include m.pcOver {
      margin: 0 0 30px;
    }

    @include m.sp {
      margin: 0 0 2rem;
    }
  }

  &_heading {
    $this: &;
    padding: 0 0 1.2vw;
    display: inline-block;
    position: relative;
    z-index: 1;

    @include m.pcOver {
      padding: 0 0 16px;
    }

    @include m.sp {
      padding: 0 0 1.5rem;
    }

    &--blend {
      @extend #{$this};
      mix-blend-mode: screen;
    }

    &Text {
      @include m.fz(40);
      font-weight: bold;
      line-height: 1.1;
      letter-spacing: 0.04em;
      position: relative;
      z-index: 2;

      @include m.sp {
        font-size: 2.2rem;
      }
    }

    &Sub {
      $this: &;
      position: absolute;
      z-index: 1;
      transform: translate(-50%, -50%);

      &--works {
        @extend #{$this};
        width: 15.4vw;
        top: -22%;
        left: 19.8%;

        @include m.pc {
          max-width: 210px;
        }

        @include m.pcOver {
          width: 210px;
        }

        @include m.sp {
          width: 14.7rem;
          top: -37%;
          left: 34%;
        }
      }

      &--more {
        @extend #{$this};
        width: 9.9vw;
        top: -8%;
        left: 4%;

        @include m.pc {
          max-width: 135px;
        }

        @include m.pcOver {
          width: 135px;
        }

        @include m.sp {
          width: 8rem;
          top: -29%;
          left: 20%;
        }
      }
    }
  }

  &_lead {
    font-family: v.$oswald;
    @include m.fz(20);
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.08em;
    opacity: 0.6;

    @include m.sp {
      font-size: 1.4rem;
    }
  }

  &_text {
    @include m.fz(16);
    font-weight: 500;
    line-height: 2;
    letter-spacing: 0.04em;

    @include m.sp {
      font-size: 1.4rem;
      letter-spacing: 0;
    }
  }

  &_btn {
    width: 300px;
    margin: 40px 0 0;

    @include m.sp {
      width: 25rem;
      margin: 4rem auto 0;
    }
  }

  &Wrap {
    width: 100%;
    margin: 0 0 1.2vw;

    @include m.pcOver {
      margin: 0 0 16px;
    }

    @include m.sp {
      margin: 0 0 1.5rem;
    }
  }

  &Cont {
    width: 100%;

    &_heading {
      padding: 0 0 1.5vw;
      @include m.fz(20);
      font-weight: bold;
      line-height: 1.95;
      letter-spacing: 0.04em;
      text-align: center;

      @include m.pcOver {
        padding: 0 0 20px;
      }

      @include m.sp {
        padding: 0 0 1rem;
        font-size: 1.8rem;
        line-height: 1.8;
      }
    }

    &_box {
      width: 100%;

      @include m.pc {
        text-align: center;
      }
    }

    &_text {
      @include m.fz(16);
      font-weight: 500;
      line-height: 2;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1.4rem;
      }
    }

    &_btn {
      width: 300px;
      margin: 4.1vw auto 0;

      @include m.pcOver {
        margin: 56px auto 0;
      }

      @include m.sp {
        width: 25rem;
        margin: 4rem auto 0;
      }
    }
  }

  &Inner {
    $this: &;
    width: 100%;
    margin: 0 0 1.8vw;
    text-align: center;

    @include m.pcOver {
      margin: 0 0 24px;
    }

    @include m.sp {
      margin: 0 0 1.5rem;
    }

    &--small {
      @extend #{$this};
      margin: 0 0 1.5vw;

      @include m.pcOver {
        margin: 0 0 20px;
      }

      @include m.sp {
        margin: 0 0 2rem;
      }

      .c-introInner {
        &_heading {
          @include m.fz(25);

          @include m.sp {
            font-size: 1.5rem;
          }
        }

        &_lead {
          @include m.fz(16);

          @include m.sp {
            padding: 1rem 0 0;
            font-size: 1.3rem;
          }
        }
      }
    }

    &_cont {
      width: 100%;

      & + & {
        margin: 3vw 0 0;

        @include m.pcOver {
          margin: 40px 0 0;
        }

        @include m.sp {
          margin: 3rem 0 0;
        }
      }
    }

    &_heading {
      @include m.fz(30);
      font-weight: bold;
      line-height: 1.4666666666666666;
      letter-spacing: 0.04em;
      text-align: center;

      @include m.sp {
        font-size: 1.6rem;
      }
    }

    &_lead {
      padding: 1vw 0 0;
      @include m.fz(18);
      letter-spacing: 0.04em;

      @include m.pcOver {
        padding: 14px 0 0;
      }

      @include m.sp {
        padding: 1.5rem 0 0;
        font-size: 1.4rem;
      }
    }
  }

  &Wrap {
    & + & {
      margin-top: 7.3vw;

      @include m.pcOver {
        margin-top: 100px;
      }

      @include m.sp {
        margin-top: 5rem;
      }
    }
  }

  &Unit {
    margin: 0 0 3vw;

    @include m.pcOver {
      margin: 0 0 40px;
    }

    @include m.sp {
      margin: 0 0 2rem;
    }

    &Type {
      &01 {
        & + & {
          margin-top: 4.4vw;

          @include m.pcOver {
            margin-top: 60px;
          }

          @include m.sp {
            margin-top: 5rem;
          }
        }

        &_heading {
          margin-bottom: 1.5vw;

          @include m.sp {
            margin-bottom: 20px;
          }

          @include m.sp {
            margin-bottom: 1.5rem;
          }
        }

        &_lead {
          margin-bottom: 0.8vw;

          @include m.pcOver {
            margin-bottom: 10px;
          }

          @include m.sp {
            margin-bottom: 0.5rem;
          }
        }

        &_cont {
          & + & {
            margin-top: 2.2vw;

            @include m.pcOver {
              margin-top: 40px;
            }

            @include m.sp {
              margin-top: 4rem;
            }
          }
        }

        &_box {
          & + & {
            margin-top: 2.2vw;

            @include m.pcOver {
              margin-top: 30px;
            }

            @include m.sp {
              margin-top: 3rem;
            }
          }
        }

        &_inner {
          & + & {
            margin-top: 3vw;

            @include m.pcOver {
              margin-top: 40px;
            }

            @include m.sp {
              margin-top: 3rem;
            }
          }
        }

        &_btn {
          margin-top: 80px;

          @include m.pcOver {
            margin-top: 80px;
          }

          @include m.sp {
            margin-top: 6rem;
          }
        }
      }

      &02 {
        width: 100%;
        margin: 0 0 1.5vw;

        @include m.pcOver {
          margin: 0 0 20px;
        }

        @include m.sp {
          margin: 0 0 2rem;
        }

        &_head {
          margin: 0 0 0.5vw;

          @include m.pcOver {
            margin: 0 0 5px;
          }

          @include m.sp {
            margin: 0 0 1rem;
          }
        }

        &_body {
        }

        &_lead {
          letter-spacing: 0.08em;

          @include m.sp {
            padding: 1rem 0 0;
            line-height: 1.6;
          }
        }
      }
    }
  }

  &Heading {
    margin-bottom: 0.8vw;

    @include m.pcOver {
      margin-bottom: 10px;
    }

    @include m.sp {
      margin-bottom: 1rem;
    }
  }
}
