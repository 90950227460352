@use 'global/mixin' as m;
@use 'global/variable' as v;

@for $i from 1 through 20 {
  .u-pa#{$i*5} {
    padding: $i * 5px !important;
  }

  .u-pt#{$i*5} {
    padding-top: $i * 5px !important;
  }

  .u-pr#{$i*5} {
    padding-right: $i * 5px !important;
  }

  .u-pb#{$i*5} {
    padding-bottom: $i * 5px !important;
  }

  .u-pl#{$i*5} {
    padding-left: $i * 5px !important;
  }
}
