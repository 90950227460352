@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-text {
  &--bold {
    font-weight: bold;
  }

  &--indent {
    padding: 0 0 0 1em;
    text-indent: -1em;

    &Num {
      padding: 0 0 0 1.1em;
      text-indent: -1.1em;
    }
  }

  &--sup {
    vertical-align: super;
    font-size: smaller;
  }
}
