@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-section {
  &Type01 {
    &--top {
      padding: 40px 0 0;

      @include m.sp {
        padding: 4rem 0 0;
      }
    }
  }

  &Type02 {
    padding: 40px 0 120px;

    @include m.tab {
      padding: 20px 0 80px;
    }

    @include m.sp {
      padding: 4rem 0 6rem;
    }

    &--sp {
      &--btm {
        padding: 40px 0 120px;

        @include m.tab {
          padding: 20px 0 80px;
        }

        @include m.sp {
          padding: 0 0 6rem;
        }
      }
    }
  }

  &Type03 {
    padding: 120px 0;

    @include m.tab {
      padding: 80px 0;
    }

    @include m.sp {
      padding: 6rem 0;
    }

    &--btm {
      padding: 0 0 120px;

      @include m.tab {
        padding: 0 0 80px;
      }

      @include m.sp {
        padding: 0 0 6rem;
      }
    }
  }

  &Type04 {
    padding: 40px 0 80px;

    @include m.tab {
      padding: 20px 0 40px;
    }

    @include m.sp {
      padding: 4rem 0 5rem;
    }
  }
}
