@use 'global/mixin' as m;
@use 'global/variable' as v;
@use 'global/keyframes' as k;

.c-ring {
  svg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }

  &Wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    pointer-events: none;

    @include m.pc {
      max-width: 1366px;
    }
  }

  &Path {
    $this: &;
    fill: none;
    stroke: v.$bluePale;
    stroke-width: 1px;

    &--stroke {
      &--white {
        @extend #{$this};
        stroke: v.$white;
      }
    }
  }

  /* orbit（枠線） */
  &Orbit {
    $this: &;
    width: 100%;
    height: 100%;
    border: 1px solid v.$bluePale;
    border-radius: 50%;

    &--white {
      @extend #{$this};
      border-color: v.$white;
    }
  }
  /* end orbit（枠線） */

  /* asteroid（小さい球） */
  &Asteroid {
    width: 1.05vw;
    height: 1.05vw;
    background: v.$bluePale;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    transform: translate(-50%, -50%);
    // mix-blend-mode: screen;

    @include m.pcOver {
      width: 15px;
      height: 15px;
    }

    @include m.sp {
      width: 1.55vw;
      height: 1.55vw;
    }
  }
  /* end asteroid（小さい球） */

  /* sphere（橙&青球） */
  &Sphere {
    &--orange {
      $this: &;
      background: url('../img/common/obj_sphere_orange.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      z-index: 2;
      transform: translate(-50%, -50%);

      &01 {
        @extend #{$this};
        width: 4.3vw;
        height: 4.3vw;

        @include m.pcOver {
          width: 58px;
          height: 58px;
        }

        @include m.sp {
          width: 3rem;
          height: 3rem;
        }
      }

      &02 {
        @extend #{$this};
        width: 3.2vw;
        height: 3.2vw;

        @include m.pcOver {
          width: 44px;
          height: 44px;
        }
      }
    }

    &--blue {
      $this: &;
      background: url('../img/common/obj_sphere_blue.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      z-index: 2;
      transform: translate(-50%, -50%);

      &01 {
        @extend #{$this};
        width: 5.2vw;
        height: 5.2vw;

        @include m.pcOver {
          width: 71px;
          height: 71px;
        }
      }

      &02 {
        @extend #{$this};
        width: 2.8vw;
        height: 2.8vw;

        @include m.pcOver {
          width: 38px;
          height: 38px;
        }

        @include m.sp {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }
  /* end sphere（橙&青球） */

  &Index {
    /* TOP Hero */
    &Hero {
      $this: &;
      position: absolute;
      z-index: 4;
      transform: translate(-50%, -50%);

      &01 {
        @extend #{$this};
        width: 34.4vw;
        height: 32vw;
        top: -4%;
        left: 52%;
        opacity: 0;
        transition: opacity v.$anime;

        @include m.pcOver {
          width: 470px;
          height: 437px;
          left: 84.3%;
        }

        @include m.sp {
          // width: 21rem;
          // height: 19rem;
          // top: 7%;
          // left: 52%;
          display: none;
        }

        &.is-view {
          opacity: 1;
        }

        // c-ringHero01_orbit
        &_orbit {
          &01 {
            @include m.rotateAnime(30s);
          }
        }

        // c-ringHero01_obj 位置調整
        &_obj {
          &--num {
            &01 {
              width: 23.9vw;
              height: 21.9vw;
              position: absolute;
              bottom: -2.7vw;
              left: -3.5vw;
              z-index: 3;

              @include m.pcOver {
                width: 330px;
                height: 299px;
                bottom: -8.6%;
                left: -10.8%;
              }

              @include m.sp {
                width: 11rem;
                height: 10rem;
                bottom: 0;
                left: 0;
              }

              svg {
                transform: rotate(-180deg);
              }
            }

            &02 {
              width: 25.8vw;
              height: 23.9vw;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 2;

              @include m.pcOver {
                width: 352px;
                height: 328px;
              }

              @include m.sp {
                width: 16rem;
                height: 14.5rem;
              }
            }
          }
        }

        // c-ringHero01_sphere
        &_sphere {
          &--orange {
            top: 94.4%;
            left: -16.4%;
          }
        }

        // c-ringHero01_asteroid
        &_asteroid {
          top: 96.8%;
          left: 32.1%;
        }
      }

      &Common {
        @extend .c-ringIndexHero01;
        width: 34vw;
        height: 32vw;
        top: -10.3%;
        left: 58.2%;

        @include m.pcOver {
          width: 464px;
          height: 437px;
          top: -6.7%;
        }

        @include m.sp {
          top: -5%;
          left: 85%;
          transform: translate(-50%, -50%) scale(1.2);
        }

        &_obj {
          &--num {
            &01 {
              @extend #{$this};
              width: 20.5vw;
              height: 18.4vw;
              position: absolute;
              top: 79.5%;
              left: 6.54vw;
              z-index: 2;

              @include m.pcOver {
                width: 280px;
                height: 251px;
              }
            }

            &02 {
              width: 22.5vw;
              height: 22.1vw;
              position: absolute;
              top: 11%;
              right: 14.2%;
              z-index: 3;

              @include m.pcOver {
                width: 307px;
                height: 301px;
              }
            }
          }
        }

        &_sphere {
          &--orange {
            width: 2.7vw;
            height: 2.7vw;
            top: 94.5%;
            left: -15%;

            @include m.pcOver {
              width: 36px;
              height: 36px;
            }
          }
        }

        &_asteroid {
          width: 0.9vw;
          height: 0.9vw;
          top: 98.5%;
          left: 39%;
        }
      }
    }
    /* end TOP Hero */

    /* intro */
    &Intro {
      &01 {
        opacity: 0;
        transition: opacity v.$anime;
        &.is-view {
          opacity: 1;
        }
        &_obj {
          &--num {
            $this: &;
            position: absolute;
            z-index: 2;
            transform: translate(-50%, -50%);

            &01 {
              @extend #{$this};
              width: 17.6vw;
              height: 17.6vw;
              top: 50%;
              left: 50%;
              z-index: 3;

              @include m.pcOver {
                width: 240px;
                height: 240px;
              }
            }

            &02 {
              @extend #{$this};
              width: 33.8vw;
              height: 33.8vw;
              top: 87.6%;
              left: 43.9%;
              z-index: 3;

              @include m.pcOver {
                width: 461px;
                height: 461px;
              }

              svg {
                transform: rotate(15deg);
              }
            }

            &03 {
              @extend #{$this};
              width: 14.7vw;
              height: 14.7vw;
              top: 118.5%;
              left: 19.9%;

              @include m.pcOver {
                width: 200px;
                height: 200px;
              }

              svg {
                transform: rotate(180deg);
              }

              .c-ringIndexIntro01 {
                &_asteroid {
                  &01 {
                    top: 19%;
                    left: 30%;
                    z-index: 1;
                  }
                  &02 {
                    top: 34%;
                    left: 83%;
                    z-index: 1;
                  }
                }

                &_sphere {
                  &--orange {
                    top: 141.4%;
                    left: 70.8%;
                  }
                }
              }
            }
          }
        }

        &_orbit {
          &01 {
            .c-ringIndexIntro01 {
              &_sphere {
                &--orange {
                  top: 39.4%;
                  left: 47.8%;
                }
              }

              &_asteroid {
                top: 84.2%;
                left: 13%;
              }
            }
          }
        }
      }

      &02 {
        opacity: 0;
        transition: opacity v.$anime;
        &.is-view {
          opacity: 1;
        }
        &_obj {
          &--num {
            $this: &;
            position: absolute;
            z-index: 2;
            transform: translate(-50%, -50%);

            &01 {
              @extend #{$this};
              width: 23.4vw;
              height: 25.8vw;
              top: 49.5%;
              left: 49.4%;

              @include m.pcOver {
                width: 319px;
                height: 352px;
              }

              .c-ringIndexIntro02 {
                &_asteroid {
                  &01 {
                    top: 42%;
                    left: 85%;
                  }
                  &02 {
                    top: 55.2%;
                    left: 66.7%;
                  }
                }
              }
            }

            &02 {
              @extend #{$this};
              width: 14.3vw;
              height: 15.5vw;
              top: 78.2%;
              left: 86.4%;

              @include m.pcOver {
                width: 195px;
                height: 211px;
              }
            }
          }
        }
      }
    }
    /* end intro */

    /* works */
    &Works {
      &01 {
        opacity: 0;
        transition: opacity v.$anime;
        &.is-view {
          opacity: 1;
        }
        &_obj {
          &--num {
            $this: &;
            position: absolute;
            z-index: 2;
            transform: translate(-50%, -50%);

            &01 {
              @extend #{$this};
              width: 7.3vw;
              height: 6.9vw;
              top: 50%;
              left: 50%;
              z-index: 2;

              @include m.pcOver {
                width: 99px;
                height: 93px;
              }

              @include m.sp {
                width: 5.2rem;
                height: 4.9rem;
                top: auto;
                left: 0;
                bottom: 0;
                transform: none;
              }

              .c-ringIndexWorks01 {
                &_asteroid {
                  &01 {
                    top: 6%;
                    left: 28%;
                  }
                  &02 {
                    top: 85%;
                    left: 82%;
                  }
                }
              }
            }

            &02 {
              @extend #{$this};
              width: 22.7vw;
              height: 22vw;
              top: 32.7%;
              left: 68.6%;
              z-index: 3;

              @include m.pcOver {
                width: 310px;
                height: 300px;
              }

              @include m.sp {
                width: 12rem;
                height: 11.6rem;
                top: 77%;
                left: 22.5%;
              }

              .c-ringIndexWorks01 {
                &_sphere {
                  &--blue {
                    top: 37%;
                    left: 40%;

                    @include m.sp {
                      top: 36%;
                    }
                  }
                }

                &_asteroid {
                  &01 {
                    top: 17%;
                    left: 62.3%;
                    z-index: 1;
                  }
                  &02 {
                    top: 49%;
                    left: 87%;
                    z-index: 1;
                  }
                }
              }
            }

            &03 {
              @extend #{$this};
              width: 24.2vw;
              height: 24.2vw;
              top: -0.9%;
              left: 101.2%;
              z-index: 3;

              @include m.pcOver {
                width: 330px;
                height: 330px;
              }

              @include m.sp {
                width: 13.2rem;
                height: 14.9rem;
                top: 53%;
                left: 46.4%;
              }
            }

            &04 {
              @extend #{$this};
              width: 11.2vw;
              height: 10.4vw;
              top: 11%;
              left: 125%;

              @include m.pcOver {
                width: 152px;
                height: 142px;
              }

              .c-ringIndexWorks01 {
                &_asteroid {
                  &01 {
                    top: 0;
                    left: 47%;
                  }
                  &02 {
                    top: 88%;
                    left: 21%;
                  }
                }
              }
            }

            &05 {
              @extend .c-ringIndexWorks01_obj--num03;
              top: -0.2%;
              left: 100.1%;
              z-index: 3;
            }
          }
        }

        &_orbit {
          &01 {
            .c-ringIndexIntro01 {
              &_sphere {
                &--orange {
                  top: 39.4%;
                  left: 47.8%;
                }
              }

              &_asteroid {
                top: 84.2%;
                left: 13%;
              }
            }
          }
        }
      }
    }
    /* end works */

    /* business */
    &Business {
      &01 {
        opacity: 0;
        transition: opacity v.$anime;
        &.is-view {
          opacity: 1;
        }
        &_obj {
          &--num {
            $this: &;
            position: absolute;
            z-index: 2;
            transform: translate(-50%, -50%);

            &01 {
              @extend #{$this};
              width: 9vw;
              height: 8.3vw;
              top: 45%;
              left: 83%;
              z-index: 2;

              @include m.pcOver {
                width: 123px;
                height: 114px;
              }

              @include m.sp {
                width: 6.4rem;
                height: 5.8rem;
                top: 43%;
                left: auto;
                right: 0;
                transform: none;
              }

              .c-ringIndexBusiness01 {
                &_asteroid {
                  &01 {
                    top: 11%;
                    left: 81%;
                  }
                  &02 {
                    top: 99.5%;
                    left: 51%;
                  }
                }

                &_sphere {
                  &--blue {
                    top: 188%;
                    left: 51.5%;
                  }

                  &--orange {
                    top: 126%;
                    left: 164%;

                    @include m.sp {
                      left: 157%;
                    }
                  }
                }
              }
            }

            &02 {
              @extend #{$this};
              width: 20.7vw;
              height: 23.6vw;
              top: 34.7%;
              left: 68.2%;

              @include m.pcOver {
                width: 282px;
                height: 322px;
              }

              @include m.sp {
                width: 15rem;
                height: 16rem;
                top: 53%;
                left: 69%;
              }

              .c-ringWorks01 {
                &_sphere {
                  &--blue {
                    top: 37%;
                    left: 40%;
                  }
                }

                &_asteroid {
                  &01 {
                    top: 17%;
                    left: 62.3%;
                  }
                  &02 {
                    top: 49%;
                    left: 87%;
                  }
                }
              }
            }

            &03 {
              @extend #{$this};
              width: 13.7vw;
              height: 12.8vw;
              top: 69.6%;
              left: 30.7%;
              z-index: 1;

              @include m.pcOver {
                width: 187px;
                height: 174px;
              }

              @include m.sp {
                width: 9.6rem;
                height: 9rem;
                top: 87.2%;
                left: 33%;
              }

              .c-ringIndexBusiness01 {
                &_asteroid {
                  &01 {
                    top: 4%;
                    left: 66%;
                  }
                  &02 {
                    top: 49.5%;
                    left: 100%;
                  }
                }
              }
            }
          }
        }

        &_orbit {
          &01 {
            .c-ringIndexIntro01 {
              &_sphere {
                &--orange {
                  top: 39.4%;
                  left: 47.8%;
                }
              }

              &_asteroid {
                top: 84.2%;
                left: 13%;
              }
            }
          }
        }
      }
    }
    /* end business */

    /* news */
    &News {
      &01 {
        opacity: 0;
        transition: opacity v.$anime;
        &.is-view {
          opacity: 1;
        }
        &_obj {
          &--num {
            $this: &;
            position: absolute;
            z-index: 2;
            transform: translate(-50%, -50%);

            &01 {
              @extend #{$this};
              width: 5.9vw;
              height: 5.5vw;
              top: 86%;
              left: 11.5%;
              z-index: 2;

              @include m.pcOver {
                width: 80px;
                height: 75px;
              }

              .c-ringIndexNews01 {
                &_asteroid {
                  &01 {
                    top: 4%;
                    left: 29%;
                  }
                  &02 {
                    top: 82.5%;
                    left: 83%;
                  }
                }
              }
            }

            &02 {
              @extend #{$this};
              width: 19.7vw;
              height: 19vw;
              top: 67.7%;
              left: 32.2%;

              @include m.pcOver {
                width: 269px;
                height: 259px;
              }
            }

            &05 {
              @extend #{$this};
              width: 19.7vw;
              height: 19.2vw;
              top: 70.1%;
              left: 31.2%;
              transform: translate(-50%, -50%) rotate(180deg);

              @include m.pcOver {
                width: 269px;
                height: 262px;
              }
            }

            &03 {
              @extend #{$this};
              width: 14.8vw;
              height: 14.8vw;
              top: 35.8%;
              left: 67.3%;
              z-index: 1;

              @include m.pcOver {
                width: 202px;
                height: 202px;
              }

              .c-ringIndexNews01 {
                &_asteroid {
                  &01 {
                    top: 57%;
                    left: 0;
                  }
                }

                &_sphere {
                  &--blue {
                    top: 221%;
                    left: 37.8%;
                  }
                }
              }
            }

            &04 {
              @extend #{$this};
              width: 9vw;
              height: 8.4vw;
              top: 44.8%;
              left: 84%;
              z-index: 3;

              @include m.pcOver {
                width: 123px;
                height: 114px;
              }

              .c-ringIndexNews01 {
                &_asteroid {
                  &01 {
                    top: 10%;
                    left: 79%;
                  }
                  &02 {
                    top: 99.5%;
                    left: 46%;
                  }
                }
              }
            }
          }
        }

        &_orbit {
          &01 {
            .c-ringIndexIntro01 {
              &_sphere {
                &--orange {
                  top: 39.4%;
                  left: 47.8%;
                }
              }

              &_asteroid {
                top: 84.2%;
                left: 13%;
              }
            }
          }
        }
      }

      &02 {
        opacity: 0;
        transition: opacity v.$anime;
        &.is-view {
          opacity: 1;
        }
        &_obj {
          &--num {
            $this: &;
            position: absolute;
            z-index: 2;
            transform: translate(-50%, -50%);

            &01 {
              @extend #{$this};
              width: 23.4vw;
              height: 25.8vw;
              top: 49.5%;
              left: 49.4%;

              @include m.pcOver {
                width: 319px;
                height: 352px;
              }

              @include m.sp {
                width: 18rem;
                height: 18rem;
                top: 57%;
                left: 51%;
                z-index: 3;
              }

              .c-ringIndexNews02 {
                &_asteroid {
                  &01 {
                    top: 42%;
                    left: 85%;
                    @include m.sp {
                      top: 49.5%;
                      left: 83.5%;
                    }
                  }
                  &02 {
                    top: 55.2%;
                    left: 66.7%;
                    @include m.sp {
                      top: 78.5%;
                      left: 66.5%;
                    }
                  }
                }
              }
            }

            &02 {
              @extend #{$this};
              width: 14.3vw;
              height: 15.5vw;
              top: 78.2%;
              left: 86.4%;

              @include m.pcOver {
                width: 195px;
                height: 211px;
              }

              @include m.sp {
                width: 13rem;
                height: 13rem;
                top: 78.2%;
                left: 86.4%;
              }
            }
          }
        }
      }
    }
    /* end news */
  }

  /* estate */
  &Estate {
    &Index {
      &Intro {
        &_obj {
          &--num {
            $this: &;
            position: absolute;
            z-index: 2;
            transform: translate(-50%, -50%);

            &01 {
              @extend #{$this};
              width: 24.2vw;
              height: 24.2vw;
              top: 70.8%;
              left: 51%;
              z-index: 3;

              @include m.pcOver {
                width: 330px;
                height: 336px;
              }
            }

            &02 {
              @extend .c-ringEstateIndexIntro_obj--num01;
              top: 71.2%;
              left: 51.9%;
              transform: translate(-50%, -50%) rotate(70deg);
            }

            &03 {
              @extend #{$this};
              width: 12.3vw;
              height: 12.3vw;
              top: 25.2%;
              left: 56.5%;
              z-index: 3;

              @include m.pcOver {
                width: 167px;
                height: 167px;
              }

              .c-ringEstateIndexIntro {
                &_asteroid {
                  top: 84%;
                  left: 14.3%;
                }

                &_sphere {
                  &--blue {
                    top: 47%;
                    left: -36%;
                  }
                }
              }
            }
          }
        }
      }

      &Media {
        &01 {
          &_obj {
            &--num {
              $this: &;
              position: absolute;
              z-index: 2;
              transform: translate(-50%, -50%);

              &01 {
                @extend #{$this};
                width: 19.8vw;
                height: 19.8vw;
                top: 72.8%;
                left: 71%;

                @include m.pcOver {
                  width: 270px;
                  height: 270px;
                }
              }

              &02 {
                @extend .c-ringEstateIndexMedia01_obj--num01;
                top: 73.5%;
                left: 70%;
                transform: translate(-50%, -50%) rotate(-115deg);
              }

              &03 {
                @extend #{$this};
                width: 17.6vw;
                height: 17.6vw;
                top: 50%;
                left: 37.3%;
                z-index: 3;

                @include m.pcOver {
                  width: 240px;
                  height: 240px;
                }

                .c-ringEstateIndexMedia01 {
                  &_asteroid {
                    &01 {
                      top: 2%;
                      left: 66%;
                    }
                    &02 {
                      top: 60%;
                      left: 99%;
                    }
                    &03 {
                      top: 98.5%;
                      left: 63.6%;
                    }
                  }
                }
              }

              &04 {
                @extend #{$this};
                width: 14.8vw;
                height: 14.8vw;
                top: 18.8%;
                left: 31.4%;

                @include m.pcOver {
                  width: 202px;
                  height: 202px;
                }
              }
            }
          }
        }

        &02 {
          &_obj {
            &--num {
              $this: &;
              position: absolute;
              z-index: 2;
              transform: translate(-50%, -50%);

              &01 {
                @extend #{$this};
                width: 10.2vw;
                height: 10.2vw;
                top: 12.4%;
                left: 69.3%;
                z-index: 3;

                @include m.pcOver {
                  width: 139px;
                  height: 139px;
                }

                .c-ringEstateIndexMedia02 {
                  &_asteroid {
                    top: 37%;
                    left: 0;
                  }
                }
              }

              &02 {
                @extend #{$this};
                width: 31vw;
                height: 31vw;
                top: 36%;
                left: 54%;

                @include m.pcOver {
                  width: 423px;
                  height: 423px;
                }
              }

              &03 {
                @extend #{$this};
                width: 24.9vw;
                height: 24.9vw;
                top: 72%;
                left: 45%;

                @include m.pcOver {
                  width: 340px;
                  height: 340px;
                }
              }
            }
          }
        }
      }
    }
  }
  /* end estate */

  &Pos {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    pointer-events: none;

    @include m.pc {
      max-width: 1100px;
    }
  }

  /* 各種 */
  &Type {
    $this: &;
    display: block;

    @include m.sp {
      display: none;
    }

    &02 {
      @extend #{$this};
      width: 44%;

      @include m.pc {
        max-width: 480px;
      }
    }

    &03 {
      @extend #{$this};
      width: 32%;

      @include m.pc {
        max-width: 344px;
      }
    }

    &04 {
      @extend #{$this};
      width: 30%;

      @include m.pc {
        max-width: 320px;
      }
    }
  }
  /* end 各種 */
}

/* animation管理領域 */
.c-ring {
  &Index {
    &Hero {
      &01 {
        animation: circleMove01 5s ease-in-out infinite;
        &_sphere {
          &--orange {
            animation: asteroidMove04 7s ease-in-out infinite;
          }
        }
      }
    }
    &Intro {
      &01 {
        &_orbit {
          &01 {
            .c-ringIndexIntro01 {
              &_sphere {
                &--orange {
                  animation: asteroidMove01 5.5s ease-in-out 0.2s infinite;
                }
              }
            }
          }
        }

        &_obj {
          &--num {
            &03 {
              .c-ringIndexIntro01 {
                &_sphere {
                  &--orange {
                    animation: asteroidMove02 5s ease-in-out 0.5s infinite;
                  }
                }
              }
            }
          }
        }
      }

      &02 {
        &_obj {
          &--num {
            &02 {
              transform: translate(-50%, -50%) rotate(180deg);
            }
          }
        }

        &_asteroid {
          &02 {
            animation: asteroidMove03 6s ease-in-out infinite;
          }
        }
      }
    }

    &Works {
      &01 {
        &_obj {
          &--num {
            &05 {
              transform: translate(-50%, -50%) rotate(180deg);
            }
          }
        }

        &_sphere {
          &--blue {
            animation: asteroidMove02 5.5s ease-in-out 0.2s infinite;
          }
        }
      }
    }

    &Business {
      &01 {
        &_obj {
          &--num {
            &01 {
              .c-ringIndexBusiness01 {
                &_sphere {
                  &--blue {
                    animation: asteroidMove02 9s ease-in-out infinite;
                  }
                  &--orange {
                    animation: asteroidMove01 10s ease-in-out 0.5s infinite;
                  }
                }
              }
            }
          }
        }
      }
    }

    &News {
      &01 {
        &_obj {
          &--num {
            &03 {
              .c-ringIndexNews01 {
                &_sphere {
                  &--blue {
                    animation: asteroidMove03 9s ease-in-out infinite;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &Orbit {
  }
}
/* end animation管理領域 */
