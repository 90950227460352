@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-num {
  &Box {
    padding: 2.2vw;

    @include m.pcOver {
      padding: 30px;
    }

    @include m.sp {
      padding: 1.5rem;
    }

    &--inner {
      padding: 1.5vw 2.2vw;

      @include m.pcOver {
        padding: 20px 30px;
      }

      @include m.sp {
        padding: 1rem 1.5rem;
      }

      &Type {
        &02 {
          padding: 1.5vw;

          @include m.pcOver {
            padding: 20px;
          }

          @include m.sp {
            padding: 1rem;
          }
        }

        &03 {
          padding: 2.2vw 3vw;

          @include m.pcOver {
            padding: 30px 40px;
          }

          @include m.sp {
            padding: 1.5rem 1rem;
          }
        }
      }
    }

    &_heading {
      padding: 0 0 0.8vw;

      @include m.pcOver {
        padding: 0 0 10px;
      }

      @include m.sp {
        padding: 0 0 0.5rem;
        line-height: 1.5;
      }
    }
  }

  &List {
    $this: &;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & + & {
      margin: 1vw 0 0;
      padding: 1vw 0 0;
      border-top: 1px solid v.$grayPale02;

      @include m.pcOver {
        margin: 12px 0 0;
        padding: 12px 0 0;
      }

      @include m.sp {
        margin: 1rem 0 0;
        padding: 1rem 0 0;
      }
    }

    &--inner {
      @extend #{$this};

      & + & {
        margin: 0.8vw 0 0;
        padding: 0.8vw 0 0;

        @include m.pcOver {
          margin: 10px 0 0;
          padding: 10px 0 0;
        }

        @include m.sp {
          margin: 0.8rem 0 0;
          padding: 0.8rem 0 0;
        }
      }
    }

    &_head {
      width: 24px;
      margin: 0 10px 0 0;

      &Text {
        @include m.fz(20);
        font-weight: bold;
        line-height: 1.6;
        letter-spacing: 0.08em;
        font-family: v.$oswald;
        opacity: 0.6;

        @include m.sp {
          line-height: 1.4;
        }
      }
    }

    &_body {
      flex: 1;
    }
  }

  &Cont {
    width: 100%;

    & + & {
      margin: 1.5vw 0 0;
      padding: 1.5vw 0 0;
      border-top: 1px solid v.$grayPale02;

      @include m.pcOver {
        margin: 20px 0 0;
        padding: 20px 0 0;
      }

      @include m.sp {
        margin: 1.5rem 0 0;
        padding: 1.5rem 0 0;
      }
    }

    &_info {
    }

    &_intro {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include m.sp {
        margin: 0 0 0.5rem;
      }

      &Text {
        width: 25px;
        margin: 0 14px 2px 0;
        font-family: v.$oswald;
        @include m.fz(25);
        font-weight: 500;
        letter-spacing: 0.08em;
        opacity: 0.6;

        @include m.sp {
          width: 2rem;
          font-size: 2.3rem;
        }
      }

      &Heading {
        flex: 1;
      }
    }

    &_box {
    }

    &_inner {
      margin-top: 0.8vw;

      @include m.pcOver {
        margin-top: 12px;
      }

      @include m.sp {
        margin-top: 0.8rem;
      }

      & + & {
        margin-top: 0.9vw;

        @include m.pcOver {
          margin-top: 14px;
        }

        @include m.sp {
          margin-top: 1rem;
        }
      }
    }

    &_lead {
      padding: 0 0 5px;
      @include m.fz(18);
      font-weight: bold;
      line-height: 1.5555555555555556;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1.5rem;
      }
    }

    &_more {
      width: 100%;
      margin: 10px 0 0;
      display: block;

      @include m.pc {
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &Intro {
    width: 100%;
    margin: 0 0 12px;
    display: flex;
    align-items: flex-start;

    @include m.pcOver {
      margin: 0 0 12px;
    }

    @include m.sp {
      margin: 0 0 1rem;
    }

    &_head {
      $this: &;
      margin: 0 0.8vw 0 0;
      @include m.fz(40);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.08em;

      @include m.pcOver {
        margin: 0 13px 0 0;
      }

      @include m.tab {
      }

      @include m.sp {
        margin: 0 0.8rem 0 0;
        font-size: 3rem;
      }

      &--data {
        @extend #{$this};
        position: relative;
        z-index: 1;

        &:after {
          content: attr(data-text);
          @include m.fz(14);
          font-weight: bold;
          letter-spacing: 0.06em;
          text-align: center;
          color: inherit;
          position: absolute;
          bottom: 100%;
          left: 50%;
          z-index: 2;
          translate: -50% 0;

          @include m.sp {
            font-size: 1.2rem;
          }
        }
      }
    }

    &_body {
      padding: 0.4vw 0 0;
      line-height: 1.8 !important;

      @include m.pcOver {
        padding: 5px 0 0;
      }

      @include m.pc {
        flex: 1;
      }
    }
  }
}
