@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-label {
  $this: &;
  width: 70px;
  padding: 3px 0;
  @include m.fz(14);
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.08em;
  text-align: center;
  color: v.$white;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  position: absolute;
  top: 10px;
  left: -6px;
  z-index: 2;

  @include m.sp {
    width: 5.5rem;
    font-size: 1.2rem;
    top: 1rem;
    left: -6px;
  }

  &--before {
    @extend #{$this};
    font-family: v.$oswald;
    background-color: v.$bluePale03;

    &Large {
      @extend .c-label--before;
      width: 106px;
      padding: 6px 0;
      @include m.fz(20);
      letter-spacing: 0.08em;

      @include m.sp {
        font-size: 1.6rem;
      }
    }
  }

  &--after {
    @extend #{$this};
    font-family: v.$oswald;
    background: v.$gradGreen02;

    &Large {
      @extend .c-label--after;
      width: 106px;
      padding: 6px 0;
      @include m.fz(20);
      letter-spacing: 0.08em;

      @include m.sp {
        font-size: 1.6rem;
      }
    }
  }

  &--building {
    @extend #{$this};
    width: 110px;
    background: v.$greenDeep;
    clip-path: polygon(0 0, 100% 0, 94% 100%, 0 100%);

    &Detail {
      @extend .c-label--building;
      position: static;
      transform: none;
    }
  }

  &--land {
    @extend #{$this};
    width: 110px;
    background: v.$blue02;
    clip-path: polygon(0 0, 100% 0, 94% 100%, 0 100%);

    &Detail {
      @extend .c-label--land;
      position: static;
      transform: none;
    }
  }
}
