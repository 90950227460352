@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-sponsor {
  width: 100%;

  @include m.sp {
    padding: 3rem 0;
  }

  &_wrap {
    height: 100%;
    margin: 0 auto;

    @include m.pc {
      padding: 20px 0;
    }
  }

  &Cont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include m.pc {
      gap: 24px 0;
    }

    @include m.sp {
      flex-flow: column;
      justify-content: center;
    }

    & + & {
      padding: 40px 0 20px;
    }

    &_box {
      @include m.pc {
        // width: 50%;
      }

      & + & {
        @include m.sp {
          margin: 3rem 0 0;
        }
      }
    }

    &_num {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      white-space: nowrap;

      &Box {
        margin: 0 4px 0 0;
        &Text {
          &--sub {
            padding: 0 0 6px;
            font-size: 11px;
            line-height: 1;

            @include m.sp {
              font-size: 1.1rem;
            }
          }

          &--main {
            font-size: 21px;
            line-height: 1;
            letter-spacing: 0.05em;
            color: v.$blue;

            @include m.sp {
              font-size: 2rem;
            }
          }
        }
      }

      &Text {
        margin: 0 0 -3px;
        font-family: v.$oswald;
        font-size: 48px;
        font-weight: 300;
        line-height: 1;
        color: v.$blue;

        @include m.tab {
          font-size: 42px;
        }

        @include m.sp {
          font-size: 4rem;
        }

        &--small {
          font-size: 24px;
          font-size: bold;
          line-height: 1;
          color: v.$blue;

          @include m.sp {
            font-size: 2rem;
          }
        }
      }
    }

    &_logo {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include m.sp {
        gap: 2rem 0;
        justify-content: center;
        flex-wrap: wrap;
      }

      &Item {
        @include m.sp {
          width: 6.8rem;
        }

        & + & {
          margin: 0 0 0 34px;

          @include m.sp {
            margin: 0 0 0 3.4rem;
          }
        }

        & > a {
          position: relative;

          @include m.pc {
            &:after {
              content: '';
              @include m.opacity;
            }

            &:hover {
              &:after {
                opacity: 0.3;
              }
            }
          }
        }

        &--health {
          max-width: 100px;
          max-height: 100px;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &--expo {
          @include m.sp {
            width: 95%;
            margin: 0 !important;
          }
        }
      }
    }
  }
}
