@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-clip {
  &Type01 {
    width: 100%;
    height: 100%;
    background: v.$gradBlue;
    clip-path: polygon(0 19%, 100% 0, 100% 100%, 0 81%);

    @include m.sp {
      clip-path: polygon(0 4%, 100% 0, 100% 100%, 0 96%);
    }
  }

  &Type02 {
    $this: &;
    width: 100%;
    height: 100%;
    background: v.$gradBlue;
    clip-path: polygon(0 13.5%, 100% 0, 100% 100%, 0 100%);

    @include m.sp {
      clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
    }

    &--hero {
      @extend #{$this};
      clip-path: polygon(0 13.5%, 100% 0, 100% 100%, 0 100%);

      @include m.sp {
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  &Type03 {
    width: 100%;
    height: 100%;
    background: v.$gradBlue;
    clip-path: polygon(0 0, 100% 24.5%, 100% 100%, 0 100%);

    @include m.sp {
      clip-path: polygon(0 0, 100% 17%, 100% 100%, 0 100%);
    }
  }

  &Type04 {
    $this: &;
    width: 100%;
    height: 100%;
    background: v.$gradBlue;
    clip-path: polygon(0 0, 100% 92px, 100% 100%, 0 100%);

    @include m.tab {
      clip-path: polygon(0 0, 100% 62px, 100% 100%, 0 100%);
    }

    @include m.sp {
      clip-path: polygon(0 0, 100% 1%, 100% 100%, 0 100%);
    }

    &--bg {
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: v.$gradBlue;
        clip-path: polygon(0 0, 100% 92px, 100% 100%, 0 100%);

        @include m.tab {
          clip-path: polygon(0 0, 100% 62px, 100% 100%, 0 100%);
        }

        @include m.sp {
          clip-path: polygon(0 0, 100% 1%, 100% 100%, 0 100%);
        }
      }
    }
  }

  &Type05 {
    $this: &;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 100% 20%, 100% 75%, 0 100%);

    @include m.tab {
      clip-path: polygon(0 0, 100% 20%, 100% 80%, 0 100%);
    }

    @include m.sp {
      clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
    }
  }

  &Type06 {
    $this: &;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);

    @include m.sp {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 97%);
    }
  }
}
