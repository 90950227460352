@charset "UTF-8";

/* transition */
$anime: 0.3s ease;

/* color */
// white
$white: #fff;
$whitePale: #f7fafc;

// black
$black: #373737;
$blackPale: #484848;
$blackDeep: #000;

// gray
$gray: #707070;
$gray02: #ccc;
$grayPale: #a1a89e;
$grayPale02: #afafaf;
$grayPale03: #e6e6e6;
$grayPale04: #dddddd;
$grayPale05: #efeeee;
$grayPale05: #d6d6d6;

// blue
$blue: #2157be;
$blue02: #4e5cc4;
$blue03: #0068b7;
$blueGrad01: #1b50b2;
$blueGrad02: #1b5fb7;
$blueGrad03: #3798c2;
$blueGrad04: #35a4be;
$bluePale: #b8cdf4;
$bluePale02: #dee6f5;
$bluePale03: #7da2e8;

// aqua
$aqua: #039be5;

// yellow
$yellow: #f2ed79;
$yellowDeep: #fff100;

// green
$green: #5bb2d9;
$green02: #1ba38e;
$greenPale: #e0f2f1;
$greenDeep: #25a5d5;
$greenDeep02: #00897b;
$greenGrad01: #3ac8db;
$greenGrad02: #46cec4;
$greenGrad03: #2ed1ab;

// red
$red: #e50000;
$red02: #d32f2f;
$red03: #f01028;

// orange
$orange: #e6612f;
$orangePale: #e3be42;

// areaColor
$areaKyusyu: #3abcad;
$areaKansai: #729bea;
$areaHokuriku: #84c65b;
$areaShikoku: #289cc9;
$areaChugoku: #64c1dd;
$areaTokai: #8282af;
$areaKanto: #d69f38;

// gradient
$gradBlue: linear-gradient(to right top, #1b50b2 0%, #1b5fb7 30.54%, #3798c2 73.4%, #35a4be 100%);
$gradBlue02: linear-gradient(to bottom, #5ba2c7 0%, #1b9fb7 30.54%, #2790a7 67%, #2d899f 100%);
$gradBlue03: linear-gradient(to right, #1b50b2 0%, #1b5fb7 30.54%, #3798c2 73.4%, #35a4be 100%);
$gradBlue04: linear-gradient(to left top, #1b50b2 0%, #1b5fb7 30.54%, #3798c2 73.4%, #35a4be 100%);
$gradGreen: linear-gradient(to right, #3ac8db 0%, #46cec4 55.17%, #2ed1ab 100%);
$gradGreen02: linear-gradient(to bottom, #3ac8db 0%, #46cec4 55.17%, #2ed1ab 100%);
$gradOrange: linear-gradient(to top, #e6612f 0%, #e3be42 100%);
$gradOrangeR: linear-gradient(to right, #e6612f 0%, #e3be42 100%);

/* box-shadow */
$shadow01: 0px 3px 3px rgba($black, 0.1);

/* font-face */
@font-face {
  font-family: icon;
  src: url('../font/icon.eot');
  src: url('../font/icon.eot?#iefix') format('eot'), url('../font/icon.woff') format('woff'), url('../font/icon.ttf') format('truetype'), url('../font/icon.svg#icon') format('svg');
  font-weight: 400;
  font-style: normal;
}
/* end font-face */

/* font */
$notoSans: 'Noto Sans JP', sans-serif;
// $notoSerif: 'Noto Serif JP', serif;
$notoSerif: 'Noto Sans JP', sans-serif;
$oswald: 'Noto Sans JP', sans-serif;
// $oswald: 'Oswald', sans-serif;
