@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-card {
  &Cont {
    $this: &;
    width: 100%;

    @include m.pc {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &--down {
      @extend #{$this};

      .c-cardCont {
        &_box {
          &:nth-child(3n - 1) {
            @include m.pc {
              margin: 40px 0 0;
            }
          }

          &:nth-child(3n) {
            @include m.pc {
              margin: 80px 0 0;
            }
          }
        }
      }
    }

    &--top {
      @extend #{$this};

      & + .c-cardCont--top {
        @include m.sp {
          margin: 3rem 0 0;
        }
      }

      .c-cardCont {
        &_box {
          @include m.pc {
            &:nth-child(3n - 2) {
              margin: 54px 0 0;
            }

            &:nth-child(3n - 1) {
              margin: 15px 0 0;
            }

            &:nth-child(3n) {
              margin: -26px 0 0;
            }

            &:first-child {
              margin: 85px 0 0;
            }

            &:nth-of-type(2) {
              @include m.pc {
                margin: 44px 0 0;
              }
            }

            &:nth-of-type(3) {
              @include m.pc {
                margin: 0;
              }
            }
          }
        }
      }
    }

    &--wrap {
      @extend #{$this};

      @include m.pc {
        &:after {
          content: '';
          width: 31%;
          display: block;
          order: 1;
        }
      }

      .c-cardCont_box {
        &:nth-child(n + 4) {
          @include m.pcOver {
            margin-top: 40px;
          }

          @include m.pc {
            margin-top: 3vw;
          }
        }
      }
    }

    &_box {
      @include m.pc {
        width: 31%;
        max-width: 340px;
      }

      & + & {
        @include m.sp {
          margin: 5rem 0 0;
        }
      }
    }

    &_cap {
      margin: 0.5vw 0 0;

      @include m.pcOver {
        margin: 6px 0 0;
      }

      @include m.sp {
        margin: 0.5rem 0 0;
      }
    }
  }

  &Box {
    width: 100%;
    height: 100%;
    &_link {
      $this: &;
      display: block;
      position: relative;
      box-shadow: v.$shadow01;
      z-index: 1;

      @include m.pc {
        transition: transform v.$anime;

        &:after {
          content: '';
          @include m.opacity();
          pointer-events: none;
        }

        &:hover {
          transform: translateY(-5px);

          &:after {
            opacity: 0.3;
            pointer-events: auto;
          }
        }
      }

      &--scale {
        display: block;
        position: relative;
        z-index: 1;

        @include m.pc {
          .c-cardBox_pic {
            overflow: hidden;

            img {
              transition: transform v.$anime;
            }
          }

          &:hover {
            .c-cardBox_pic {
              img {
                transform: scale(1.1);
              }
            }
          }
        }

        .c-cardBox_pic {
          aspect-ratio: auto 400 / 280;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &--opacity {
        display: block;
        position: relative;
        z-index: 1;

        @include m.pc {
          .c-cardBox_pic {
            position: relative;
            z-index: 1;

            &:after {
              content: '';
              @include m.opacity();
              background-color: v.$blue;
            }
          }

          &:hover {
            .c-cardBox_pic {
              &:after {
                opacity: 0.2;
              }
            }
          }
        }
      }

      &--flex {
        @extend #{$this};

        @include m.pc {
          height: 100%;
          display: flex;
          flex-flow: column;
          justify-content: space-between;

          .c-cardBox_pic {
            height: 230px;

            img {
              height: 100%;
              object-fit: cover;
            }
          }

          .c-cardBox_inner {
            flex: 1;
          }
        }
      }

      &--nolink {
        @include m.pc {
          &:after {
            display: none;
          }
          &:hover {
            transform: translateY(0);
          }
        }
      }
    }

    &_pic {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    &_map {
      width: 100%;
      height: 199px;

      @include m.tab {
        height: 180px;
      }

      @include m.sp {
        height: 19rem;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    &_tag {
      margin: 20px 0 8px;
      padding: 6px 12px;
      display: inline-block;
      @include m.fz(14);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.04em;

      @include m.sp {
        margin: 2rem 0 1rem;
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
      }
    }

    &_text {
      @include m.fz(16);
      font-weight: 600;
      line-height: 1.75;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1.4rem;
      }
    }

    &_inner {
      $this: &;
      width: 100%;
      padding: 9% 9% 8.5%;
      background-color: v.$white;

      @include m.pcOver {
        padding: 30px 30px 28px;
      }

      @include m.sp {
        padding: 2rem 3rem;
      }

      &--gradBlue {
        @extend #{$this};
        background: v.$gradBlue;

        .c-cardBox_inner {
          &Heading,
          &Text,
          &More {
            color: v.$white;
          }
        }
      }

      &Heading {
        padding: 0 0 14px;
        @include m.fz(18);
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.04em;
        color: v.$blue;

        @include m.sp {
          padding: 0 0 1.5rem;
          font-size: 1.6rem;
        }
      }

      &Text {
        @include m.fz(14);
        line-height: 2;
        letter-spacing: 0.04em;

        @include m.sp {
          font-size: 1.4rem;
        }

        & + & {
          margin: 10px 0 0;

          @include m.sp {
            margin: 1rem 0 0;
          }
        }
      }

      &More {
        margin: 26px 0 0;

        @include m.sp {
          margin: 2rem 0 0;
        }
      }
    }
  }

  &Unit {
    width: 100%;
    height: 100%;

    &_link {
      height: 100%;
      position: relative;
      z-index: 1;

      @include m.pc {
        display: flex;
        flex-flow: column;

        .c-cardUnit {
          &_pic {
            &Inner {
              // &:after {
              //   content: '';
              //   @include m.opacity();
              // }
              img {
                transition: transform v.$anime;
              }
            }
          }
        }
        &:hover {
          .c-cardUnit {
            &_pic {
              &Inner {
                // &:after {
                //   opacity: 0.3;
                // }
                img {
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }

      @include m.sp {
        display: block;
      }

      .c-cardUnit {
        &_pic {
          &Inner {
            overflow: hidden;
          }

          img {
            transition: transform v.$anime;
          }
        }
      }
    }

    &_cat {
      width: 100%;
      margin: 10px 0 5px;
      text-align: left;

      @include m.pcOver {
        margin: 10px 0 5px;
      }

      @include m.sp {
        margin: 1rem 0 0.5rem;
      }

      &Tag {
        padding: 4px 20px;
        display: inline-block;
        @include m.fz(14);
        font-weight: 500;

        @include m.sp {
          font-size: 1.2rem;
        }
      }
    }

    &_pic {
      $this: &;
      width: 100%;
      position: relative;
      z-index: 1;

      &--cover {
        @extend #{$this};
        aspect-ratio: auto 490 / 400;

        .c-cardUnit_picInner {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &Type02 {
          @extend #{$this};
          aspect-ratio: auto 320 / 230;

          .c-cardUnit_picInner {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      &Inner {
        position: relative;
        z-index: 1;
      }

      &Logo {
        width: 100%;
        height: 80px;
        padding: 0 0.8vw 0.8vw;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        background: linear-gradient(to top, v.$white -50%, rgba(225, 225, 225, 0) 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;

        @include m.pcOver {
          padding: 0 10px 10px;
        }

        @include m.sp {
          padding: 0 0.5rem 0.5rem;
        }

        @include m.sp {
          height: 4rem;
        }

        &Item {
          &--appark {
            width: 100%;

            @include m.pc {
              max-width: 128px;
            }

            @include m.sp {
              height: 2.4rem;

              img {
                width: auto;
                height: 100%;
              }
            }
          }
          &--apgolf {
            width: 100%;

            @include m.pc {
              max-width: 241px;
            }

            @include m.sp {
              height: 2.4rem;

              img {
                width: auto;
                height: 100%;
              }
            }
          }
          &--apengei {
            width: 100%;
            max-width: 128px;

            @include m.pc {
              max-width: 128px;
            }

            @include m.sp {
              height: 2.4rem;

              img {
                width: auto;
                height: 100%;
              }
            }
          }
        }
      }
    }

    &_heading {
      padding: 0 0 4px;

      @include m.sp {
        padding: 0 0 0.1rem;
      }
    }

    &_inner {
      $this: &;
      margin: 1.5vw 0 0;

      @include m.pcOver {
        margin: 20px 0 0;
      }

      @include m.pc {
        flex: 1;
        position: relative;
        z-index: 1;
      }

      @include m.sp {
        margin: 1rem 0 0;
      }

      &--type02 {
        @extend #{$this};
        margin: 1vw 0 0;

        @include m.pcOver {
          margin: 12px 0 0;
        }

        @include m.sp {
          margin: 0.8rem 0 0;
        }
      }

      &Lead {
        @include m.fz(16);
        line-height: 1.75;
        letter-spacing: 0.04em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;

        @include m.sp {
          font-size: 1.3rem;
        }
      }
    }

    &_more {
      margin: 1.1vw 0 0;

      @include m.pc {
        position: absolute;
        top: 100%;
        right: 0;
      }
    }
  }
}
