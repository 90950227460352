@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-anime {
  &Fade {
    opacity: 0;
    transition: opacity 0.6s ease;

    &.is-animated {
      opacity: 1;
    }
  }

  &Up {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.4s ease, transform 0.4s ease;

    &.is-animated {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &Slide {
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.4s ease, transform 0.4s ease;

    &.is-animated {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
