@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-font {
  &--oswald {
    font-family: v.$oswald;
  }
  &--italic {
    font-style: italic;
  }
}
