@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-gr {
  &Hero {
    width: 100%;
    margin: -9vw 0 0;
    position: relative;
    z-index: 1;

    @include m.sp {
      margin: -7.5rem 0 0;
    }
  }

  &About {
    margin: 0 0 9.2vw;

    @include m.pcOver {
      margin: 0 0 120px;
    }

    @include m.sp {
      margin: 0 0 8rem;
    }

    &_intro {
      margin: 0 0 2.5vw;

      @include m.pcOver {
        margin: 0 0 30px;
      }

      @include m.sp {
        margin: 0 0 3rem;
      }
    }
  }

  &Badge {
    $this: &;
    width: 100%;
    margin: 0 0 4.5vw;

    @include m.pcOver {
      margin: 0 0 60px;
    }

    @include m.sp {
      margin: 0 0 3rem;
    }
  }

  &Media {
    margin: 0 0 6vw;

    @include m.pcOver {
      margin: 0 0 80px;
    }

    @include m.sp {
      margin: 0 0 4rem;
    }
  }

  &Ex {
    &_intro {
      margin: 0 0 1.5vw;

      @include m.pcOver {
        margin: 0 0 20px;
      }

      @include m.sp {
        margin: 0 0 2rem;
      }
    }
    &_cont {
      width: 100%;
      display: flex;

      @include m.sp {
        flex-wrap: wrap;
      }

      &List {
        width: 11%;
        @include m.pc {
          max-width: 115px;
        }
        @include m.sp {
          width: 31%;
          margin-right: 3.5%;
        }

        & + & {
          @include m.pc {
            margin: 0 0 0 8px;
          }
        }

        &:nth-child(3n) {
          @include m.sp {
            margin-right: 0;
          }
        }

        &:nth-child(n + 4) {
          @include m.sp {
            margin-top: 2rem;
          }
        }
      }

      &Pic {
        display: block;
      }

      &Cap {
        margin: 0.8vw 0 0;
        @include m.fz(14);
        line-height: 1.5;
        letter-spacing: 0.04em;
        text-align: center;

        @include m.pcOver {
          margin: 10px 0 0;
        }

        @include m.sp {
          margin: 0.8rem 0 0;
          font-size: 1.4rem;
        }
      }
    }
  }

  &Contact {
    &_intro {
      margin: 0 0 4vw;
      @include m.pcOver {
        margin: 0 0 56px;
      }
      @include m.sp {
        margin: 0 0 3rem;
      }
    }
  }
}
