@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-data {
  width: 100%;
  display: flex;
  align-items: flex-end;

  @include m.sp {
    width: 90%;
    justify-content: flex-end;
  }

  &_head {
    margin: 0 1.9vw 0 0;
    padding: 0.9vw 0 0.9vw 0.9vw;
    @include m.fz(20);
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.04em;
    color: v.$blue;
    background-color: v.$bluePale02;
    position: relative;
    z-index: 1;

    @include m.pcOver {
      margin: 0 25px 0 0;
      padding: 12px 0 12px 12px;
    }

    @include m.sp {
      margin: 0 2rem 0 0;
      padding: 0.8rem;
      font-size: 1.4rem;
    }

    &:after {
      content: '';
      width: 11px;
      height: 100%;
      background: url('../img/common/obj_fit_arrow.svg') no-repeat left center;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 2;

      @include m.tab {
        left: calc(100% - 1px);
      }

      @include m.sp {
        width: 0.8rem;
      }
    }

    @include m.pc {
      min-width: 258px;
    }
  }

  &_body {
    flex: 1;

    @include m.sp {
      text-align: right;
    }
  }

  &_text {
    @include m.fz(35);
    font-weight: bold;
    line-height: 1.1428571428571428;
    letter-spacing: 0.04em;

    @include m.sp {
      font-size: 2.5rem;
    }

    &--unit {
      @include m.fz(20);
      font-weight: bold;
      line-height: 2;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1.5rem;
      }
    }
  }
}
