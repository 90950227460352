@use 'global/mixin' as m;
@use 'global/variable' as v;

html {
  @include m.sp {
    font-size: calc(100vw / 37.5);
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
}

body {
  color: v.$black;
  font-family: v.$notoSerif;
  background-color: v.$whitePale;

  &.is-fixed {
    overflow: hidden;
  }
}

main {
  margin: 100px 0 0;
  overflow: hidden;

  @include m.tab {
    margin: 80px 0 0;
  }

  @include m.sp {
    margin: 7dvh 0 0;
  }
}

img {
  height: auto;
}

a {
  &[href^='tel:'] {
    @include m.pc {
      pointer-events: none;
    }
  }
}

sup {
  font-size: smaller;
  vertical-align: super;
}

.pc {
  @include m.pc {
    display: block;
  }

  @include m.sp {
    display: none;
  }
}

.sp {
  @include m.pc {
    display: none;
  }

  @include m.sp {
    display: block;
  }
}

/* js */
.simpleParallax {
  height: 100%;
}
/* end js */

/* datepicker */
.ui-datepicker {
  @include m.sp {
    font-size: 4vw;
  }
}
/* end datepicker */
