@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-nav {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include m.pc {
    max-width: 580px;
    margin: 0 auto;
  }

  @include m.sp {
    justify-content: space-between;
  }

  &_list {
    margin: 0 0 1.5vw;
    border-right: 1px solid v.$blue;

    @include m.pcOver {
      margin: 0 0 20px;
    }

    @include m.sp {
      width: 50%;
      margin: 0 0 2rem;

      &:nth-child(2n) {
        border-right: none;
      }
    }

    &:last-child {
      @include m.pc {
        border-right: none;
      }
    }
  }

  &_item {
    $this: &;
    padding: 6px 16px;
    @include m.fz(16);
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.04em;
    color: v.$blue;

    @include m.pc {
      cursor: pointer;
      white-space: nowrap;
    }

    @include m.sp {
      font-size: 1.4rem;
    }

    &.is-selected {
      .c-nav_itemInner {
        &:after {
          width: 100%;
        }
      }
    }

    &Inner {
      @include m.sp {
        text-decoration: underline;
      }
    }
  }
}
