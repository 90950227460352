@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-slider {
  &Column {
    &_cont {
      width: 100%;
      padding: 0 0 0 9.7%;
      position: relative;
      z-index: 1;

      @include m.sp {
        padding: 0 0 0 5rem;
      }

      .swiper {
        // overflow: visible;

        &-wrapper {
        }
        &-slide {
          width: 100%;

          @include m.pc {
            max-width: 400px;
          }
        }
      }
    }

    &_btn {
      width: 300px;
      margin: 4.1vw auto 0;

      @include m.pcOver {
        margin: 56px auto 0;
      }

      @include m.sp {
        width: 25rem;
        margin: 4rem auto 0;
      }
    }

    &_nav {
      $this: &;
      width: 32px;
      height: 32px;
      background-color: rgba(v.$white, 0.81);
      border: 2px solid transparent;
      border-radius: 50%;
      top: 36%;
      transform: translate(-50%, -50%);

      @include m.pc {
        transition: background-color v.$anime;

        &:hover {
          background-color: rgba(v.$white, 1);
        }
      }

      @include m.tab {
        width: 30px;
        height: 30px;
      }

      @include m.sp {
        width: 3rem;
        height: 3rem;
        top: 40%;
      }

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%) rotate(-45deg);

        @include m.tab {
          width: 9px;
          height: 9px;
        }
      }

      &.swiper-button-disabled {
        pointer-events: auto;
      }

      &--next {
        @extend #{$this};
        left: 12.1%;

        @include m.tab {
          left: 13.1%;
        }

        @include m.sp {
          left: 20%;
        }

        &:before {
          border-right: 1px solid v.$blue;
          border-bottom: 1px solid v.$blue;
          left: calc(50% - 2px);
        }

        &:after {
          display: none;
        }
      }

      &--prev {
        @extend #{$this};
        left: 7.9%;

        @include m.tab {
          left: 6.9%;
        }

        @include m.sp {
          left: 7%;
        }

        &:before {
          border-top: 1px solid v.$blue;
          border-left: 1px solid v.$blue;
          left: calc(50% + 2px);
        }

        &:after {
          display: none;
        }
      }
    }

    &_arrow {
      $this: &;
      @include m.pc {
        display: none;
      }
      @include m.sp {
        border: 1px solid v.$grayPale02;
        position: absolute;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
      }

      &--next {
        @extend #{$this};

        @include m.sp {
          right: -1rem;
          left: auto;
        }
      }

      &--prev {
        @extend #{$this};

        @include m.sp {
          right: auto;
          left: -1rem;
        }
      }
    }
  }
}
