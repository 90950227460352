@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-estate {
  /* 不動産利活用TOP */
  &Index {
    &Intro {
      width: 100%;
      margin: -12.1vw 0 2.6vw;
      padding: 13.2vw 0;
      position: relative;
      z-index: 1;

      @include m.pcOver {
        padding: 10.7vw 0 180px;
      }

      @include m.sp {
        margin: -4rem 0 4rem;
        padding: 8rem 0;
      }

      &_ring {
        width: 19.8vw;
        position: absolute;
        top: 25.8%;
        left: 96%;
        z-index: 2;
        transform: translate(-50%, -50%);

        @include m.pcOver {
          width: 270px;
        }

        @include m.sp {
          display: none;
        }
      }
    }

    &Media {
      position: relative;
      z-index: 1;

      &_ring {
        &01 {
          width: 23.4vw;
          position: absolute;
          top: 32%;
          left: -7%;
          z-index: 2;
          transform: translate(-50%, -50%);

          @include m.pcOver {
            width: 319px;
          }

          @include m.sp {
            display: none;
          }
        }

        &02 {
          width: 25.2vw;
          position: absolute;
          top: 132%;
          left: 107%;
          z-index: 2;
          transform: translate(-50%, -50%);

          @include m.pcOver {
            width: 344px;
          }

          @include m.sp {
            display: none;
          }
        }
      }
    }

    &Area {
      margin: 4.4vw 0;
      position: relative;
      z-index: 1;

      @include m.pcOver {
        margin: 60px 0;
      }

      @include m.sp {
        margin: 6rem 0;
      }
    }

    &Flow {
      margin: 8.4vw 0 0;

      @include m.pc {
        margin: 114px 0 0;
      }

      @include m.sp {
        margin: 6rem 0 0;
      }

      &_cont {
        width: 92%;
        margin: 0 auto;
        padding-top: 1.2vw;

        @include m.pc {
          max-width: 950px;
        }

        @include m.pcOver {
          padding-top: 16px;
        }
      }

      &_box {
        width: 100%;
      }

      &_pic {
        width: 85%;
        margin: 0 auto;
        padding: 0 0 5px;
        position: relative;
        z-index: 1;

        @include m.pc {
          max-width: 170px;
        }

        @include m.pcOver {
          padding: 0 0 12px;
        }

        @include m.sp {
          width: 65%;
        }

        &Inner {
          padding: 0 0 8px;
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            width: 1.5vw;
            height: 2.4vw;
            background: url('../img/common/obj_arrow_green.svg') no-repeat center;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: calc(100% + 15%);
            z-index: 2;
            transform: translateY(-50%);

            @include m.pcOver {
              width: 20px;
              height: 33px;
            }

            @include m.sp {
              display: none;
            }
          }
        }
      }

      &_heading {
        text-align: center;
      }

      &_num {
        width: 26%;
        position: absolute;
        top: 17%;
        left: 0;
        z-index: 2;
        transform: translateY(-50%);

        @include m.pc {
          max-width: 44px;
        }
      }

      &_slide {
        @include m.sp {
          position: relative;
          z-index: 1;
        }

        &Nav {
          &--next,
          &--prev {
            @include m.pc {
              display: none;
            }

            @include m.sp {
              width: 2.5rem;
              height: 3.4rem;
              background: url('../img/common/obj_arrow_green.svg') no-repeat center;
              background-size: contain;
              position: absolute;
              top: 27%;
              z-index: 2;
            }

            &.swiper-button-disabled {
              display: none;
            }
          }

          &--next {
            right: 0;
            transform: translateY(-50%);
          }

          &--prev {
            left: 0;
            transform: translateY(-50%) rotate(-180deg);
          }
        }

        &Item {
          &Cont {
            width: 100%;
            @include m.pc {
              display: flex;
              justify-content: center;
            }
          }
          &Box {
            width: 22%;

            @include m.pc {
              max-width: 200px;
            }

            & + & {
              @include m.pc {
                margin-left: 4%;
              }
            }

            &:last-child {
              .p-estateIndexFlow_pic {
                &Inner {
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    &Column {
      margin: 5vw 0 0;
      padding: 9.5vw 0 9vw;

      @include m.pcOver {
        margin: 68px 0 0;
        padding: 129px 0 122px;
      }

      @include m.sp {
        margin: 6rem 0 0;
        padding: 8rem 0 6rem;
      }

      &_inner {
        position: relative;
        z-index: 3;
      }
    }
  }
  /* end 不動産利活用TOP */

  /* 土地利活用 */
  &Land {
    &_ring {
      $this: &;
      position: absolute;
      z-index: -1;
      transform: translate(-50%, -50%);

      &01 {
        @extend #{$this};
        top: 106%;
        left: -11%;
      }

      &02 {
        @extend #{$this};
        top: 105.5%;
        left: 107%;
      }
    }

    &_media {
      @include m.sp {
        padding: 2rem 0 0;
      }
    }

    &_intro {
      margin: 0 0 8.8vw;

      @include m.pcOver {
        margin: 0 0 120px;
      }

      @include m.sp {
        margin: 0 0 6rem;
      }
    }
  }
  /* end 土地利活用 */

  /* 建物利活用 */
  &Building {
    &_ring {
      $this: &;
      position: absolute;
      z-index: -1;
      transform: translate(-50%, -50%);

      &01 {
        @extend #{$this};
        top: 206.6%;
        left: -5.6%;
      }
    }
  }
  /* end 建物利活用 */

  /* 利活用実績 */
  &Case {
    &_intro {
      margin: 0 0 2.4vw;
      position: relative;
      z-index: 1;

      @include m.pcOver {
        margin: 0 0 32px;
      }

      @include m.sp {
        margin: 0 0 2rem;
      }

      &Ring {
        position: absolute;
        top: 90%;
        left: -8.4%;
        z-index: 2;
        transform: translate(-50%, -50%);
      }
    }
    &_area {
      position: relative;
      z-index: 2;

      & + & {
        margin: 40px 0 0;
      }

      &Img {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 490 / 400;
        }
      }
    }
  }
  /* end 利活用実績 */

  /* 不動産利活用コラム一覧 */
  &Column {
    &_pick {
      margin: 4.7vw 0 0;

      @include m.pcOver {
        margin: 64px 0 0;
      }
    }

    &_box {
      &Item {
        display: none;

        &.is-view {
          display: block;
        }
      }
    }

    &_ring {
      position: absolute;
      top: 5%;
      left: 107%;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
  }
  /* end 不動産利活用コラム一覧 */

  /* 関連サービス */
  &Service {
    width: 100%;
    margin: 7.3vw 0 -14vw;
    position: relative;
    z-index: 0;

    @include m.pcOver {
      margin: 100px 0 -192px;
    }

    @include m.sp {
      margin: 6rem 0 -14rem;
    }

    &_ring {
      position: absolute;
      top: -17.7%;
      left: 107%;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
  }
  /* end 関連サービス */

  /* 戸建て分譲 */
  &House {
    &_cont {
      margin: 8.8vw 0 2.2vw;

      @include m.pcOver {
        margin: 120px 0 30px;
      }

      @include m.sp {
        margin: 6rem 0 2rem;
      }

      &Intro {
        margin: 0 0 20px;

        @include m.pcOver {
          margin: 0 0 20px;
        }

        @include m.sp {
          margin: 0 0 1rem;
        }
      }

      &View {
        width: 100%;
        margin: 0 auto 2.2vw;

        @include m.pc {
          max-width: 610px;
        }

        @include m.pcOver {
          margin: 0 auto 30px;
        }

        @include m.sp {
          margin: 0 auto 2rem;
        }
      }

      &Box {
        text-align: center;
      }

      &Heading {
        margin: 0 0 1.5vw;

        @include m.pcOver {
          margin: 0 0 20px;
        }

        @include m.sp {
          margin: 0 0 1.5rem;
        }
      }
    }

    &_media {
      width: 100%;
      margin: 0 0 6vw;

      @include m.pcOver {
        margin: 0 0 80px;
      }

      @include m.sp {
        margin: 0 0 6rem;
      }

      &Link {
        width: 100%;
        padding: 3vw;
        display: block;
        border: 2px solid v.$bluePale02;

        @include m.pc {
          transition: background-color v.$anime;

          &:hover {
            background-color: v.$white;

            .p-estateHouse_mediaLine {
              &:after {
                width: 100%;
              }
            }
          }
        }

        @include m.pcOver {
          padding: 30px;
        }

        @include m.sp {
          padding: 3rem 2rem 0;
        }
      }

      &Btn {
        @include m.pc {
          display: none;
        }

        @include m.sp {
          width: 25rem;
          margin: 2rem auto 0;
        }
      }

      &Heading {
        $this: &;

        &--koti {
          @extend #{$this};
          width: 100%;
          margin: 0 auto 2.2vw;

          @include m.pc {
            max-width: 317px;
          }

          @include m.pcOver {
            margin: 0 auto 30px;
          }

          @include m.sp {
            width: 75%;
            margin: 0 auto 3rem;
          }
        }
      }

      &Cont {
        width: 100%;
        display: flex;

        @include m.pc {
          justify-content: space-between;
          align-items: flex-start;
        }

        @include m.sp {
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        &Box {
          @include m.pc {
            flex: 1;
          }

          @include m.sp {
            order: 1;
          }
        }

        &Heading {
          display: inline-flex;
          align-items: center;
        }

        &Ico {
          width: 1.2vw;
          max-width: 18px;
          margin-left: 6px;

          @include m.sp {
            display: none;
          }
        }

        &Pic {
          margin: 0 0 0 3.7vw;

          @include m.pc {
            width: 44%;
            max-width: 450px;
          }

          @include m.pcOver {
            margin: 0 0 0 50px;
          }

          @include m.sp {
            margin: 0 0 2rem;
            order: 0;
          }
        }
      }
    }

    &_slide {
      width: 100%;

      @include m.sp {
        position: relative;
        z-index: 1;
      }

      &Nav {
        $this: &;
        @include m.pc {
          display: none;
        }
        @include m.sp {
          border: 1px solid v.$grayPale02;
          position: absolute;
          top: 50%;
          z-index: 2;
          transform: translateY(-50%);
        }

        &--next {
          @extend #{$this};

          @include m.sp {
            right: -1rem;
            left: auto;
          }
        }

        &--prev {
          @extend #{$this};

          @include m.sp {
            right: auto;
            left: -1rem;
          }
        }
      }
    }

    &_image {
      width: 100%;

      &Heading {
        margin: 0 auto 1.5vw;

        @include m.pc {
          max-width: 422px;
        }

        @include m.pcOver {
          margin: 0 auto 20px;
        }

        @include m.sp {
          width: 100%;
        }
      }

      &List {
        width: 100%;
        margin: 0 0 3vw;

        @include m.pcOver {
          margin: 0 0 40px;
        }

        @include m.sp {
          margin: 0 0 4rem;
        }

        &Cont {
          width: 100%;
          @include m.pc {
            display: flex;
            justify-content: space-between;
          }
        }

        &Box {
          @include m.pc {
            width: 31% !important;
            max-width: 340px;
            transform: none !important;
          }
        }
      }

      &Btn {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &Item {
          width: 15%;

          @include m.pc {
            max-width: 148px;
            cursor: pointer;
          }

          @include m.tab {
            width: 32%;
            max-width: none;
          }

          @include m.sp {
            width: 47%;
          }

          &:nth-child(n + 3) {
            @include m.sp {
              margin-top: 1.5rem;
            }
          }

          &:nth-child(n + 4) {
            @include m.tab {
              margin-top: 2.2vw;
            }
          }

          a {
            width: 100%;
            padding: 11px 20px 20px;
            display: block;
            @include m.fz(13);
            font-weight: bold;

            @include m.pcOver {
              padding: 11px 20px 20px;
            }
          }
        }
      }

      &View {
        width: 100%;
        padding: 1.8vw 5vw 2.4vw;
        background-color: v.$white;

        @include m.pcOver {
          padding: 32px 68px;
        }
      }
    }

    &_other {
      margin: 4.4vw 0 0;

      @include m.pcOver {
        margin: 60px 0 0;
      }

      @include m.tab {
        padding: 0 0 4.4vw;
      }

      @include m.sp {
        margin: 3rem 0 0;
      }

      &Btn {
        width: 64%;
        margin: 5.9vw auto 0;

        @include m.pc {
          max-width: 700px;
        }

        @include m.pcOver {
          margin: 80px auto 0;
        }

        @include m.sp {
          width: 100%;
          margin: 5rem auto 0;
        }
      }
    }

    &_bg {
      margin: 16vw 0 -23.4vw;
      position: relative;
      z-index: 0;

      @include m.pcOver {
        margin: 218px 0 -320px;
      }

      @include m.sp {
        margin: 14rem 0 -20rem;
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      transform: translate(-50%, -50%);

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 28%;
        top: 11.5%;
        left: -8%;
        z-index: -1;

        @include m.pc {
          max-width: 319px;
        }
      }

      &02 {
        @extend #{$this};
        width: 30%;
        top: 34.3%;
        left: 100.2%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }

      &03 {
        @extend #{$this};
        width: 41%;
        top: 99%;
        left: -1.5%;
        z-index: -1;

        @include m.pc {
          max-width: 480px;
        }
      }
    }
  }
  /* end 戸建て分譲 */
}
