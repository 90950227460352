@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-badge {
  $this: &;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  &--center {
    @extend #{$this};
    justify-content: center;

    @include m.sp {
      justify-content: space-around;
    }
  }

  &_list {
    $this: &;
    width: 20%;
    margin: 0 0 3vw;
    display: flex;
    flex-flow: column;
    align-items: center;
    @include m.pc {
      max-width: 210px;
    }
    @include m.pcOver {
      margin: 0 0 40px;
    }
    @include m.sp {
      width: 33%;
      margin: 0 0 3rem;
      justify-content: flex-start;
    }
    &--half {
      &--sp {
        @extend #{$this};

        @include m.pc {
          margin: 0 1vw;
        }

        @include m.pcOver {
          margin: 0 12px;
        }

        @include m.sp {
          width: 48%;
        }
      }
    }
    &--large {
      @extend #{$this};
      width: 23%;
      margin: 0;

      @include m.pc {
        max-width: 250px;
        margin: 0;
      }

      @include m.sp {
        width: 48%;
      }

      &:nth-child(n + 3) {
        @include m.sp {
          margin-top: 2rem;
        }
      }
    }
  }

  &_img {
    width: 67%;
    margin: 0 0 1vw;
    @include m.pc {
      max-width: 140px;
    }
    @include m.pcOver {
      margin: 0 0 12px;
    }
    @include m.sp {
      width: 82%;
      margin: 0 0 0.8rem;
    }
  }

  &_cap {
    @include m.fz(18);
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    color: v.$blue;

    @include m.pc {
      letter-spacing: 0.04em;
    }

    @include m.sp {
      font-size: 1.3rem;
    }
  }

  &_text {
    padding: 0.5vw 0 0;

    @include m.pcOver {
      padding: 6px 0 0;
    }

    @include m.sp {
      padding: 0.5rem 0 0;
    }
  }
}
