@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-hero {
  $this: &;
  width: 100%;
  position: relative;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 62.5%, 0 94.5%);

  @include m.tab {
    clip-path: polygon(0 0, 100% 0, 100% 62.5%, 0 97.5%);
  }

  @include m.sp {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  }

  &Lower {
    @extend #{$this};
    clip-path: polygon(0 0, 100% 0, 100% 62.6%, 0 100%);

    @include m.sp {
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    }
  }

  &Img {
    $this: &;
    width: 100%;
    height: 30vw;
    position: relative;
    z-index: 1;

    @include m.pc {
      max-height: 400px;
    }

    img {
      width: 100%;
      height: 110%;
      object-fit: cover;
      object-position: top;
    }

    @include m.sp {
      height: 18rem;
    }

    // &:after {
    //   content: '';
    //   width: 100%;
    //   height: calc(100% + 100px);
    //   background: linear-gradient(to right, #366db7 0%, rgba(31, 57, 105, 0) 100%);
    //   position: absolute;
    //   top: -100px;
    //   left: 0;
    //   z-index: 2;
    //   opacity: 0.5;
    //   mix-blend-mode: multiply;

    //   @include m.sp {
    //     height: calc(100% + 7vh);
    //     background: linear-gradient(to right, #3e71d1 0%, rgba(31, 57, 105, 0) 100%);
    //     top: -7vh;
    //   }
    // }

    &--grad {
      @extend #{$this};
      background: v.$gradBlue;

      @include m.pc {
        height: 24vw;
        max-height: 320px;
      }

      &:after {
        display: none;
      }

      & + .c-heroCont {
        padding: 6vw 3.6vw;
      }
    }

    &--contact {
      @include m.sp {
        height: 36rem;
      }
    }
  }

  &Cont {
    width: 100%;
    height: 100%;
    padding: 9.2vw 3.6vw 6vw;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    mix-blend-mode: screen;

    @include m.pc {
      max-width: 1200px;
    }

    @include m.pcOver {
      padding: 9.2vw 49px 81px;
    }

    @include m.sp {
      width: 90%;
      padding: 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
    }

    &_head {
      width: 100%;
      position: absolute;
      top: 46%;
      left: 3.6vw;
      transform: translateY(-50%);

      @include m.pcOver {
        margin: 0 0 100px;
      }

      @include m.sp {
        margin: 0;
        top: 48%;
      }
    }

    &_intro {
      width: 100%;
      margin: 0 0 1.8vw;
      position: relative;
      z-index: 1;

      @include m.pcOver {
        margin: 0 0 30px;
      }

      @include m.sp {
        margin: 0 0 1.5rem;
      }

      &Heading {
        @include m.fz(40);
        font-weight: bold;
        line-height: 1.1;
        letter-spacing: 0.04em;
        color: v.$white;

        @include m.tab {
          @include m.fz(36);
        }

        @include m.sp {
          font-size: 2.8rem;
          line-height: 1.4;
        }
      }

      &Copy {
        $this: &;
        width: 14.2vw;
        position: absolute;
        top: -32%;
        left: 5%;
        z-index: -1;
        transform: translate(-50%, -50%);

        @include m.pcOver {
          width: 194px;
        }

        @include m.sp {
          width: 10rem;
          left: 12%;
        }

        &--advantage {
          @extend #{$this};

          @include m.pc {
            width: 22vw;
            max-width: 289px;
            left: 8.2%;
          }

          @include m.sp {
            width: 20rem;
            left: 21%;
            top: -18%;
          }
        }

        &--company {
          @extend #{$this};

          @include m.pc {
            width: 13vw;
            max-width: 174px;
            left: 3.6%;
          }

          @include m.sp {
            width: 11rem;
            left: 8%;
            top: -10%;
          }
        }

        &--service {
          @extend #{$this};

          @include m.pc {
            width: 13vw;
            max-width: 115px;
            left: 2%;
          }

          @include m.sp {
            width: 9rem;
            left: 5%;
            top: -19%;
          }
        }
      }
    }

    &_lead {
      font-family: v.$oswald;
      @include m.fz(20);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.08em;
      color: v.$white;
      opacity: 0.6;

      @include m.sp {
        font-size: 1.6rem;
        opacity: 0.7;
      }
    }

    &_breadcrumb {
      width: 100%;
      padding: 20px 3.6vw 0;
      position: absolute;
      top: 0;
      left: 0;
      overflow-x: scroll;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      @include m.sp {
        padding: 1rem 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &Pos {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    pointer-events: none;

    @include m.pc {
      max-width: 1200px;
    }
  }

  &Ring {
    $this: &;
    width: 30%;
    position: absolute;
    top: -2%;
    left: 55.3%;
    z-index: 2;
    transform: translate(-50%, -50%);

    @include m.pc {
      max-width: 400px;
    }

    @include m.sp {
      width: 20rem;
      top: 30%;
      left: 102%;
    }

    &02 {
      @extend #{$this};
      width: 23%;
      top: 73%;
      left: 101.5%;
      z-index: -1;

      @include m.pc {
        max-width: 270px;
      }

      @include m.sp {
        display: none;
      }
    }

    &03 {
      @extend #{$this};
      top: 148.2%;
      left: -3.8%;
      z-index: -1;

      @include m.sp {
        display: none;
      }
    }

    &04 {
      @extend #{$this};
      top: 117%;
      left: -3.4%;
      z-index: 2;
    }

    &05 {
      @extend #{$this};

      @include m.pc {
        max-width: 330px;
        left: 43.5%;
      }

      @include m.tab {
        left: 40%;
      }

      @include m.sp {
        left: 105%;
      }
    }
  }
}
