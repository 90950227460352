@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-menu {
  @include m.sp {
    display: flex;
    flex-wrap: wrap;
  }

  &_list {
    display: flex;
    align-items: center;

    @include m.sp {
      width: 48%;
      justify-content: space-between;
      align-items: flex-start;
    }

    & + & {
      @include m.pc {
        margin-top: 1vw;
      }

      @include m.pcOver {
        margin-top: 12px;
      }
    }

    &:nth-child(n + 3) {
      @include m.sp {
        margin-top: 1.5rem;
      }
    }
  }

  &_item {
    $this: &;
    display: flex;
    align-items: center;

    @include m.sp {
      align-items: flex-start;
    }

    span {
      @include m.sp {
        line-height: 1.8 !important;
      }
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      margin: 2px 5px 0 0;
      display: block;
      border-style: solid;
      border-width: 4px 0 4px 6px;
      border-color: transparent transparent transparent v.$blue;

      @include m.sp {
        margin-top: 0.9rem;
      }
    }

    &--child {
      @extend #{$this};
      margin: 0.6vw 0 0 12px;
      &:before {
        display: none;
      }
    }
  }

  &Dot {
    margin-left: 1em;
    list-style: disc;
  }
}
