@use 'sass:math';
@use 'global/variable' as v;
@use 'global/keyframes' as k;

@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin pcOver($width: 1367px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin opacity() {
  width: 100%;
  height: 100%;
  background-color: v.$white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity v.$anime;
}

@mixin rotateAnime($seconds: 30s) {
  animation: sphereRotation $seconds linear infinite;
}

// モニタサイズ375pxを起点にしたvwのサイズ指定
@function vw($size, $viewport) {
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vw;
}

// 上記のvwを利用したフォントサイズの指定
@mixin fz($font_size: 10) {
  font-size: $font_size * 1px; //vw非対応の場合の保険

  @include pc {
    font-size: vw($font_size, 1366); //フォントをvw指定
  }

  @include pcOver {
    font-size: $font_size * 1px;
  }

  @include tab {
    font-size: vw($font_size, 1100); //フォントをvw指定
  }
}
