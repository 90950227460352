@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-zindex {
  &01 {
    position: relative;
    z-index: 1;
  }

  &02 {
    position: relative;
    z-index: 2;

    &--pc {
      @include m.pc {
        position: relative;
        z-index: 2;
      }
    }
  }

  &03 {
    position: relative;
    z-index: 3;

    &--pc {
      @include m.pc {
        position: relative;
        z-index: 3;
      }
    }

    &--sp {
      @include m.sp {
        position: relative;
        z-index: 3;
      }
    }
  }

  &04 {
    position: relative;
    z-index: 4;

    &--sp {
      @include m.sp {
        position: relative;
        z-index: 4;
      }
    }
  }
}
