@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-tag {
  $this: &;
  padding: 4px 8px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 1;
  letter-spacing: 0.04em;

  @include m.sp {
    font-size: 1rem;
    white-space: nowrap;
  }

  &--black {
    @extend #{$this};
    color: v.$white;
    background-color: v.$black;
  }

  &--blue {
    @extend #{$this};
    color: v.$white;
    background-color: v.$blue;
  }

  &--green {
    @extend #{$this};
    color: v.$white;
    background-color: v.$greenGrad02;
  }

  &--shinsa {
    @extend #{$this};
    border: 1px solid v.$black;
  }

  &Type02 {
    @extend #{$this};
    padding: 6px 20px;
    font-size: 14px;

    @include m.sp {
      font-size: 1.2rem;
    }

    &--blue {
      @extend .c-tagType02;
      color: v.$white;
      background-color: v.$blue;
    }
  }

  &Type03 {
    $this: &;
    padding: 3px 6px;
    display: inline-block;
    text-align: center;

    @include m.pcOver {
      padding: 3px;
    }

    &--green {
      @extend #{$this};
      color: v.$greenDeep02;
      background-color: v.$greenPale;
      border: 1px solid v.$greenDeep02;
    }

    &_fill {
      &--aqua {
        @extend #{$this};
        color: v.$white;
        background-color: v.$aqua;
      }
      &--red {
        @extend #{$this};
        color: v.$white;
        background-color: v.$red;
      }
    }
  }
}
