@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-color {
  &_text {
    font-size: inherit;

    &--white {
      color: v.$white !important;
    }

    &--black {
      color: v.$black !important;
    }

    &--red {
      color: v.$red !important;

      &02 {
        color: v.$red02 !important;
      }
    }

    &--green {
      color: v.$green02 !important;
    }

    &--blue {
      color: v.$blue !important;

      &Pale {
        &01 {
          color: v.$areaKansai !important;
        }
      }

      &Opacity {
        &01 {
          color: v.$blue;
          opacity: 0.4;
        }
      }
    }

    &Area {
      &--kyusyu {
        color: v.$areaKyusyu !important;
      }

      &--kansai {
        color: v.$areaKansai !important;
      }
    }
  }

  &_bg {
    &--white {
      background-color: v.$white !important;

      &Pale {
        background-color: v.$whitePale !important;
      }
    }

    &--black {
      background-color: v.$black !important;

      &Pale {
        background-color: v.$blackPale !important;
      }
    }

    &--gray {
      &Pale {
        &02 {
          background-color: v.$grayPale02 !important;
        }
        &04 {
          background-color: v.$grayPale04 !important;
        }
        &05 {
          background-color: v.$grayPale05 !important;
        }
      }
    }

    &--blue {
      background-color: v.$blue !important;

      &Pale {
        background-color: v.$bluePale02 !important;
      }
    }

    &--yellow {
      &Deep {
        background-color: v.$yellowDeep !important;
      }
    }

    &Area {
      &--kyusyu {
        background-color: v.$areaKyusyu !important;
      }

      &--kansai {
        background-color: v.$areaKansai !important;
      }
      &--tokai {
        background-color: v.$areaTokai !important;
      }
      &--hokuriku {
        background-color: v.$areaHokuriku !important;
      }
    }

    &Grad {
      &--blue {
        background: v.$gradBlue !important;

        &04 {
          background: v.$gradBlue04 !important;
        }
      }
    }
  }
}
