@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-rental {
  &Hero {
    width: 100%;
    margin: -9vw 0 0;
    position: relative;
    z-index: 1;

    @include m.sp {
      margin: -7.5rem 0 0;
    }
  }

  &About,
  &Badge {
    margin: 0 0 3.1vw;

    @include m.pcOver {
      margin: 0 0 42px;
    }

    @include m.sp {
      margin: 0 0 3rem;
    }
  }

  &Area {
    width: 100%;

    &_cont {
      @include m.pc {
        display: flex;
        justify-content: space-between;
      }

      &List {
        @include m.pc {
          width: 49%;
          max-width: 530px;
        }

        & + & {
          @include m.sp {
            margin-top: 4rem;
          }
        }
      }

      &Item {
        width: 100%;

        @include m.pc {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            @include m.opacity();
          }

          &:hover {
            &:after {
              opacity: 0.3;
            }
          }
        }
      }

      &Pic {
        @include m.pc {
          max-height: 280px;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &Box {
        padding: 2.1vw 2.2vw 1.5vw;
        background-color: v.$white;
        box-shadow: 0 3px 6px rgba(v.$blackDeep, 16%);

        @include m.pc {
          flex: 1;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
        }

        @include m.pcOver {
          padding: 28px 30px 20px;
        }

        @include m.sp {
          padding: 2rem;
        }
      }
    }

    &_intro {
      margin: 0 0 0.8vw;

      @include m.pcOver {
        margin: 0 0 10px;
      }

      @include m.sp {
        margin: 0 0 1rem;
      }

      &Main {
        color: v.$blue;
      }
    }

    &_inner {
      width: 100%;
      margin: 0 0 2.2vw;

      @include m.pcOver {
        margin: 0 0 30px;
      }

      @include m.sp {
        margin: 0;
      }
    }

    &_more {
      display: flex;
      justify-content: flex-end;
    }
  }

  &Feature {
    margin: -8.8vw 0 0;
    padding: 11.7vw 0;

    @include m.pcOver {
      margin: -120px 0 0;
      padding: 160px 0;
    }

    @include m.sp {
      margin: -8rem 0 0;
      padding: 12rem 0;
    }

    &_cont {
      margin: 0 0 4.5vw;
      position: relative;
      z-index: 1;

      @include m.pcOver {
        margin: 0 0 60px;
      }

      @include m.sp {
        margin: 0 0 5rem;
      }
    }

    &_point {
      & + & {
        margin: 6.1vw 0 0;

        @include m.pcOver {
          margin: 80px 0 0;
        }

        @include m.sp {
          margin: 4rem 0 0;
        }
      }
    }

    &_view {
      &Slide {
        @include m.sp {
          width: 100%;
        }
      }
    }
  }

  &View {
    width: 100%;
    display: flex;
    
    @include m.pc {
      justify-content: center;
    }

    &_list {
      width: 32%;
      @include m.pc {
        max-width: 340px;
      }
      & + & {
        @include m.pc {
          margin-left: 4px;
        }
      }
    }
  }

  &Bnr {
    width: 100%;
    background-color: v.$white;
    border: 3px solid v.$bluePale02;

    &_cont,
    &_pic {
      position: relative;
      z-index: 1;
    }

    &_box {
      width: 100%;
      padding: 3vw 0 3vw 3.2vw;
      position: relative;
      z-index: 1;

      @include m.pcOver {
        padding: 40px 0 40px 44px;
      }

      @include m.pc {
        max-width: 587px;
      }

      @include m.sp {
        padding: 2rem 1.2rem;
      }

      &Inner {
        @include m.pc {
          width: 80%;
          max-width: 420px;
        }
      }
    }

    &_pic {
      height: 100%;

      @include m.pc {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
      }

      div {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: right;
        }
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: url('../img/rental_eco/area/rental_gradient_white.png') no-repeat center;
        position: absolute;
        top: 0;
        left: -1px;
        z-index: 2;

        @include m.pc {
          max-width: 205px;
        }

        @include m.sp {
          background: none;
          background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #fff 100%);
          top: -3px;
          left: 0;
        }
      }
    }

    &_intro {
      @include m.sp {
        margin: 0 0 0.5rem;
      }
      &Sub {
        line-height: 1;
      }
    }

    &_tel {
      width: 100%;
      margin: 0 0 1vw;
      padding: 0.5vw 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: v.$blue;
      border-top: 1px solid v.$blue;
      border-bottom: 1px solid v.$blue;

      @include m.pcOver {
        margin: 0 0 12px;
        padding: 6px 0;
      }

      @include m.sp {
        margin: 0 0 1.3rem;
        padding: 1.2rem 0;
      }

      &Text {
        margin: 0 0.8vw 0 0;
        @include m.fz(20);
        font-weight: bold;
        line-height: 1.4;

        @include m.pcOver {
          margin: 0 10px 0 0;
        }

        @include m.sp {
          margin: 0 1rem 0 0;
          font-size: 1.6rem;
        }
      }

      &Num {
        @include m.fz(32);
        font-weight: bold;
        line-height: 1.25;

        @include m.sp {
          font-size: 2.4rem;
        }
      }
    }
  }

  &Info {
    &_cont {
      & + & {
        margin: 4.5vw 0 0;

        @include m.pcOver {
          margin: 60px 0 0;
        }

        @include m.sp {
          margin: 6rem 0 0;
        }
      }
    }

    &_table {
      margin: 0 0 2.5vw;

      @include m.pcOver {
        margin: 0 0 30px;
      }

      @include m.sp {
        margin: 0 0 3rem;
      }
    }

    &_map {
      margin: 0 0 4.5vw;

      @include m.pcOver {
        margin: 0 0 60px;
      }

      @include m.sp {
        margin: 0 0 3rem;
      }

      &Cont {
        & + & {
          margin: 1.2vw 0 0;

          @include m.pcOver {
            margin: 16px 0 0;
          }

          @include m.sp {
            margin: 1.5rem 0 0;
          }
        }
      }
      &View {
        height: 300px;

        @include m.tab {
          height: 199px;
        }

        @include m.sp {
          height: 38rem;
        }

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
