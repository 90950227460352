@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-index {
  /* hero */
  &Hero {
    width: 100%;
    height: 52vw;

    @include m.pcOver {
      max-height: 970px;
      height: 51vw;
    }

    @include m.pc {
      min-height: 595px;
      // max-height: 710px;
    }

    @include m.pcOver {
      max-height: none;
    }

    @include m.tab {
      height: 60vw;
      min-height: 100%;
    }

    @include m.sp {
      height: 59rem;
    }

    &_wrap {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      z-index: 1;

      &Inner {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);

        @include m.pc {
          max-width: 1366px;
        }

        @include m.pcOver {
          max-width: 647px;
          left: 37%;
        }
      }
    }

    &_intro {
      width: 48%;
      position: absolute;
      top: 39.7%;
      left: 32.6%;
      z-index: 3;
      transform: translate(-50%, -50%);
      mix-blend-mode: screen;

      @include m.pc {
        max-width: 647px;
      }

      @include m.pcOver {
        left: 37.5%;
      }

      @include m.sp {
        width: 89%;
        top: 47.5%;
        left: 50%;
      }

      &Heading {
        width: 100%;
        position: relative;
        z-index: 1;
      }

      &Copy {
        width: 43%;
        position: absolute;
        top: -33%;
        left: 14.5%;
        z-index: 2;
        transform: translate(-50%, -50%);
        @include m.pc {
          max-width: 278px;
        }
        @include m.sp {
          width: 47%;
          position: absolute;
          top: -35%;
          left: 18.5%;
          z-index: -1;
        }
        img {
          @include m.pc {
            opacity: 0.66;
          }
        }
      }
    }

    &_bg {
      $this: &;
      width: 100%;
      height: 100%;
      padding: 0 0 6% 9%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      clip-path: polygon(0 0, 68.7% 0, 102% 100%, 0% 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;

      @include m.pc {
        max-width: 770px;
      }

      @include m.pcOver {
        max-width: none;
        width: 400%;
        padding: 0;
        left: -90%;
        transform: translateX(-50%);
        clip-path: polygon(0 0, 86.5% 0, 102% 100%, 0% 100%);
      }

      @include m.sp {
        height: 56vh;
        padding: 0 2.5rem;
        top: auto;
        bottom: 1rem;
        clip-path: polygon(0 0%, 100% 10%, 100% 90%, 0% 100%);
      }

      &:after {
        content: '';
        width: 100vw;
        height: 100%;
        background: v.$gradBlue;
        position: absolute;
        top: 50%;
        left: 40%;
        z-index: -1;
        transform: translate(-50%, -50%);

        @include m.pc {
          max-width: 960px;
        }

        @include m.pcOver {
          max-width: none;
          left: 80%;
        }

        @include m.sp {
          left: 50%;
          background: linear-gradient(to right top, #1b50b2 0%, #1b5fb7 30.54%, #3798c2 73.4%, #35a4be 100%);
        }
      }
    }

    &_cont {
      width: 57%;
      height: 100%;
      padding: 0 0 6% 9%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // clip-path: polygon(0 0, 68.7% 0, 102% 100%, 0% 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      @include m.pc {
        max-width: 770px;
      }

      @include m.pcOver {
        max-width: none;
        width: 100%;
        padding: 0;
      }

      @include m.sp {
        width: 100%;
        padding: 0;
      }

      &Inner {
        width: 87%;
        padding: 62% 0 0;

        @include m.pc {
          max-width: 560px;
        }

        @include m.pcOver {
          padding: 42% 0 0;
        }

        @include m.sp {
          width: 100%;
          padding: 0 2rem;
          position: absolute;
          top: 67%;
          left: 50%;
          z-index: 2;
          transform: translate(-50%, -50%);
        }
      }

      &Text {
        padding: 0 0 3% 2%;
        @include m.fz(18);
        line-height: 1.7777777777777777;
        letter-spacing: 0.04em;
        color: v.$white;
        position: relative;
        z-index: 1;

        @include m.pcOver {
          padding: 0 0 5% 2%;
        }

        @include m.sp {
          padding: 6.5rem 0 1.5rem;
          font-size: 1.4rem;
        }

        &Inner {
          @include m.fz(20);
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: 0.04em;

          @include m.sp {
            font-size: 1.8rem;
          }
        }
      }

      &Body {
        width: 100%;

        @include m.sp {
          text-align: center;
        }
      }
    }

    &_menu {
      width: 100%;
      margin: 0 0 0 -1%;
      display: flex;
      justify-content: space-between;

      @include m.sp {
        margin: 0;
        flex-wrap: wrap;
      }

      &List {
        width: 9.2vw;
        height: 9.2vw;
        border-radius: 50%;

        @include m.pc {
          max-width: 125px;
          max-height: 125px;
        }

        @include m.sp {
          width: 48%;
          height: auto;
          border-radius: 100px;
        }

        &:nth-child(n + 3) {
          @include m.sp {
            margin: 1.1rem 0 0;
          }
        }

        &Item {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          position: relative;
          overflow: hidden;

          @include m.pc {
            flex-flow: column;

            .p-indexHero_menuListItemBg {
              &:before {
                transition: opacity v.$anime;
              }
            }

            .p-indexHero_menuListItemArrow {
              transition: transform v.$anime;
            }

            &:hover {
              .p-indexHero_menuListItemBg {
                &:after {
                  opacity: 0;
                }
              }

              .p-indexHero_menuListItemArrow {
                transform: translate(calc(-50% + 4px), -50%);
              }
            }
          }

          @include m.sp {
            height: auto;
            padding: 1.1rem 0;
            justify-content: center;
            border-radius: 100px;
          }

          &Bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            @include m.pc {
              opacity: 0.5;
              filter: drop-shadow(v.$shadow01);
              mix-blend-mode: color-dodge;

              &:after {
                transition: opacity v.$anime;
              }
            }

            @include m.sp {
              // background: linear-gradient(#20b6d1 0%, #4ba3ef 100%);
            }

            &:before,
            &:after {
              content: '';
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }

            &:before {
              content: '';
              z-index: -1;
              border-radius: 50%;
              background-color: v.$blueGrad02;
            }

            &:after {
              content: '';
              background: v.$gradBlue02;
              z-index: 0;

              @include m.sp {
                background: linear-gradient(#20b6d1 0%, #4ba3ef 100%);
                // opacity: 0.8;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
              }
            }
          }

          &Text {
            @include m.fz(14);
            font-weight: bold;
            line-height: 1;
            letter-spacing: 0.04em;
            color: v.$white;

            @include m.sp {
              order: 1;
              font-size: 1.4rem;
              position: relative;
              z-index: 1;
            }
          }

          &Ico {
            $this: &;
            width: 2.8vw;
            height: 2.8vw;
            margin: 8px auto 12px;
            mix-blend-mode: screen;
            opacity: 0.45;

            @include m.pcOver {
              width: 38px;
              height: 38px;
            }

            @include m.tab {
              margin: 4px auto 8px;
            }

            @include m.sp {
              margin: 0;
              order: 0;
              position: absolute;
              top: 50%;
              left: 16%;
              z-index: 2;
              transform: translate(-50%, -50%);
            }

            &--type01 {
              @extend #{$this};
              background: url('../img/common/ico_map.svg') no-repeat center bottom;
              background-size: 2.7vw;

              @include m.pcOver {
                background-size: 36px;
              }

              @include m.sp {
                width: 2rem;
                height: 2rem;
                background-size: contain;
              }
            }

            &--type02 {
              @extend #{$this};
              background: url('../img/common/ico_building.svg') no-repeat center bottom;
              background-size: 2.8vw 2.6vw;

              @include m.pcOver {
                background-size: 38px 35px;
              }

              @include m.sp {
                width: 1.7rem;
                height: 1.6rem;
                background-size: contain;
              }
            }

            &--type03 {
              @extend #{$this};
              background: url('../img/common/ico_house.svg') no-repeat center bottom;
              background-size: 2.8vw 2.6vw;

              @include m.pcOver {
                background-size: 38px 35px;
              }

              @include m.sp {
                width: 1.6rem;
                height: 1.6rem;
                background-size: contain;
              }
            }

            &--type04 {
              @extend #{$this};
              background: url('../img/common/ico_point.svg') no-repeat center bottom;
              background-size: 2.8vw 2.6vw;

              @include m.pcOver {
                background-size: 38px 35px;
              }

              @include m.sp {
                width: 1.2rem;
                height: 2rem;
                background-size: contain;
              }
            }
          }

          &Arrow {
            width: 1.8vw;
            position: absolute;
            top: 84%;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, -50%);

            @include m.pcOver {
              width: 24px;
            }

            @include m.sp {
              width: 1.4rem;
              top: 50%;
              left: 90%;
            }
          }
        }
      }
    }

    &_slide {
      width: 64%;
      height: 100%;
      position: relative;
      z-index: 1;

      @include m.pc {
        max-width: 1054px;
      }

      @include m.pcOver {
        max-width: none;
      }

      @include m.sp {
        width: 100%;
        height: 38rem;
        position: absolute;
        top: -7vh;
        left: 0;
      }

      .swiper {
        height: 100%;

        &-slide {
          height: 100%;

          @include m.pc {
            max-height: 730px;
          }

          @include m.pcOver {
            max-height: none;
          }

          @include m.sp {
            max-height: none;
          }
        }

        &-pagination {
          width: 90%;
          height: 1.05vw;
          bottom: 109px;
          right: 0;
          left: auto;

          @include m.pcOver {
            height: 15px;
            bottom: 9vw;
          }

          @include m.sp {
            display: none;
          }

          &-bullet {
            width: 1.05vw;
            height: 1.05vw;
            margin: 0 0.92vw !important;
            background-color: v.$white;
            opacity: 1;

            @include m.pcOver {
              width: 15px;
              height: 15px;
              margin: 0 12px !important;
            }

            &-active {
              background-color: v.$blue;
            }
          }
        }
      }

      img {
        height: 100%;
        object-fit: cover;
        object-position: right;

        @include m.pcOver {
          height: 100%;
        }

        @include m.sp {
          object-position: center;
        }
      }
    }
  }
  /* end hero */

  /* what is ntt ap? */
  &Intro {
    width: 100%;
    margin: -9.5% 0 0;
    position: relative;
    z-index: 1;

    @include m.pcOver {
      margin: -8vw 0 0;
    }

    @include m.tab {
      margin: -11% 0 0;
    }

    @include m.sp {
      margin: 0;
    }

    &_cont {
      width: 100%;
      padding: 11.7% 0 14.3%;
      background-color: v.$whitePale;
      position: relative;
      z-index: 1;
      clip-path: polygon(0% 15.5%, 100% 0%, 100% 99%, 0 83%);

      @include m.pcOver {
        padding: 8.4vw 0 14vw;
        clip-path: polygon(0% 15.5%, 100% 0%, 100% 99%, 0 79%);
      }

      @include m.sp {
        padding: 0 0 8rem;
        clip-path: polygon(0% 0, 100% 0%, 100% 92.6%, 0 83.3%);
      }

      &Inner {
        height: 100%;
        position: relative;
        z-index: 1;
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      z-index: 3;
      transform: translate(-50%, -50%);

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 20.5vw;
        height: 45.8vw;
        top: -11.5%;
        left: 95.7%;

        @include m.pcOver {
          width: 280px;
          height: 625px;
          top: 13%;
        }
      }

      &02 {
        @extend #{$this};
        width: 23.9vw;
        height: 23vw;
        top: 66.3%;
        left: 2.8%;

        @include m.pcOver {
          width: 326px;
          height: 313px;
        }

        @include m.tab {
          left: -5.5%;
        }
      }
    }

    &_head {
      width: 100%;
      margin: 0 0 32px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      @include m.sp {
        margin: 0;
      }

      &Logo {
        width: 61px;
        margin: 0 0 0.8%;

        @include m.pcOver {
          margin: 0 0 10px;
        }

        @include m.sp {
          width: 4.5rem;
          margin: 0 0 0.5rem;
        }
      }

      &Copy {
        padding: 0 0 14px;
        font-family: v.$oswald;
        @include m.fz(25);
        font-weight: 600;
        line-height: 1.8;
        letter-spacing: 0.08em;
        color: v.$blue;

        @include m.pcOver {
          padding: 0 0 12px;
        }

        @include m.sp {
          padding: 0 0 0.5rem;
          font-size: 1.8rem;
          line-height: 1;
          letter-spacing: 0.08em;
        }
      }

      &Title {
        padding: 0 0 10px;
        @include m.fz(52);
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: 0.039em;
        color: v.$blue;

        @include m.pcOver {
          margin: 0 0 10px;
        }

        @include m.sp {
          padding: 0 0 1rem;
          font-size: 2.5rem;
          line-height: 1.32;
          letter-spacing: 0;
        }
      }

      &Lead {
        @include m.fz(25);
        font-weight: 600;
        line-height: 1.76;
        letter-spacing: 0.04em;

        @include m.sp {
          display: none;
        }
      }
    }

    &_body {
      text-align: center;

      &Text {
        @include m.fz(18);
        line-height: 2;
        letter-spacing: 0.04em;

        @include m.sp {
          font-size: 1.4rem;
          line-height: 2;
          letter-spacing: 0.04em;
        }

        &Inner {
          @include m.fz(22);
          font-weight: bold;
          line-height: 1.6363636363636365;
          letter-spacing: 0.04em;
          color: v.$blue;
          position: relative;
          z-index: 1;

          @include m.sp {
            font-size: 1.55rem;
            font-weight: bold;
            line-height: 2;
            letter-spacing: 0;
          }

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            background: linear-gradient(transparent 82%, v.$yellow 0%);
            position: absolute;
            top: -2px;
            left: 49%;
            z-index: -1;
            transform: translateX(-50%);
          }

          &.pc {
            @include m.pc {
              display: inline;
            }
          }

          &.sp {
            @include m.sp {
              display: inline;
            }
          }
        }
      }
    }
  }
  /* end what is ntt ap? */

  /* OurWorks */
  &Works {
    width: 100%;
    margin: -129px 0 0;
    padding: 124px 0 195px;
    clip-path: polygon(0% 0, 100% 8.7vw, 100% 100%, 0 100%);
    position: relative;
    z-index: 1;

    @include m.pcOver {
      margin: -10vw 0 0;
      padding: 124px 0 14vw;
    }

    @include m.sp {
      margin: -19.5rem 0 0;
      padding: 12.5rem 0 10rem;
      clip-path: polygon(0% 0, 100% 8.7vw, 100% 98.5%, 0 100%);
    }

    &_img {
      width: 100%;
      height: 31.7vw;
      margin: -132px 0 0;
      position: relative;
      z-index: 1;

      @include m.sp {
        height: 24rem;
        margin: 0;
      }

      img {
        height: 100%;
        object-fit: cover;
        object-position: 16%;
      }
    }

    &_cont {
      & + & {
        margin: 80px 0 0;

        @include m.sp {
          margin: 6rem 0 0;
        }
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      z-index: 3;
      transform: translate(-50%, -50%);

      &01 {
        @extend #{$this};
        width: 37.1vw;
        height: 25.6vw;
        top: 38.5%;
        left: 72%;
        // left: 50%;

        @include m.pcOver {
          width: 507px;
          height: 350px;
        }

        @include m.tab {
          left: 79%;
        }

        @include m.sp {
          width: 26.7rem;
          height: 19.6rem;
          top: 30.5rem;
          left: 104%;
        }
      }
    }
  }
  /* end OurWorks */

  /* Business */
  &Business {
    margin: -130px 0 0;
    padding: 127px 0 0;
    clip-path: polygon(0% 8.7vw, 100% 0%, 100% 100%, 0 100%);
    background-color: v.$white;

    @include m.pcOver {
      margin: -10vw 0 0;
      padding: 10vw 0 0;
    }

    @include m.sp {
      margin: -3.5rem 0 0;
      padding: 6.5rem 0 0;
      clip-path: polygon(0% 1.5%, 100% 0%, 100% 100%, 0 100%);
    }

    &_cont {
      & + & {
        margin: 80px 0 0;

        @include m.sp {
          margin: 6rem 0 0;
        }
      }
    }

    &_img {
      margin: -148px 0 0;

      @include m.sp {
        width: 100%;
        height: 28.7rem;
        margin: -10rem 0 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      z-index: 3;
      transform: translate(-50%, -50%);

      &01 {
        @extend #{$this};
        width: 27.2vw;
        height: 20.7vw;
        top: 9.5%;
        left: 4.5%;

        @include m.pcOver {
          width: 371px;
          height: 283px;
        }

        @include m.sp {
          width: 19.1rem;
          height: 14.6rem;
          top: 0.4%;
          left: 6.5%;
        }
      }
    }
  }
  /* end Business */

  /* News */
  &News {
    margin: -120px 0 0;
    padding: 125px 0 370px;
    background: v.$gradBlue;
    clip-path: polygon(0% 8.7vw, 100% 0%, 100% 98.8%, 0 85.8%);

    @include m.pcOver {
      margin: -9vw 0 0;
      padding: 6.5vw 0 340px;
    }

    @include m.tab {
      padding: 125px 0 280px;
    }

    @include m.sp {
      margin: -4.5rem 0 0;
      padding: 5rem 0 19.5rem;
      clip-path: polygon(0% 8.7vw, 100% 0%, 100% 98.8%, 0 94%);
    }

    &_cont {
      & + & {
        margin: 16px 0 0;
      }
    }

    &_ring {
      $this: &;
      position: absolute;
      z-index: 3;
      transform: translate(-50%, -50%);

      &01 {
        @extend #{$this};
        width: 27.2vw;
        height: 20.7vw;
        top: 51.6%;
        left: 92.7%;

        @include m.pcOver {
          width: 371px;
          height: 283px;
        }

        @include m.sp {
          display: none;
        }
      }

      &02 {
        @extend .p-indexIntro_ring02;

        @include m.pc {
          display: none;
        }

        @include m.sp {
          width: 20rem;
          height: 19rem;
          top: 2.7%;
          left: -1%;
          display: block;
        }
      }
    }
  }

  &Panel {
    margin: -250px 0 120px;

    @include m.tab {
      margin: -200px 0 80px;
    }

    @include m.sp {
      margin: -11rem 0 8rem;
    }
  }
  /* end News */
}

/* animation管理領域 */
.p-index {
  &Intro {
    &_ring {
      &01 {
        animation: circleMove01 6s ease-in-out infinite;
      }
      &02 {
        animation: circleMove02 8s ease-in-out 0.5s infinite;
      }
    }
  }

  &Works {
    &_ring {
      &01 {
        animation: circleMove02 7s ease-in-out infinite;
      }
    }
  }

  &Business {
    &_ring {
      &01 {
        animation: circleMove03 4s ease-in-out infinite;
      }
    }
  }

  &News {
    &_ring {
      &01 {
        animation: circleMove03 5s ease-in-out infinite;
      }
    }
  }
}
/* end animation管理領域 */
