@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-contact {
  &Flow {
    width: 100%;

    @include m.pc {
      display: flex;
      justify-content: space-between;
    }

    img {
      @include m.sp {
        display: none;
      }
    }

    &_box {
      $this: &;
      width: 30%;

      @include m.pc {
        max-width: 270px;
      }

      @include m.sp {
        width: 100%;
        padding: 1rem 0;
        text-align: center;
        background-color: v.$bluePale02;
        position: relative;
        z-index: 1;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 1rem 1.5rem 0 1.5rem;
          border-color: v.$bluePale02 transparent transparent transparent;
          position: absolute;
          top: calc(100% - 1px);
          left: 50%;
          z-index: 2;
          transform: translateX(-50%);
        }
      }

      &--arrow {
        @extend #{$this};

        @include m.pc {
          width: 32%;
          max-width: 290px;
        }

        @include m.sp {
          margin-bottom: 1.5rem;
        }
      }

      &:last-child {
        @include m.sp {
          &:after {
            display: none;
          }
        }
      }

      &.is-active {
        @include m.sp {
          color: v.$white;
          background-color: v.$blue;

          &:after {
            border-color: v.$blue transparent transparent transparent;
          }
        }
      }

      &Text {
        @include m.pc {
          display: none;
        }

        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }
  }

  &Intro {
    width: 100%;
    margin: 0 0 3.5vw;

    @include m.pcOver {
      margin: 0 0 48px;
    }

    @include m.sp {
      margin: 0 0 3rem;
    }

    &_cont {
      width: 100%;

      & + & {
        margin: 2.2vw 0 0;

        @include m.pcOver {
          margin: 30px 0 0;
        }

        @include m.sp {
          margin: 2rem 0 0;
        }
      }
    }

    &_note {
      &List {
        text-indent: -1em;
        padding: 0 0 0 1em;
        letter-spacing: 0;
      }
    }
  }

  &Require {
    margin: 0 4px 6px;
    padding: 4px 6px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: v.$white;
    background-color: v.$blue;
    text-indent: 0;

    @include m.tab {
      margin: 0 4px 4px;
      font-size: 10px;
    }

    @include m.sp {
      font-size: 1rem;
    }
  }

  &Cont {
    width: 100%;

    &--confirm {
      width: 100%;
      @include m.fz(14);
      line-height: 2;

      @include m.sp {
        font-size: 1.4rem;
      }

      .p-contactInfo_bodyIntro {
        display: none;
      }

      .p-contactInfo_bodyCont {
        & + .p-contactInfo_bodyCont {
          padding: 0;
          border: none;
        }
      }

      .p-contactInfo--inner {
        & + .p-contactInfo--inner {
          margin-top: 0.8vw;

          @include m.pcOver {
            margin-top: 10px;
          }

          @include m.sp {
            margin-top: 2rem;
          }
        }
      }
    }

    &_box {
      width: 100%;

      & + & {
        margin: 4.4vw 0 0;

        @include m.pcOver {
          margin: 60px 0 0;
        }

        @include m.sp {
          margin: 4rem 0 0;
        }
      }
    }

    &_heading {
      width: 100%;
      padding: 0 0 0.9vw;
      @include m.fz(20);
      font-weight: bold;
      line-height: 2;
      color: v.$blue;

      @include m.pcOver {
        padding: 0 0 12px;
      }

      @include m.sp {
        padding: 0 0 1rem;
        font-size: 1.8rem;
        line-height: 1.6;
      }
    }
  }

  &Info {
    $this: &;
    width: 100%;
    padding: 2.2vw 0;
    border-top: 1px solid v.$grayPale02;

    @include m.pc {
      display: flex;
      justify-content: space-between;
    }

    @include m.pcOver {
      padding: 30px 0;
    }

    @include m.sp {
      padding: 1.5rem 0 2.2rem;
    }

    &:last-child {
      border-bottom: 1px solid v.$grayPale02;
    }

    &--box {
      @extend #{$this};
      margin-bottom: 2.5vw;
      padding: 0;
      display: block;
      border: none;

      @include m.pcOver {
        margin-bottom: 30px;
      }

      @include m.sp {
        margin-bottom: 2rem;
      }
    }

    &--start {
      @extend #{$this};
      align-items: flex-start;

      .p-contactInfo_head {
        height: auto;
        align-items: flex-start;
      }
    }

    &--inner {
      @extend #{$this};
      padding: 0;
      border: none;

      &:last-child {
        border: none;
      }

      & + .p-contactInfo--inner {
        margin-top: 2.5vw;

        @include m.pcOver {
          margin-top: 30px;
        }

        @include m.sp {
          margin-top: 2rem;
        }
      }

      &.is-disabled {
        & * {
          color: v.$grayPale02;
          pointer-events: none;
        }

        textarea {
          background-color: v.$grayPale05;
        }
      }
    }

    &--border {
      padding-bottom: 2vw;
      border-bottom: 1px solid v.$grayPale02;

      @include m.pcOver {
        padding-bottom: 26px;
      }

      @include m.sp {
        padding-bottom: 0.5rem;
      }
    }

    &_head {
      $this: &;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include m.pc {
        max-width: 230px;
        width: 26%;
        height: 50px;
        margin-right: 24px;
      }

      @include m.pcOver {
        margin-right: 34px;
      }

      @include m.tab {
        width: 25%;
        height: 40px;
      }

      @include m.sp {
        width: 100%;
        margin: 0 0 1.5rem;
      }

      &--inner {
        @extend #{$this};

        @include m.pc {
          max-width: 210px;
          width: 100%;
        }

        @include m.tab {
          max-width: 180px;
        }

        @include m.sp {
          margin: 0 0 0.8rem;
        }
      }

      &Text {
        margin-right: 10px;

        @include m.pcOver {
          margin-right: 10px;
        }

        &--small {
          display: block;
          font-weight: normal;
          line-height: 1;
        }
      }

      .p-contactRequire {
        margin-top: 6px;
      }
    }

    &_body {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;

      @include m.pc {
        flex: 1;
      }

      &Intro {
        height: 50px;
        margin: 0 0 20px;
        display: flex;
        align-items: center;

        @include m.tab {
          height: 40px;
        }
      }

      &Inner {
        width: 100%;
        margin-top: 28px;

        @include m.sp {
          margin-top: 2rem;
        }

        &:first-child {
          margin-top: 0;

          @include m.sp {
            margin-top: 0;
          }
        }

        p + .p-contactInfo_bodyInner {
          margin-top: 0;

          @include m.pcOver {
            margin: 0;
          }

          @include m.tab {
            margin-top: 0;
          }

          @include m.sp {
            margin-top: 0;
          }
        }

        &--border {
          width: 100%;
          margin-top: 28px;
          padding-top: 28px;
          border-top: 1px solid v.$grayPale02;

          @include m.sp {
            margin-top: 2rem;
            padding-top: 2rem;
          }
        }
      }

      &Cont {
        $this: &;
        width: 100%;

        & + & {
          margin-top: 30px;
          padding-top: 30px;
          border-top: 1px solid v.$grayPale02;

          @include m.sp {
            margin-top: 2rem;
            padding-top: 2rem;
          }
        }

        &--inner {
          @extend #{$this};

          & + & {
            margin-top: 20px;
            padding-top: 20px;
            border-top: none;

            @include m.sp {
              margin-top: 1rem;
              padding-top: 1rem;
            }
          }

          p + .p-contactInfo_bodyInner {
            margin-top: 0;

            @include m.pcOver {
              margin: 0;
            }

            @include m.tab {
              margin-top: 0;
            }

            @include m.sp {
              margin-top: 0;
            }
          }
        }
      }

      &Heading {
        @include m.fz(16);
        font-weight: 600;
        color: v.$blue;

        @include m.sp {
          font-size: 1.4rem;
        }
      }

      &Lead {
        padding: 0 0 8px;
        @include m.fz(16);
        font-weight: 500;

        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }

    &_calender {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &Input {
        flex: 1;
      }

      &Ico {
        width: 18px;
        height: 20px;
        margin: 0 0 0 1.2vw;

        @include m.pc {
          margin: 0 0 0 16px;
        }

        @include m.sp {
          width: 2.5rem;
          height: 2.5rem;
          margin: 0 0 0 1.5rem;
        }

        &:after {
          font-family: icon !important;
          speak: none;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;
          text-indent: 0;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: '';
          font-size: 20px;
          cursor: pointer;

          @include m.sp {
            font-size: 2.5rem;
          }
        }
      }
    }

    &_flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include m.sp {
        display: block;
      }

      &Head {
        width: 27%;
        margin: 0 2.5vw 0 0;
        @include m.pc {
          max-width: 160px;
        }
        @include m.pcOver {
          margin: 0 32px 0 0;
        }
        @include m.sp {
          width: 100%;
          margin: 0 0 1rem;
        }
      }

      &Body {
        @include m.pc {
          flex: 1;
        }
      }

      &Text {
        line-height: 1.5 !important;
      }
    }
  }

  &Form {
    &_textarea,
    &_input {
      &::placeholder {
        @include m.fz(16);
        line-height: 1.75;
        letter-spacing: 0.04em;
        color: v.$black;
        opacity: 0.5;

        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }

    &_textarea,
    &_input,
    &_selectItem {
      width: 100%;
      height: 50px;
      padding: 1.1vw 1.5vw;
      display: block;
      @include m.fz(16);
      line-height: 1.75;
      letter-spacing: 0.04em;
      color: v.$black;
      resize: none;

      @include m.pcOver {
        padding: 15px 20px;
      }

      @include m.tab {
        height: 40px;
      }

      @include m.sp {
        padding: 1rem;
        font-size: 1.4rem;
      }
    }

    &_textarea {
      height: 150px;
      background-color: v.$grayPale03;
      word-break: break-all;
    }

    &_input {
      background-color: v.$grayPale03;
    }

    &_radio {
      $this: &;
      width: 100%;
      display: flex;

      @include m.pc {
        height: 50px;
        align-items: center;
        cursor: pointer;
      }

      @include m.tab {
        height: 40px;
      }

      @include m.sp {
        justify-content: space-between;
        flex-wrap: wrap;
      }

      &--inner {
        @extend #{$this};
        @include m.pc {
          height: auto;
        }
      }

      &--wrap {
        @extend #{$this};
        flex-wrap: wrap;
        @include m.pc {
          height: auto;
        }

        .p-contactForm_radioList {
          margin-top: 0.7vw;
          margin-bottom: 1vw;

          @include m.pcOver {
            margin-top: 10px;
            margin-bottom: 10px;
          }

          @include m.sp {
            margin-top: 1rem;
            margin-bottom: 1rem;
          }
        }
      }

      &List {
        @include m.pc {
          margin-right: 3.2vw;
        }

        @include m.pcOver {
          margin-right: 40px;
        }

        @include m.sp {
          width: 50%;
        }

        &:nth-child(n + 3) {
          @include m.sp {
            margin-top: 1rem;
          }
        }
      }

      &Label {
        display: flex;
        align-items: center;

        &Input {
          display: none;

          &:checked + span {
            &:after {
              opacity: 1;
            }
          }
        }

        &Ico {
          width: 1.7vw;
          height: 1.7vw;
          margin: 2px 10px 0 0;
          background-color: v.$white;
          border: 1px solid v.$gray;
          border-radius: 50%;
          position: relative;
          z-index: 1;

          @include m.pcOver {
            width: 20px;
            height: 20px;
          }

          @include m.sp {
            width: 2rem;
            height: 2rem;
          }

          &:after {
            content: '';
            width: 0.8vw;
            height: 0.8vw;
            display: block;
            background-color: v.$blue;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, -50%);
            opacity: 0;

            @include m.pcOver {
              width: 10px;
              height: 10px;
            }

            @include m.sp {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }
    }

    &_select {
      width: 267px;
      appearance: none;
      position: relative;
      z-index: 1;

      &:before,
      &:after {
        content: '';
        position: absolute;
      }

      &:before {
        width: 100%;
        height: 100%;
        background-color: v.$grayPale03;
        top: 0;
        left: 0;
        z-index: -2;
      }

      &:after {
        width: 0.8vw;
        height: 0.8vw;
        border-right: 1px solid v.$black;
        border-bottom: 1px solid v.$black;
        top: calc(50% - 2px);
        right: 16px;
        z-index: -1;
        transform: translateY(-50%) rotate(45deg);

        @include m.pc {
          cursor: pointer;
        }

        @include m.pcOver {
          width: 10px;
          height: 10px;
        }

        @include m.sp {
          width: 0.8rem;
          height: 0.8rem;
        }
      }

      &Item {
        display: flex;
        align-items: center;
        line-height: 1;
      }
    }

    &_submit {
      @include m.pc {
        width: 100%;
        max-width: 560px;
        cursor: pointer;
      }

      @include m.sp {
        padding: 1.4rem 0;
      }

      &Text {
        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }
  }

  &Unit {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &_input {
      &Type {
        &01 {
          width: 95px;
          text-align: center;

          @include m.sp {
            width: 30%;
          }
        }

        &02 {
          width: 160px;

          @include m.sp {
            width: 50%;
          }
        }

        &03 {
          width: 100%;

          @include m.pc {
            max-width: 288px;
          }
        }

        &04 {
          width: 128px;

          @include m.sp {
            width: 75%;
          }

          @include m.tab {
            width: 90px;
          }
        }
      }
    }

    &_box {
      margin: 0 0 0 10px;
    }
  }

  &Note {
    margin-top: 8px;

    &_list {
    }
  }

  &Privacy {
    $this: &;
    width: 100%;
    margin: 6vw 0 0;

    @include m.pcOver {
      margin: 80px 0 0;
    }

    @include m.sp {
      margin: 5rem 0 0;
    }

    &--inner {
      @extend #{$this};
      margin: 2.5vw 0 0;

      @include m.pcOver {
        margin: 30px 0 0;
      }

      @include m.sp {
        margin: 3rem 0 0;
      }
    }

    &_wrap {
      margin-top: 80px;

      @include m.sp {
        margin-top: 6.4rem;
      }
    }

    &_cont {
      width: 100%;
      height: 270px;
      padding: 1.8vw 5px 2.2vw;
      background-color: v.$bluePale02;

      @include m.pcOver {
        padding: 24px 5px 30px;
      }

      @include m.sp {
        height: 30rem;
        padding: 1.5rem 5px;
      }

      &.is-large {
        height: 540px;

        @include m.sp {
          height: 48rem;
        }
      }

      &Inner {
        height: 100%;
        overflow-y: scroll;
        padding: 0 2.2vw;
        scrollbar-width: thin;
        scrollbar-color: v.$grayPale02 v.$bluePale02;

        @include m.pcOver {
          padding: 0 30px;
        }

        @include m.sp {
          padding: 0 2rem;
        }

        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          // background-color: #ccc;
        }
        &::-webkit-scrollbar-thumb {
          background-color: v.$grayPale02;
          border-radius: 10px;
        }
      }
    }

    &_heading {
      &--type {
        &01 {
          margin: 0 0 0.6vw;

          @include m.pcOver {
            margin: 0 0 8px;
          }

          @include m.sp {
            margin: 0 0 5px;
          }
        }

        &02 {
          margin: 1.2vw 0 0.6vw;
          color: v.$blue;

          @include m.pcOver {
            margin: 16px 0 8px;
          }

          @include m.sp {
            margin: 1.2rem 0 0.8rem;
          }
        }
      }
    }

    &_list {
      margin: 0 0 0 14px;
      list-style-type: decimal;

      &Item {
        margin: 1.2vw 0 0.6vw;

        @include m.pcOver {
          margin: 16px 0 8px;
        }

        @include m.sp {
          margin: 1.2rem 0 0.8rem;
        }

        &--indentType {
          &01 {
            margin: 0;
            text-indent: -1.6em;
            padding-left: 1.6em;
          }
          &02 {
            margin: 0;
            text-indent: -1em;
            padding-left: 1em;
          }
          &03 {
            margin: 0;
            text-indent: -1.8em;
            padding-left: 1.8em;
          }
        }
      }

      &--inner {
        margin: 0;
        list-style-type: none;
      }
    }

    &_link {
      &--mail {
        color: v.$blue;

        @include m.sp {
          & * {
            text-decoration: underline;
          }
        }
      }

      &--tel {
        @include m.pc {
          pointer-events: none;
        }
        @include m.sp {
          color: v.$blue;
          text-decoration: underline;
        }
      }
    }

    &_signature {
      margin: 1.5vw 0 0;
      text-align: right;

      @include m.pcOver {
        margin: 20px 0 0;
      }

      @include m.sp {
        margin: 1rem 0 0;
      }
    }

    &_btn {
      width: 100%;
      margin: 4.4vw 0 0;

      @include m.pc {
        display: flex;
        justify-content: center;
      }

      @include m.pcOver {
        margin: 60px auto 0;
      }

      @include m.sp {
        width: 100%;
        margin: 4rem auto 0;
      }

      &Item {
        width: 100%;

        @include m.pc {
          max-width: 300px;
        }

        & + & {
          margin: 0 0 0 2.2vw;

          @include m.pcOver {
            margin: 0 0 0 30px;
          }

          @include m.sp {
            margin: 3rem 0 0;
          }
        }
      }
    }

    &_separator {
      margin-top: 32px;
      padding-top: 32px;
      border-top: 1px dotted v.$black;

      @include m.sp {
        margin-top: 2.4rem;
        padding-top: 2.4rem;
      }
    }
  }

  &Error {
    &_lead {
      padding: 1.7vw;
      text-align: center;
      color: v.$red;
      border: 1px solid v.$red;

      @include m.pcOver {
        padding: 20px;
      }

      @include m.sp {
        padding: 2rem;
      }
    }

    &_text {
      padding-top: 1vw;
      line-height: 1;
      color: v.$red;

      @include m.pcOver {
        padding-top: 10px;
      }

      @include m.sp {
        padding-top: 1rem;
      }
    }
  }
}
