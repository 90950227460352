@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-post {
  width: 100%;
  margin: 0 auto;

  @include m.pc {
    max-width: 720px;
  }

  &_ring {
    position: absolute;
    top: 47.5%;
    left: 107%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }

  &Intro {
    width: 100%;
    margin: 0 0 2.4vw;

    @include m.pcOver {
      margin: 0 0 32px;
    }

    @include m.sp {
      margin: 0 0 3rem;
    }

    &_inner {
      margin: 0 0 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &_date {
      margin-right: 16px;
      @include m.fz(16);
      font-weight: 500;
      line-height: 1.75;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 1.4rem;
      }
    }

    &_tag {
      & + .c-postIntro_heading {
        margin-top: 1vw;

        @include m.pcOver {
          margin-top: 12px;
        }

        @include m.sp {
          margin-top: 1rem;
        }
      }
    }

    &_heading {
      @include m.fz(25);
      font-weight: bold;
      line-height: 2;
      letter-spacing: 0.04em;

      @include m.sp {
        font-size: 2rem;
      }
    }
  }

  &Main {
    width: 100%;

    img {
      max-width: 100%;
      width: auto;
      margin: 0 auto 4.4vw;
      position: relative;
      z-index: 3;

      @include m.pcOver {
        margin: 0 auto 60px;
      }

      @include m.sp {
        margin: 0 auto 4rem;
      }
    }
  }

  &Cont {
    & + .c-postMain {
      margin-top: 3vw;

      @include m.pcOver {
        margin-top: 40px;
      }

      @include m.sp {
        margin-top: 4rem;
      }
    }

    &_inner {
      & + & {
        margin: 4.4vw 0 0;

        @include m.pcOver {
          margin: 60px 0 0;
        }

        @include m.sp {
          margin: 4rem 0 0;
        }
      }
    }
  }

  &Btn {
    width: 300px;
    margin: 5.9vw auto 0;

    @include m.pcOver {
      margin: 80px auto 0;
    }

    @include m.tab {
      width: 240px;
    }

    @include m.sp {
      width: 25rem;
      margin: 6rem auto 0;
    }
  }

  /* 記事用パーツ */
  &Heading {
    &--type {
      &01 {
        margin: 4.4vw 0 1.5vw;
        padding: 5px 1.5vw;
        @include m.fz(20);
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 0.04em;
        color: v.$blue;
        background-color: rgba(v.$blue, 0.2);

        @include m.pcOver {
          margin: 60px 0 20px;
          padding: 5px 20px;
        }

        @include m.sp {
          margin: 4rem 0 1.5rem;
          padding: 0.5rem 1.5rem;
          font-size: 1.8rem;
        }
      }

      &02 {
        margin: 4.4vw 0 1.5vw;
        padding: 0 0 0 0.8vw;
        @include m.fz(18);
        font-weight: bold;
        line-height: 1.5555555555555556;
        letter-spacing: 0.04em;
        border-left: 5px solid v.$blue;

        @include m.pcOver {
          margin: 60px 0 20px;
          padding: 0 0 0 10px;
        }

        @include m.sp {
          margin: 4rem 0 1.5rem;
          padding: 0 0 0 1rem;
          font-size: 1.6rem;
          border-width: 2px;
        }
      }
    }
  }

  &Text,
  &Link {
    @include m.fz(16);
    line-height: 2;
    letter-spacing: 0.04em;

    @include m.sp {
      font-size: 1.4rem;
    }
  }

  &Text {
    & + .c-postLink {
      margin-top: 1.5vw;

      @include m.pcOver {
        margin-top: 20px;
      }

      @include m.sp {
        margin-top: 1.5rem;
      }
    }
  }

  &Link {
    display: inline-block;
    color: v.$blue;
    position: relative;
    z-index: 1;

    @include m.pc {
      &:before {
        content: '';
        width: 0;
        height: 1px;
        background-color: v.$blue;
        position: absolute;
        bottom: 3px;
        left: 0;
        z-index: 2;
        transition: width v.$anime;
      }

      &:hover {
        &:before {
          width: 100%;
        }
      }
    }

    @include m.sp {
      text-decoration: underline;
    }

    &[href$='.pdf'] {
      &:after {
        content: '';
        width: 1.3vw;
        height: 1.3vw;
        margin: 0 0 -0.2vw;
        display: inline-block;
        background: url('../img/common/ico_pdf.svg') no-repeat center;
        background-size: contain;

        @include m.sp {
          width: 1.6rem;
          height: 1.6rem;
          margin: 0 0 -0.3rem;
        }
      }
    }

    & + .c-postText {
      margin-top: 1.5vw;

      @include m.pcOver {
        margin-top: 20px;
      }

      @include m.sp {
        margin-top: 1.5rem;
      }
    }
  }

  &Img {
    max-width: 100%;
    width: auto;
    margin: 4.4vw auto 2.4vw;
    display: block;

    @include m.pcOver {
      margin: 60px auto 30px;
    }

    @include m.sp {
      margin: 4rem auto 2rem;
    }

    & + .c-postImg {
      margin-top: 6.4vw;

      @include m.pcOver {
        margin-top: 80px;
      }

      @include m.sp {
        margin-top: 6rem;
      }
    }
  }
  /* end 記事用パーツ */
}
