@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-text {
  $this: &;
  @include m.fz(16);
  line-height: 2;
  letter-spacing: 0.04em;

  @include m.sp {
    font-size: 1.4rem;
  }

  &--medium {
    @extend #{$this};
    font-weight: 500;
  }

  &--bold {
    @extend #{$this};
    font-weight: bold;
  }

  &--l {
    $this: &;
    @include m.fz(20);
    line-height: 2;
    letter-spacing: 0.04em;

    @include m.sp {
      font-size: 1.6rem;
    }

    &--medium {
      @extend #{$this};
      font-weight: 500;
    }

    &--bold {
      @extend #{$this};
      font-weight: bold;
    }
  }

  &--m {
    $this: &;
    @include m.fz(18);
    line-height: 2;
    letter-spacing: 0.04em;

    @include m.sp {
      font-size: 1.6rem;
    }

    &--medium {
      @extend #{$this};
      font-weight: 500;
    }

    &--bold {
      @extend #{$this};
      font-weight: bold;
    }
  }

  &--s {
    $this: &;
    @include m.fz(14);
    line-height: 2;
    letter-spacing: 0.04em;

    @include m.sp {
      font-size: 1.2rem;
    }

    &--medium {
      @extend #{$this};
      font-weight: 500;
    }

    &--bold {
      @extend #{$this};
      font-weight: bold;
    }

    &--sp {
      @extend #{$this};

      @include m.sp {
        font-size: 1.4rem;
      }
    }
  }

  &--xs {
    $this: &;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.04em;

    @include m.tab {
      font-size: 11px;
    }

    @include m.sp {
      font-size: 1.1rem;
    }

    &--medium {
      @extend #{$this};
      font-weight: 500;
    }

    &--bold {
      @extend #{$this};
      font-weight: bold;
    }
  }
}
