@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-eco {
  &Index {
    width: 100%;
    margin: -13vw 0 3vw;
    padding: 4vw 0 0;
    position: relative;
    z-index: 1;

    @include m.pcOver {
      margin: -220px 0 40px;
      padding: 53px 0 0;
    }

    @include m.sp {
      margin: -18rem 0 4rem;
      padding: 12rem 0 0;
    }

    &_hero {
      width: 26%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      translate: -50% -50%;
      z-index: 2;

      @include m.pc {
        max-width: 342px;
      }

      @include m.sp {
        width: 65%;
        top: 62%;
      }

      &Bg {
        @include m.sp {
          height: 35rem;

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &_cont {
      margin: 0 0 6vw;

      @include m.pcOver {
        margin: 0 0 80px;
      }

      @include m.sp {
        margin: 0 0 6rem;
      }
    }

    &_media {
      &Box {
        margin: 3vw 0 0;

        @include m.pcOver {
          margin: 40px 0 0;
        }

        @include m.sp {
          margin: 2rem 0 0;
        }
      }
    }

    &_plan {
      padding: 2.2vw 3vw 3vw;

      @include m.pcOver {
        padding: 30px 40px 40px;
      }

      @include m.sp {
        padding: 2rem 3rem 3rem;
      }

      &Cont {
        padding: 0.5vw 0 0;

        @include m.pcOver {
          padding: 6px 0 0;
        }

        @include m.sp {
          padding: 0.5rem 0 1rem;
        }

        & + & {
          @include m.pc {
            margin: 1.5vw 0 0;
          }

          @include m.pcOver {
            margin: 20px 0 0;
          }
        }
      }

      &List {
        @include m.pc {
          width: 100%;
          max-width: 860px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
        }

        &Box {
          padding: 1vw;
          background: v.$gradBlue;

          @include m.pcOver {
            padding: 13px;
          }

          @include m.pc {
            width: 48%;
            max-width: 410px;
          }

          @include m.sp {
            padding: 1.5rem 1rem;
          }

          & + & {
            @include m.sp {
              margin-top: 2.5rem;
            }
          }
        }
      }

      &Info {
        &Head {
          @include m.fz(18);
          font-weight: bold;
          line-height: 1.7777777777777777;
          letter-spacing: 0.04em;
          color: v.$white;

          @include m.pc {
            text-align: center;
          }

          @include m.sp {
            font-size: 1.6rem;
            line-height: 1.5;
          }
        }

        &Body {
          margin-top: 8px;
          padding-top: 4px;
          border-top: 1px solid v.$white;

          @include m.pc {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &Item {
          color: v.$white;

          & + & {
            @include m.pc {
              margin: 0 0 0 1em;
            }
          }
        }
      }
    }

    &_access {
      margin: 0 0 6.8vw;

      @include m.pcOver {
        margin: 0 0 92px;
      }

      @include m.sp {
        margin: 0 0 6rem;
      }

      &Map {
        height: 380px;

        @include m.tab {
          height: 280px;
        }

        @include m.sp {
          height: 38rem;
        }
      }
    }

    &_card {
      margin: 0 0 5.7vw;

      @include m.pcOver {
        margin: 0 0 70px;
      }

      @include m.sp {
        margin: 0 0 5rem;
      }
    }

    &_ring {
      $this: &;
      width: 100%;
      position: absolute;
      translate: -50% -50%;

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 28%;
        top: 70%;
        left: -6%;
        z-index: -1;

        @include m.pc {
          max-width: 320px;
        }

        @include m.pcOver {
          top: 70%;
        }
      }

      &02 {
        @extend #{$this};
        width: 32%;
        top: 3%;
        left: 108%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }

        @include m.pcOver {
          top: 3%;
        }
      }

      &03 {
        @extend #{$this};
        width: 25%;
        top: 122%;
        left: 106%;
        z-index: -1;

        @include m.pc {
          max-width: 270px;
        }
      }
    }
  }

  &Feature {
    &_ring {
      $this: &;
      width: 100%;
      position: absolute;
      translate: -50% -50%;

      @include m.sp {
        display: none;
      }

      &01 {
        @extend #{$this};
        width: 29%;
        top: 58%;
        left: -11.8%;
        z-index: -1;

        @include m.pc {
          max-width: 320px;
        }
      }

      &02 {
        @extend #{$this};
        width: 32%;
        top: 5.5%;
        left: 105%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }
      }

      &03 {
        @extend #{$this};
        width: 32%;
        top: 4%;
        left: 104%;
        z-index: -1;

        @include m.pc {
          max-width: 344px;
        }

        @include m.pcOver {
          top: 5%;
        }
      }
    }
  }

  &Info {
    width: 100%;

    &_cont {
      display: flex;
      @include m.pc {
        justify-content: space-between;
      }
      @include m.sp {
        flex-flow: column;
      }

      &Box {
        padding: 2.2vw;

        @include m.pcOver {
          padding: 30px;
        }

        @include m.pc {
          flex: 1;
        }

        @include m.sp {
          padding: 2rem 2rem 3rem;
          order: 1;
        }
      }

      &Pic {
        position: relative;
        z-index: 1;

        @include m.pc {
          width: 49%;
          max-width: 530px;
        }

        @include m.sp {
          order: 0;
        }

        &Item {
          height: 100%;
          position: relative;
          z-index: 1;

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &Badge {
        width: 23%;
        position: absolute;
        top: 1.5vw;
        left: 1.5vw;
        z-index: 2;

        @include m.pc {
          max-width: 120px;
        }

        @include m.pcOver {
          top: 20px;
          left: 20px;
        }

        @include m.sp {
          top: 1rem;
          left: 1rem;
        }
      }

      &Inner {
        margin: 0 0 2vw;

        @include m.pcOver {
          margin: 0 0 26px;
        }

        @include m.sp {
          margin: 0 0 2.5rem;
        }
      }
    }

    &_heading {
      margin: 0 0 1.5vw;

      @include m.pcOver {
        margin: 0 0 20px;
      }

      @include m.sp {
        margin: 0 0 1rem;
      }
    }

    &_contact {
      @include m.pc {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      & + & {
        margin: 2.2vw 0 0;

        @include m.pcOver {
          margin: 36px 0 0;
        }

        @include m.sp {
          margin: 3rem 0 0;
        }
      }

      &Head {
        margin: 0 0.8vw 0 0;
        padding: 3px 0;
        display: inline-block;
        @include m.fz(14);
        line-height: 1.5;
        text-align: center;
        background-color: v.$white;

        @include m.pc {
          width: 100%;
          max-width: 160px;
        }

        @include m.pcOver {
          margin: 0 10px 0 0;
        }

        @include m.sp {
          margin: 0 0 1.5rem;
          padding: 0.4rem 0.8rem;
          font-size: 1.2rem;
        }
      }

      &Body {
        display: flex;
        align-items: center;
        @include m.pc {
          flex: 1;
        }
        @include m.sp {
          align-items: flex-end;
        }
      }

      &Text {
        margin: 0 0.8vw 0 0;
        @include m.fz(20);
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 0.04em;
        color: v.$blue;

        @include m.pcOver {
          margin: 0 8px 0 0;
        }

        @include m.sp {
          margin: 0 0.8rem 0 0;
          font-size: 1.6rem;
        }
      }

      &Num {
        display: flex;
        align-items: center;

        &Item {
          $this: &;
          display: flex;
          align-items: center;
          @include m.fz(40);
          font-weight: bold;
          line-height: 1;
          color: v.$blue;
          position: relative;
          z-index: 1;

          @include m.sp {
            font-size: 3.5rem;
          }

          &--line {
            @extend #{$this};
            margin: -4px 0 0;
          }

          &--ruby {
            @extend #{$this};

            &:before {
              content: attr(data-text);
              display: block;
              font-size: 12px;
              line-height: 1;
              white-space: nowrap;
              text-align: center;
              color: inherit;
              position: absolute;
              bottom: 100%;
              left: 50%;
              z-index: 2;
              translate: -50% 0;

              @include m.tab {
                font-size: 11px;
              }

              @include m.sp {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  &Badge {
    margin: 0 0 7.3vw;

    @include m.pcOver {
      margin: 0 0 100px;
    }

    @include m.sp {
      margin: 0 0 6rem;
    }
  }

  &View {
    &_intro {
      margin: 0 0 3.2vw;

      @include m.pcOver {
        margin: 0 0 44px;
      }

      @include m.sp {
        margin: 0 0 2rem;
      }
    }

    &_list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &Item {
        width: 19%;
        @include m.pc {
          max-width: 200px;
        }

        @include m.sp {
          width: 48%;
        }

        &:nth-child(n + 6) {
          @include m.pc {
            margin-top: 2.2vw;
          }

          @include m.pcOver {
            margin-top: 30px;
          }
        }

        &:nth-child(n + 3) {
          @include m.sp {
            margin-top: 2rem;
          }
        }
      }

      &Pic {
        margin: 0 0 0.3vw;

        @include m.pcOver {
          margin: 0 0 4px;
        }

        @include m.sp {
          margin: 0 0 0.3rem;
        }
      }
    }
  }

  &Flow {
    &_intro {
      margin: 0 0 3.5vw;

      @include m.pcOver {
        margin: 0 0 45px;
      }

      @include m.sp {
        margin: 0 0 3rem;
      }

      &Cont {
        & + & {
          margin: 1.5vw 0 0;

          @include m.pcOver {
            margin: 20px 0 0;
          }

          @include m.sp {
            margin: 1rem 0 0;
          }
        }
      }
    }
  }

  &Step {
    margin: 0 0 7.5vw;

    @include m.pcOver {
      margin: 0 0 100px;
    }

    @include m.sp {
      margin: 0 0 6rem;
    }

    &_cont {
      padding: 2.8vw 2.2vw;
      background-color: v.$white;

      @include m.pc {
        display: flex;
        justify-content: space-between;
      }

      @include m.pcOver {
        padding: 36px 30px;
      }

      @include m.sp {
        padding: 3.5rem 1.5rem 2.5rem;
      }

      & + & {
        margin: 2.2vw 0 0;

        @include m.pcOver {
          margin: 30px 0 0;
        }

        @include m.sp {
          margin: 2rem 0 0;
        }
      }

      &Intro {
        width: 100%;
        margin: 0 3.5vw 0 0;

        @include m.pcOver {
          margin: 0 40px 0 0;
        }

        @include m.pc {
          max-width: 140px;
        }

        @include m.tab {
          margin: 0 1vw 0 0;
        }

        @include m.sp {
          margin: 0 0 2rem;
        }
      }

      &Box {
        @include m.pc {
          flex: 1;
        }
      }
    }

    &_flow {
      @include m.pc {
        display: flex;
        justify-content: space-between;
      }

      &List {
        @include m.pc {
          width: 29%;
          max-width: 250px;
        }

        @include m.sp {
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            width: 1.5rem;
            height: 2.5rem;
            display: block;
            background: url('../img/common/obj_arrow_green.svg') no-repeat center;
            background-size: contain;
            position: absolute;
            top: calc(100% + 1rem);
            left: 50%;
            translate: -50% 0;
            rotate: 90deg;
          }
        }

        & + & {
          @include m.sp {
            margin-top: 5rem;
          }
        }

        &:last-child {
          @include m.pc {
            .p-ecoStep_flow {
              &Pic {
                &:after {
                  display: none;
                }
              }
            }
          }

          @include m.sp {
            &::after {
              display: none;
            }
          }
        }
      }

      &Pic {
        margin: 0 0 0.5vw;
        position: relative;
        z-index: 1;

        @include m.pcOver {
          margin: 0 0 6px;
        }

        @include m.sp {
          margin: 0 0 0.5rem;
        }

        &:after {
          content: '';
          width: 15px;
          height: 25px;
          display: block;
          background: url('../img/common/obj_arrow_green.svg') no-repeat center;
          background-size: contain;
          position: absolute;
          top: 50%;
          left: 109%;
          z-index: 2;
          translate: 0 -50%;

          @include m.tab {
            width: 12px;
            height: 22px;
          }

          @include m.sp {
            display: none;
          }
        }
      }
    }

    &_media {
      @include m.pc {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      &Pic {
        margin: 0 1.8vw 0 0;

        @include m.pcOver {
          margin: 0 24px 0 0;
        }

        @include m.pc {
          width: 29%;
          max-width: 250px;
        }

        @include m.sp {
          margin: 0 0 1.5rem;
        }
      }

      &Box {
        @include m.pc {
          flex: 1;
        }
      }

      &Intro {
        margin: 0 0 1vw;

        @include m.pcOver {
          margin: 0 0 12px;
        }

        @include m.sp {
          margin: 0 0 1rem;
        }
      }

      &Inner {
        & + & {
          margin-top: 1.5vw;

          @include m.pcOver {
            margin-top: 20px;
          }

          @include m.sp {
            margin-top: 2rem;
          }
        }
      }

      &Label {
        width: 100%;
        margin: 0 0 4px;
        padding: 4px 0;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        line-height: 1;
        color: v.$white;
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
        background-color: v.$greenGrad02;

        @include m.pc {
          max-width: 70px;
        }

        @include m.tab {
          font-size: 11px;
        }

        @include m.sp {
          width: 30%;
          padding: 0.5rem 0;
          font-size: 1.4rem;
        }
      }
    }

    &_heading {
      @include m.fz(18);
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0.04em;
      color: v.$blue;

      @include m.pc {
        display: flex;
      }

      @include m.tab {
        line-height: 1.7;
      }

      @include m.sp {
        font-size: 1.6rem;
      }

      &Sub {
        display: block;
      }
    }
  }

  &Note {
    padding: 24px 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    text-align: center;
    color: v.$red;
    border: 2px solid v.$red;
    margin-bottom: 80px;

    @include m.sp {
      padding: 1.6rem 0;
      font-size: 1.6rem;
      border-width: 1px;
      margin-bottom: 4rem;
    }
  }
}
